import { getMediaUrl } from "lib/media";
import { ImageOptions } from "models/content/image";
import { css } from "styled-components";
import { stretchToFullWidth } from "theme/mixins";

export const parseImageUrls = (imageOptions: ImageOptions) => {
  const { filename, extension } = imageOptions;

  const mobile = getMediaUrl(filename, {
    extension,
    size: "2x",
  });
  const mobileWebp = getMediaUrl(filename, {
    extension: "webp",
    size: "2x",
  });
  const tablet = getMediaUrl(filename, {
    extension,
    size: "3x",
  });
  const tabletWebp = getMediaUrl(filename, {
    extension: "webp",
    size: "3x",
  });
  const desktop = getMediaUrl(filename, {
    extension,
    size: "3x",
  });
  const desktopWebp = getMediaUrl(filename, {
    extension: "webp",
    size: "3x",
  });
  return { mobile, tablet, desktop, mobileWebp, tabletWebp, desktopWebp };
};

export const ImageCSS = css`
  z-index: 60;
  width: 100%;
  ${stretchToFullWidth()};
  margin-bottom: 1.5rem;
`;
