import { getMediaUrl } from "lib/media";
import { TestimonialContentType } from "models/content/testimonial";
import { FunctionComponent } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import {
  StyledDash,
  StyledDetails,
  StyledName,
  StyledQuote,
  StyledTestimonial,
  StyledText,
  StyledCountry,
  StyledStars,
  StyledAuthor,
  StyledAuthorPicture,
  StyledAuthorDetails,
  StyledAuthorName,
  StyledAuthorCountry,
} from "./testimonial.styles";
import { useTheme } from "hooks/theme";

interface TestimonialContentProps extends ContentProps {
  content: TestimonialContentType;
}

const TestimonialContent: FunctionComponent<TestimonialContentProps> = ({
  content,
}) => {
  const theme = useTheme();
  const { text, name, country, image } = content.testimonialOptions;

  const quote = getMediaUrl("icons/quote", { extension: "svg" });

  if (theme === "MasterEnglish") {
    const starsUrl = getMediaUrl("yellow-rating-5-star", {
      extension: "svg",
      size: "1x",
    });
    const pictureUrl = getMediaUrl(`testimonials/${image}`, {
      extension: "webp",
      size: "1x",
    });
    return (
      <StyledTestimonial $theme={theme}>
        <StyledAuthor>
          <StyledAuthorPicture src={pictureUrl} />
          <StyledAuthorDetails>
            <StyledAuthorName>{name}</StyledAuthorName>
            <StyledAuthorCountry>{country}</StyledAuthorCountry>
          </StyledAuthorDetails>
        </StyledAuthor>
        <StyledText $theme={theme}>{text}</StyledText>
        <StyledStars src={starsUrl} />
      </StyledTestimonial>
    );
  }
  return (
    <StyledTestimonial>
      <StyledQuote src={quote} />
      <StyledText>{text}</StyledText>
      <StyledDetails>
        <StyledDash>&mdash;</StyledDash>
        <StyledName>{name}</StyledName>,&nbsp;
        <StyledCountry>{country}</StyledCountry>
      </StyledDetails>
    </StyledTestimonial>
  );
};

export default TestimonialContent;

export const testimonialContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: TestimonialContent,
  validator: testimonialContentValidator,
};
