import { getMediaUrl, MediaUrls } from "lib/media";
import { HeaderModel } from "models/question";
import { css } from "styled-components";
import devices from "theme/devices";

export const ImageCSS = css`
  width: 100%;
  height: 200px;

  object-fit: cover;
  object-position: center top;

  @media ${devices.tablet} {
    height: 350px;
  }

  @media ${devices.desktop} {
    height: 510px;
    object-position: center 20%;
  }
`;

export const ImageCSSFlexible = css`
  width: 100%;
`;

export const parseHeaderUrl = (header: HeaderModel): MediaUrls => {
  const { extension } = header;

  const filename = `header/${header.filename}`;
  const mobile = getMediaUrl(filename, { extension, size: "2x" });
  const mobileWebp = getMediaUrl(filename, {
    extension: "webp",
    size: "2x",
  });
  const tablet = getMediaUrl(filename, { extension, size: "3x" });
  const tabletWebp = getMediaUrl(filename, {
    extension: "webp",
    size: "3x",
  });
  const desktop = getMediaUrl(filename, { extension, size: "3x" });
  const desktopWebp = getMediaUrl(filename, {
    extension: "webp",
    size: "3x",
  });
  return { mobile, tablet, desktop, mobileWebp, tabletWebp, desktopWebp };
};
