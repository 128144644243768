import { Fragment, FunctionComponent } from "react";

const TermsOfUseES: FunctionComponent = () => {
  return (
    <Fragment>
      <h1>Términos de Uso</h1>

      <ol>
        <li>
          <a href="#n1">General</a>
        </li>
        <li>
          <a href="#n2">Inicio del Contrato</a>
        </li>
        <li>
          <a href="#n3">Identificación</a>
        </li>
        <li>
          <a href="#n4">Producción y Uso del Servicio</a>
        </li>
        <li>
          <a href="#n5">Mantenimiento del Servicio y Reparación de Fallos</a>
        </li>
        <li>
          <a href="#n6">
            Reclamaciones Sobre el Servicio y Responsabilidad por Daños
          </a>
        </li>
        <li>
          <a href="#n7">Pagos y Facturación</a>
        </li>
        <li>
          <a href="#n8">
            Duración del Contrato y de la Usabilidad de Partes Personales del
            Servicio
          </a>
        </li>
        <li>
          <a href="#n9">Cierre del Servicio del Cliente</a>
        </li>
        <li>
          <a href="#cancellation_right">Terminación de Contrato</a>
        </li>
        <li>
          <a href="#n11">Consecuencias de Terminación de Contrato</a>
        </li>
        <li>
          <a href="#n12">Otras Condiciones</a>
        </li>
      </ol>

      <h2 id="n1">1. General</h2>

      <p>
        <strong>
          1.1 Objeto de los Términos de Uso y Descripción de Servicio.
        </strong>
        &nbsp; Master English/WordDive S.L., dirección postal: Verstaankatu 1,
        2F, FI 33100 Tampere, Finlandia (en adelante&nbsp; &quot;Compañía&quot;)
        ofrece servicios y productos, como el Servicio Master English (en
        adelante&nbsp; &quot;Servicio&quot;), a sus Usuarios (en adelante&nbsp;
        &quot;Cliente&quot;) de acuerdo con los presentes Términos de Uso. El
        contenido exacto del Servicio queda fijado en las descripciones y en los
        términos especiales del Servicio, presentados en la página web de este o
        bien en el momento de solicitud del Servicio.
      </p>

      <p>
        <strong>
          1.2 Términos contractuales aplicables y orden de aplicación.
        </strong>
        &nbsp;Estos Términos de Uso se aplican al Servicio y al uso y pedido de
        servicios y productos producidos por la Compañía y ofrecidos a través
        del Servicio. Además, las diferentes partes del Servicio pueden requerir
        contratos separados con la Compañía, con descripciones y términos
        especiales del servicio que difieren o se añaden a estos términos. En
        caso de que estos términos generales y los términos específicos
        relativos a una determinada parte del Servicio estén en conflicto, se
        aplicarán en primer lugar los términos más específicos, y los términos
        generales en segundo lugar cuando se trate de la parte del Servicio en
        cuestión.
      </p>

      <p>
        <strong>1.3 Servicios de pago.</strong>&nbsp;El Cliente puede solicitar
        los diferentes productos o servicios disponibles a través del Servicio
        mediante el pago en línea, el pago con tarjeta de crédito o mediante
        otro método de pago especificado. La Compañía anunciará el precio de
        cada producto o servicio según la forma de pago y las condiciones de
        entrega que complementan estas condiciones por separado.
      </p>

      <p>
        <strong>1.4 Derecho de rescisión del cliente.</strong>&nbsp;El Cliente
        no tiene derecho a rescindir el pedido después de que la entrega del
        producto o servicio haya comenzado por medios electrónicos con el
        acuerdo del Cliente.
      </p>

      <p>
        <strong>1.5. Clientes menores de edad.</strong>&nbsp;El uso del Servicio
        está permitido solo para personas mayores de 16 años.
      </p>

      <h2 id="n2">2. Inicio del contrato</h2>

      <p>
        <strong>2.1 Inicio del Contrato del Servicio.</strong>&nbsp;El contrato
        comienza cuando el Cliente, durante el registro, ha aceptado estos
        Términos de Uso y la Política de Privacidad del registro de clientes del
        servicio de WordDive de la Compañía y cuando la Compañía ha reconocido
        el pago del Cliente por la compra del producto o servicio.
      </p>

      <p>
        <strong>
          2.2 Validez de la información proporcionada por el Cliente en el
          Servicio.
        </strong>
        &nbsp;Las comisiones del Cliente hechas en el Servicio se manejan y
        entregan de acuerdo a la información proporcionada por el Cliente. El
        Cliente es responsable de la validez de la información proporcionada, y
        la Compañía no tiene la obligación de comprobar ni añadir a la
        información.
      </p>

      <h2 id="n3">3. Identificación</h2>

      <p>
        <strong>3.1 Medios de identificación.</strong>&nbsp;El uso de ciertas
        partes del Servicio requiere un nombre de Usuario y una contraseña
        personal u otro medio de identificación aceptado por la Compañía. La
        dirección de correo electrónico proporcionada por el cliente funcionará
        como nombre de Usuario.
      </p>

      <p>
        <strong>3.2 Medios de identificación del cliente.</strong>&nbsp;La
        Compañía proporciona al Cliente nombres de Usuario, contraseñas y otros
        posibles medios de identificación con el fin de utilizar el Servicio
        durante el período del contrato y solo para el propósito acordado de
        conformidad con estos Términos de Uso. El Cliente no tiene derecho de
        propiedad sobre estos medios de identificación, y no tiene derecho a
        seguir utilizándolos después de la finalización del Contrato, a menos
        que se acuerde lo contrario o se requiera legalmente.
      </p>

      <p>
        <strong>
          3.3 Derecho de la Compañía a cambiar los medios de identificación.
        </strong>
        &nbsp;En cualquier momento, la Compañía tiene el derecho de cambiar los
        medios de identificación del Cliente. Si es posible dentro de lo
        razonable, la Compañía informará al Cliente de cualquier cambio en los
        medios de identificación de antemano.
      </p>

      <p>
        <strong>3.4 Custodia de los medios de identificación.</strong>&nbsp;El
        Cliente es responsable de la custodia cuidadosa de sus medios de
        identificación. El cliente se compromete a mantener separados la
        contraseña y cualquier otro medio de identificación posible y adicional
        para que un tercero no pueda acceder a ellos. La contraseña utilizada en
        relación con los medios de identificación es personal y no debe
        entregarse a un tercero.
      </p>

      <h2 id="n4">4. Producción y uso del servicio</h2>

      <p>
        <strong>4.1 Producción del servicio.</strong>&nbsp;La Compañía tiene el
        derecho de producir el Servicio de la manera que considere conveniente.
        La Compañía tiene el derecho de hacer cambios que afecten el contenido,
        la tecnología y el uso del Servicio. Si estos cambios requieren cambios
        en el equipo y el software del Cliente, este debe, asumiendo los costos,
        hacerse cargo de ellos. La Compañía se esfuerza por publicar de
        antemano, en un plazo razonable, información sobre los cambios que
        afectan al Cliente, por ejemplo, mediante notificaciones publicadas a
        través del Servicio.
      </p>

      <p>
        La Compañía tiene derecho a detener la producción del Servicio o su
        parte. En este caso, la Compañía se esfuerza por informar al Cliente de
        esto de antemano dentro de un plazo razonable, por ejemplo, en el sitio
        web del Servicio.
      </p>

      <p>
        <strong>
          4.2. El material informativo producido por los Clientes que utilizan
          el Servicio.
        </strong>
        &nbsp;Como se describe con más detalle en la Política de Privacidad de
        la Compañía y de conformidad con las leyes aplicables en relación con el
        manejo de la información personal, la Compañía tiene el derecho de
        recopilar, guardar y procesar todo el material de información creado por
        los Clientes que utilizan el Servicio. Esto significa, por ejemplo, las
        respuestas a los ejercicios escritos o grabados por el Cliente durante
        el uso del Servicio. Todo el material informativo producido por los
        Clientes que utilicen el Servicio seguirá siendo propiedad de la
        Compañía, y la Compañía tiene derecho a utilizarlo de la forma descrita
        en la Política de Privacidad. A menos que se decrete lo contrario en la
        política de privacidad o en la legislación de procesamiento de
        información aplicable, el Cliente no tiene derecho al material de
        información de respuesta escrito ni grabado por el Cliente durante el
        contrato o después de la finalización de este.
      </p>

      <p>
        <strong>
          4.3 Derecho de uso en relación con el material publicado en el
          servicio.
        </strong>
        &nbsp;El Servicio incluye y proporciona material protegido por derechos
        de autor, derechos de marca registrada y otros derechos inmateriales.
        Sin el consentimiento específico por escrito del creador, el titular de
        los derechos o la Compañía o el anuncio específico de un derecho de uso
        más amplio en el Servicio (por ejemplo, para los comunicados de prensa),
        el Cliente no tiene derecho a distribuir, publicar, copiar, distribuir
        al público ni utilizar de otro modo el material que se adquiere del
        Servicio o a través de él, excepto para el propio uso privado del
        Cliente. El titular de los derechos del material presentado en el
        Servicio o disponible a través de él puede hacer demandas directamente
        al Cliente en caso de uso inapropiado del material protegido, tal como
        se define en esta sección.
      </p>

      <p>
        <strong>
          4.4 Derecho de uso en relación con el software y los documentos.
        </strong>
        &nbsp;La Compañía concede al Cliente el derecho a utilizar las versiones
        en código de máquina del software y los documentos entregados por la
        Compañía de acuerdo con el contrato. De acuerdo con el derecho de uso,
        el Cliente puede utilizar el software y los documentos solo de acuerdo
        con las posibles condiciones de licencia que acompañan al software, las
        instrucciones de la Compañía, y solo directamente relacionadas con el
        uso del Servicio. La propiedad y los derechos inmateriales del software
        y los documentos, así como sus versiones revisadas, son propiedad de la
        Compañía o de un tercero (como el principal o el subcontratista de la
        Compañía), y el Cliente no tiene derecho, sin un acuerdo previo por
        escrito de la Compañía, a copiar, traducir o cambiar los documentos o el
        software o distribuirlos para el uso de un tercero, a menos que la ley
        imperativa lo exija.
      </p>

      <p>
        <strong>4.5 Equipo y software del cliente.</strong>&nbsp;Las terminales,
        el software y las conexiones (como las conexiones a una red de
        comunicaciones móviles y a la Internet) que se requieren para utilizar
        el Servicio no están incluidos en este. El Cliente es responsable de la
        adquisición, mantenimiento y actualización de estos dispositivos,
        conexiones y software. Además, el Cliente es responsable de que estos
        dispositivos y software no interfieran ni alteren el funcionamiento de
        la Compañía o del Servicio. Los dispositivos y el software que puedan
        estar causando perturbaciones deben desconectarse inmediatamente del
        Servicio.
      </p>

      <h2 id="n5">5. Mantenimiento del servicio y reparación de defectos</h2>

      <p>
        <strong>
          5.1 Mantenimiento del Servicio y Reparación de Defectos.
        </strong>
        &nbsp;La Compañía se esfuerza por cuidar, en la medida de sus
        posibilidades, la funcionalidad del Servicio y por reparar cualquier
        posible defecto en el Servicio dentro de un plazo razonable.
      </p>

      <p>
        <strong>5.2 Interrupciones temporales.</strong>&nbsp;La Compañía tiene
        derecho a cerrar temporalmente el Servicio o una parte de este si hay
        una razón justificable para hacerlo. En este caso, la Compañía se
        esfuerza por trabajar para que la interrupción sea lo más breve posible
        y cause el menor inconveniente posible al Cliente. La Compañía, dentro
        de sus capacidades y de antemano si es necesario, informará al Cliente
        de la interrupción, por ejemplo, en el sitio web del Servicio.
      </p>

      <h2 id="n6">
        6. Reclamaciones relativas al Servicio y responsabilidad por daños y
        perjuicios
      </h2>

      <p>
        <strong>6.1 Reclamaciones del Cliente.</strong>&nbsp;Si el Cliente nota
        un defecto en el servicio, debe hacer una reclamación y solicitar una
        corrección dentro de un tiempo razonable después de que el defecto fue o
        debería haberse detectado. La reclamación puede hacerse por correo
        electrónico a&nbsp;
        <a href="mailto:soporte@masterenglish.com">soporte@masterenglish.com</a>
        .
      </p>

      <p>
        <strong>6.2 Corrección del defecto.</strong>&nbsp;En caso de que la
        Compañía se ofrezca, sin demora, a corregir el defecto señalado por el
        Cliente, la Compañía tiene el derecho de corregir el defecto. En caso de
        que el defecto no pueda corregirse o no se corrija dentro de un tiempo
        razonable después de que el Cliente lo haya señalado, el Cliente tiene
        derecho a recibir una reducción de precio en relación con el defecto.
      </p>

      <p>
        <strong>6.3 Responsabilidad por daños.</strong>&nbsp;El Cliente tiene
        derecho a exigir una indemnización por daños directos de acuerdo con los
        artículos de la Ley de Protección del Consumidor relativos a la
        indemnización. La Compañía no se hace responsable de los daños causados
        por el defecto en caso de que este se deba a una razón ajena a la
        Compañía o a un obstáculo fuera de su ámbito de influencia. El Cliente
        tiene derecho a ser compensado por los daños indirectos causados por el
        defecto solo cuando el defecto sea causado intencionalmente por la
        Compañía o causado por la negligencia de la Compañía. El Cliente siempre
        está obligado a actuar de manera que el daño causado por el defecto no
        aumente innecesariamente debido a las acciones o a la negligencia del
        cliente. La Compañía solo es responsable ante los clientes consumidores
        por los daños causados por el defecto.
      </p>

      <p>
        <strong>
          6.4 Responsabilidades en la venta a compañías e institutos educativos.
        </strong>
        &nbsp;Los productos y servicios siempre se venden tal cual. La Compañía
        no se hace responsable de los daños directos o indirectos ni del lucro
        cesante causados por defectos en los productos o servicios y/o el uso de
        estos. La responsabilidad de la Compañía siempre está limitada de
        acuerdo con el contenido de estos Términos de Contrato.
      </p>

      <p>
        <strong>
          6.5 Defectos en los dispositivos o software controlados por el
          Cliente.
        </strong>
        &nbsp;Si el Cliente, a sabiendas o a pesar de haber sido notificado por
        la Compañía, utiliza dispositivos o software defectuosos o que crean
        perturbaciones, el Cliente es responsable de compensar a la Compañía por
        los posibles daños y los gastos de la búsqueda de los defectos.
      </p>

      <h2 id="n7">7. Pagos y Facturación</h2>

      <p>
        <strong>7.1 Pagos por el servicio.</strong>&nbsp;El cliente paga por el
        uso de las partes cobrables del Servicio según el contrato. La Compañía
        puede utilizar servicios de facturación de terceros para exigir los
        pagos por el uso del Servicio. En estos casos, los pagos por el uso del
        Servicio se exigen de acuerdo con los períodos de facturación y los
        términos definidos en los contratos entre los terceros en cuestión (como
        Apple App Store) y el Cliente, a menos que se especifique lo contrario
        en el Servicio.
      </p>

      <p>
        Los pagos se exigen por adelantado para cada período de facturación. Los
        pagos también deben hacerse en caso de que el tiempo de inactividad del
        Servicio sea causado por el Cliente o por una razón de la que el Cliente
        sea responsable. En caso de fin de contrato, la Compañía no reembolsará
        al cliente los pagos anticipados.
      </p>

      <p>
        <strong>7.2 Cambios de precios.</strong>&nbsp;La Compañía se reserva el
        derecho de modificar sus precios y los pagos por el uso del Servicio.
      </p>

      <p>
        <strong>7.3 Métodos de pago.</strong>&nbsp;Los productos se pagan junto
        con el pedido. Los servicios de pago disponibles dependen del país en
        cuestión. En cada país se ofrecen a los Usuarios de la tienda web uno o
        más de los siguientes servicios de pago:&nbsp;
        <a href="http://www.masterenglish.com" rel="noreferrer" target="_blank">
          www.masterenglish.com
        </a>
        .
      </p>

      <p>
        <strong>Apple App Store</strong>
        <br />
        Apple
        <br />
        1 Infinite Loop
        <br />
        Cupertino, CA 95014
        <br />
        ESTADOS UNIDOS
        <br />
        <em>
          Apple App Store ofrece pagos integrados y sencillos para los
          dispositivos de Apple.
        </em>
      </p>

      <p>
        <strong>Google Play Store</strong>
        <br />
        Google
        <br />
        1600 Amphitheatre Parkway
        <br />
        Mountain View, CA 94043
        <br />
        ESTADOS UNIDOS
      </p>

      <p>
        <strong>Paddle.com Market Limited</strong>
        <br />
        15 Briery Close
        <br />
        Great Oakley
        <br />
        Corby
        <br />
        Northamptonshire
        <br />
        NN18 8JG
        <br />
        REINO UNIDO
      </p>

      <p>
        <strong>Paddle.com Inc</strong>
        <br />
        3811
        <br />
        Ditmars Blvd
        <br />
        #1071 Astoria
        <br />
        NY 11105-1803
        <br />
        ESTADOS UNIDOS
      </p>

      <p>
        <strong>7.4 Garantía de resultados.</strong>&nbsp;Para ciertos productos
        y servicios, la Compañía ofrece una garantía de reembolso. En aquellos
        casos donde la garantía es aplicable, se le informará al Cliente de las
        condiciones de la garantía antes de la solicitud del producto o
        servicio.
      </p>

      <p>
        <strong>
          Condiciones de la garantía de resultados con examen oficial de inglés
        </strong>
        <br />
        1. En el momento de solicitar un producto o servicio de la Compañía, se
        ha informado al Cliente de que la garantía es aplicable a su solicitud.
        <br />
        2. El Cliente completa todos los módulos de estudio incluidos en su
        programa de aprendizaje dentro del periodo de tiempo acordado. Se
        comunicarán al Cliente los módulos de estudio y el periodo de tiempo
        requeridos al crear su solicitud.
        <br />
        3. El Cliente completa uno de los exámenes oficiales de inglés de la
        lista incluida a continuación y envía el certificado con los resultados
        a la Compañía por correo electrónico (soporte@masterenglish.com) en un
        plazo de 2 meses (62 días) tras haber completado su programa.
        <br />
        4. Si el resultado del examen oficial realizado por el Cliente es menor
        a las puntuaciones mínimas definidas en la siguiente lista, la Compañía
        le reembolsará al Cliente todos los pagos relativos al producto o
        servicio cubierto bajo garantía.
      </p>

      <p>
        <strong>TOEFL</strong>
        <br />
        TOEFL iBT: el Cliente consigue al menos 72 puntos.
        <br />
        TOEFL ITP: el Cliente consigue al menos 585 puntos.
      </p>

      <p>
        <strong>IELTS</strong>
        <br />
        El Cliente consigue al menos 6.0 puntos.
      </p>

      <p>
        <strong>Cambridge English test</strong>
        <br />
        El Cliente aprueba como mínimo el examen B2 First (FCE).
      </p>

      <p>
        <strong>TOEIC</strong>
        <br />
        TOEIC Listening and Reading: el Cliente consigue al menos 865 puntos.
        <br />
        TOEIC Speaking and Writing: el Cliente consigue al menos 335 puntos.
      </p>

      <h2 id="n8">
        8. Duración del Contrato y el Usabilidad de las partes personales del
        Servicio.
      </h2>

      <p>
        <strong>
          8.1 Tiempo de funcionamiento de los Servicios y Productos Personales.
        </strong>
        &nbsp;Para las partes de período fijo del Servicio, la Compañía
        anunciará el tiempo de funcionamiento de esa parte ya sea durante el
        pedido del servicio o de alguna otra manera dentro del Servicio. El
        Cliente debe tener en cuenta que el Servicio y sus características
        inherentes no son eternos por naturaleza, y la Compañía no garantiza que
        el Servicio o los productos o servicios adquiridos a través de él
        permanezcan sin cambios. El nombre de Usuario y otros datos personales
        relacionados con el Servicio se almacenan durante un mínimo de doce (12)
        meses después de que el último período de contrato del Cliente haya
        terminado. Después de esto, la Compañía tiene el derecho de eliminar el
        nombre de Usuario del Cliente y otros datos personales relacionados con
        el Servicio. Para obtener más información sobre el procesamiento de la
        información personal durante el uso del Servicio, consulte la Política
        de Privacidad.
      </p>

      <h2 id="n9">9. Cierre del Servicio del Cliente</h2>

      <p>
        <strong>9.1 Derecho de la Compañía a cerrar el Servicio.</strong>
        &nbsp;La Compañía tiene derecho a cerrar el Servicio del Cliente total o
        parcialmente y dejar el pedido del cliente sin cumplir si:
      </p>

      <p>
        <strong>9.1.1</strong>&nbsp;El Cliente se ha negado a hacer un pago a
        pesar de que se le ha enviado un recordatorio.
      </p>

      <p>
        <strong>9.1.2</strong>&nbsp;A pesar de la notificación de la Compañía,
        el Cliente utiliza dispositivos o programas informáticos disruptivos.
      </p>

      <p>
        <strong>9.1.3</strong>&nbsp;Por el uso del Servicio, el Cliente ha
        causado molestias a la Compañía, a otros Usuarios del Servicio o a
        terceros.
      </p>

      <p>
        <strong>9.1.4</strong>&nbsp;El Cliente incumple los deberes acordados en
        el contrato.
      </p>

      <p>
        <strong>9.1.5</strong>&nbsp;El Cliente ha dado información
        fundamentalmente errónea durante la apertura del Servicio o más tarde.
      </p>

      <p>
        <strong>9.1.6</strong>&nbsp;Hay razones para sospechar que el Cliente
        está usando el Servicio para acciones ilegales.
      </p>

      <p>
        <strong>9.1.7</strong>&nbsp;La Compañía tiene una razón técnica o
        comercial que requiere el cierre del Servicio. En este caso, la Compañía
        compensa al Cliente la suma equivalente al tiempo de estudio restante.
      </p>

      <h2 id="cancellation_right">10. Terminación del contrato</h2>

      <p>
        <strong>10.1 Derecho del Cliente a terminar el contrato.</strong>
        &nbsp;El Cliente tiene derecho a terminar un contrato de duración
        indeterminada (suscripción) cancelándolo al menos 24 horas antes del
        comienzo del siguiente período de facturación. El cliente tiene derecho
        a seguir utilizando el Servicio hasta el final del período ya pagado.
        Las instrucciones para cancelar una suscripción se encuentran en el
        siguiente artículo:&nbsp;
        <a
          href="https://masterenglish.zendesk.com/hc/es-mx/articles/360018237539--C%C3%B3mo-cancelo-mi-suscripci%C3%B3n-"
          rel="noreferrer"
          target="_blank"
        >
          ¿Cómo puedo cancelar mi suscripción a Master English?
        </a>
      </p>

      <p>
        <strong>10.2 Derecho de la Compañía a terminar el Contrato.</strong>
        &nbsp;La Compañía tiene derecho a terminar un contrato de duración
        indeterminada (suscripción) después de observar un período de
        terminación de un (1) mes.
      </p>

      <p>
        <strong>10.3 Derecho de la Compañía a disolver el Contrato.</strong>
        &nbsp;La Compañía tiene el derecho de disolver el contrato si:
      </p>

      <p>
        <strong>10.3.1</strong>&nbsp;El Cliente no ha usado el Servicio por más
        de doce (12) meses.
      </p>

      <p>
        <strong>10.3.2</strong>&nbsp;El Servicio del Cliente ha estado
        completamente cerrado durante un (1) mes por una razón mencionada en la
        sección 9.1.
      </p>

      <p>
        <strong>10.3.3</strong>
        &nbsp;El Cliente ha descuidado, en lo fundamental y a pesar de haber
        sido notificado, los deberes que se han acordado en el contrato.
      </p>

      <h2 id="n11">11. Consecuencias de la Terminación del Contrato</h2>

      <p>
        <strong>11.1 Fin del uso del Servicio y eliminación de datos.</strong>
        &nbsp;Al finalizar el contrato, la Compañía tiene derecho a interrumpir
        el uso del Servicio con la información de Usuario del Cliente y a
        eliminar cualquier dato posible que el Cliente haya guardado en las
        partes del Servicio específicas para el cliente.
      </p>

      <h2 id="n12">12. Otros Términos</h2>

      <p>
        <strong>12.1 Emisión de información.</strong>
        &nbsp;El Cliente debe, antes de abrir el Servicio, proporcionar a la
        Compañía la información que requiere para el Servicio (información del
        cliente) y asegurarse de que la información dada es válida. El Cliente
        debe notificar inmediatamente a la Compañía los cambios en la
        información del cliente por correo electrónico a&nbsp;
        <a href="mailto:soporte@masterenglish.com">soporte@masterenglish.com</a>
        .
      </p>

      <p>
        <strong>12.2 Notificaciones.</strong>&nbsp;El Cliente debe enviar
        cualquier notificación por escrito relativa a este contrato a la
        dirección de la Compañía&nbsp;
        <a href="mailto:soporte@masterenglish.com">soporte@masterenglish.com</a>
        &nbsp; o WordDive Ltd, Verstaankatu 1, 2F, FI 33100 Tampere, Finlandia.
        La Compañía puede enviar cualquier notificación escrita relativa a este
        contrato a la última dirección de correo electrónico que el Cliente haya
        proporcionado a la Compañía. Las notificaciones generales relativas al
        Servicio también pueden publicarse en el sitio web del Servicio. Una
        notificación enviada por el Cliente se considerará recibida el séptimo
        (7. º) día después de su envío por correo y el siguiente día laborable
        después de su envío por correo electrónico.
      </p>

      <p>
        <strong>12.3 Transferencia del contrato.</strong>&nbsp;La Compañía tiene
        el derecho de transferir el contrato a un tercero en su totalidad o en
        parte. La Compañía notificará al cliente la transferencia con bastante
        antelación. Además, la Compañía tiene el derecho de transferir los pagos
        debidos en virtud del contrato a un tercero. Después de la notificación
        de la transferencia de los pagos debidos al tercero, los pagos solo son
        válidos cuando se realizan a la parte receptora de la transferencia. El
        Cliente no tiene derecho a transferir el contrato a un tercero sin un
        acuerdo previo por escrito de la Compañía.
      </p>

      <p>
        <strong>12.4 Ley aplicable y jurisdicción.</strong>&nbsp;Este contrato y
        sus términos especiales de servicio operan bajo la ley finlandesa y las
        disputas relacionadas con el contrato se resolverán en el Tribunal de
        Distrito de Pirkanmaa. Además, el consumidor tiene derecho a someter el
        conflicto a la Junta de Conflictos del Consumidor o al Tribunal de
        Distrito del consumidor en Finlandia. Si el consumidor no es un
        residente finlandés, los conflictos se tramitan en el Tribunal de
        Distrito de Pirkanmaa, Finlandia.
      </p>

      <p>
        <strong>12.5 Fuerza Mayor.</strong>&nbsp;Las partes quedan liberadas de
        sus obligaciones y de su deber de pagar una indemnización cuando la
        ruptura de las obligaciones del contrato o el incumplimiento de estas se
        deba a fuerza mayor. Un fundamento suficiente para la exoneración del
        deber (fuerza mayor) es un acontecimiento inusual que obstaculice el
        cumplimiento del contrato y que afecte a la materia, ocurra después de
        la celebración del contrato, sea independiente de cualquiera de las
        partes y no pueda evitarse ni superarse de forma razonable. Entre esos
        acontecimientos figuran la guerra, la rebelión, la requisa o la
        confiscación para necesidades generales, el cese de la distribución de
        energía, los conflictos laborales, los incendios, las tormentas
        eléctricas u otros fenómenos naturales, los daños causados por un
        tercero a los cables o cualquier otro acontecimiento igualmente eficaz e
        inusual independiente de cualquiera de las partes. Si el cumplimiento
        del deber contractual se retrasa por alguna de las razones mencionadas,
        el período de cumplimiento se prorrogará por el tiempo que se considere
        razonable teniendo en cuenta todas las circunstancias que lo afecten.
      </p>

      <p>
        <strong>12.6 Fecha de entrada en vigor de los Términos de Uso.</strong>
        &nbsp;Estos términos entran en vigor a partir del 1.1.2021 y son
        efectivos hasta nuevo aviso. Los Términos de Uso están disponibles en la
        Compañía de forma gratuita.
      </p>

      <p>
        <strong>12.7 Cambios en los Términos de Uso.</strong>&nbsp;La Compañía
        puede cambiar estos Términos de Uso. El Cliente será notificado de
        cualquier nuevo Término de Uso con avisos al cliente y/o dentro del
        Servicio (por ejemplo, en el sitio web del Servicio en&nbsp;
        <a href="http://www.masterenglish.com">www.masterenglish.com</a>). Los
        Términos de Uso modificados se aplicarán a los contratos celebrados
        antes de la fecha de entrada en vigor de los nuevos términos.
      </p>
    </Fragment>
  );
};

export default TermsOfUseES;
