import { Fragment, FunctionComponent, useEffect, useState } from "react";
import Block from "components/layout/block";
import Section from "components/layout/section";
import Background from "components/layout/background";
import { useTranslations } from "hooks/translations";
import {
  createEmbeddedQuestionnaireState,
  getInitialQuestion,
  getEmbeddedQuestionnaire,
} from "lib/questionnaire";
import { QuestionModel } from "models/question";
import { QuestionnaireModel } from "models/questionnaire";
import Title from "components/title";
import { ActionType } from "components/layout/actions";
import { OptionModel } from "models/option";
import { StyledActionContainer, StyledBadge } from "./embedded.styles";
import Description from "components/description";
import { useNavigate } from "react-router-dom";
import { getQuestionDescription, getQuestionTitle } from "lib/question";
import { Country } from "models/locale";
import { fetchCountry } from "services/geo";

interface EmbeddedProps {}

const EmbeddedQuestion: FunctionComponent<EmbeddedProps> = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const [questionnaire, setQuestionnaire] = useState<
    QuestionnaireModel | undefined
  >(undefined);
  const [question, setQuestion] = useState<QuestionModel | undefined>(
    undefined
  );
  const [country, setCountry] = useState<Country>("US");

  useEffect(() => {
    const fn = async () => {
      const qu = await getEmbeddedQuestionnaire();
      const q = getInitialQuestion(qu);
      setQuestionnaire(qu);
      setQuestion(q);

      // check country for tv badge
      setCountry(await fetchCountry());
    };
    fn();
  }, []);

  const handleNavigation = async (option: OptionModel) => {
    if (!questionnaire || !question) {
      return null;
    }

    createEmbeddedQuestionnaireState(t, navigate, option);
  };

  const renderQuestion = () => {
    if (!questionnaire || !question) {
      return null;
    }

    const title = getQuestionTitle(questionnaire, question);
    const description = getQuestionDescription(questionnaire, question);
    const actions = (question.options || []).map((option) => {
      return {
        type: "button",
        button: {
          text: option.description || "",
          href: "",
          type: "default",
          onClick: () => handleNavigation(option),
        },
      } as ActionType;
    });
    return (
      <Fragment>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <StyledActionContainer actions={actions} />
      </Fragment>
    );
  };

  return (
    <Fragment>
      {/* show in costa rica */}
      {(country === "CR" || country === "CL") && (
        <StyledBadge filename="AsSeenOnTV" direction="vertical" />
      )}
      <Section>
        <Block order={1}>
          <Background
            align="left"
            mobileImageUrl={"home/CityMobileBg@2x"}
            tabletImageUrl={"home/CityTabletBg"}
            desktopImageUrl={"home/CityDesktopBg"}
            extension="jpg"
          ></Background>
        </Block>
        <Block order={2}>{question && renderQuestion()}</Block>
      </Section>
    </Fragment>
  );
};

export default EmbeddedQuestion;
