import { FunctionComponent } from "react";
import { StyledContent } from "./content.styles";

interface ContentContainerProps {
  className?: string;
  children?: any;
}

const ContentContainer: FunctionComponent<ContentContainerProps> = ({
  children,
  className,
}) => {
  return <StyledContent className={className}>{children}</StyledContent>;
};

export default ContentContainer;
