import Image from "components/image";
import { getMediaUrl, MediaExtension, MediaUrls } from "lib/media";
import { FunctionComponent } from "react";
import { ImageCSSLeft, ImageCSSRight } from "./background.styles";

interface BackgroundProps {
  mobileImageUrl: string;
  tabletImageUrl: string;
  desktopImageUrl: string;
  align: "left" | "right";
  extension?: MediaExtension;
}

const Background: FunctionComponent<BackgroundProps> = ({
  mobileImageUrl,
  tabletImageUrl,
  desktopImageUrl,
  align,
  extension = "png",
}) => {
  const urls: MediaUrls = {
    mobile: getMediaUrl(mobileImageUrl, {
      size: "1x",
      extension,
    }),
    tablet: getMediaUrl(tabletImageUrl, {
      size: "1x",
    }),
    desktop: getMediaUrl(desktopImageUrl, {
      size: "1x",
      extension,
    }),
    mobileWebp: getMediaUrl(mobileImageUrl, {
      size: "1x",
      extension: "webp",
    }),
    tabletWebp: getMediaUrl(tabletImageUrl, {
      size: "1x",
      extension: "webp",
    }),
    desktopWebp: getMediaUrl(desktopImageUrl, {
      size: "1x",
      extension: "webp",
    }),
  };

  return (
    <Image
      urls={urls}
      alt=""
      css={align === "left" ? ImageCSSLeft : ImageCSSRight}
    />
  );
};

export default Background;
