import { ProductsContext } from "providers/products";
import { useContext } from "react";

export const useProducts = () => {
  const context = useContext(ProductsContext);
  if (!context) {
    throw new Error("No ProductsProvider found!");
  }
  return context.products;
};
