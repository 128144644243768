import ThemeColor from "theme/theme-color";
import { read, write } from "lib/storage";
import { DEFAULT_THEME, ThemeType, THEME_KEY } from "models/theme";
import { createContext, FunctionComponent, useEffect, useState } from "react";
import { GlobalStyleSheet } from "theme/global";

interface ThemeContextInterface {
  theme: ThemeType;
  updateTheme: (newTheme: ThemeType) => void;
}

// create context
export const ThemeContext = createContext<ThemeContextInterface | undefined>(
  undefined
);

const ThemeProvider: FunctionComponent<any> = ({ children }) => {
  const [theme, toggleTheme] = useState<ThemeType>(DEFAULT_THEME);

  const updateTheme = (newTheme: ThemeType) => {
    write(THEME_KEY, newTheme);
    toggleTheme(newTheme);
  };

  useEffect(() => {
    const cachedTheme = read<ThemeType>(THEME_KEY);

    // try to reason theme value (prioritise query to enabled testing)
    let newTheme: ThemeType = DEFAULT_THEME;
    if (cachedTheme) {
      // use cached, if set
      newTheme = cachedTheme;
    }

    // change state
    updateTheme(newTheme);
  }, []);

  const value = { theme, updateTheme };
  return (
    <ThemeContext.Provider value={value}>
      <ThemeColor />
      {children}
      <GlobalStyleSheet />
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
