import { request } from "lib/request";
import { PaymentProvider, PaymentStatus } from "models/payment";

export const fetchPaymentStatus = async (paymentProvider: PaymentProvider) => {
  const response = await request<PaymentStatus>({
    method: "POST",
    url: "/v1/payment-status",
    data: { paymentId: "", paymentProvider },
  });
  return response.data;
};
