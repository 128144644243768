import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";

export const StyledProgress = styled.div`
  height: 5px;
`;

interface OuterProps extends ThemeProps {}

export const StyledOuter = styled.div<OuterProps>`
  height: 5px;
  background-color: ${ColorCode.MERCURY};
  position: relative;

  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      background-color: white;
    `}
`;

interface Props {
  $progress: number;
}

export const StyledInner = styled.div<Props>`
  height: 5px;
  max-width: 100%;
  background-color: ${ColorCode.BLUE};
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.25s;
  width: ${(props) => props.$progress}%;
`;
