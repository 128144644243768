import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { ContentSchema, ContentProps } from "../factory";
import MultiSelection from "components/form/multi-selection";
import { ValidatorMethod } from "../../validations";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { ProgramChangeWishContentType } from "models/content/program-change-wish";

interface ProgramChangeWishContentProps extends ContentProps {
  content: ProgramChangeWishContentType;
}

const ProgramChangeWishContent: FunctionComponent<
  ProgramChangeWishContentProps
> = ({ content, onChange }) => {
  const state = useQuestionnaireState();
  const updateQuestionnaireState = useUpdateQuestionnaireState();

  const [selected, toggleSelected] = useState<number[]>([]);
  const { programChangeWishOptions } = content;
  const { items = [] } = programChangeWishOptions;

  useEffect(() => {
    // always start from clean state, do not reuse browser state
  }, []);

  // compose options for the multiselection
  const options = items.map((skill, id) => {
    return {
      id,
      text: skill,
    };
  });

  const handleChoose = (id: number) => {
    if (selected.includes(id)) {
      toggleSelected([...selected.filter((s) => s !== id)]);
      state.moreInfoOf = [
        ...(state.programChangeWish || []).filter((sd) => sd !== id),
      ];
    } else {
      const newSelected = [...selected, id].sort();
      toggleSelected(newSelected);
      state.programChangeWish = newSelected;
    }

    // persist
    updateQuestionnaireState(state);

    // signal
    onChange();
  };

  return (
    <Fragment>
      <MultiSelection
        selected={selected}
        options={options}
        onChoose={handleChoose}
      />
    </Fragment>
  );
};

export default ProgramChangeWishContent;

export const programChangeWishContentValidator: ValidatorMethod = (
  state,
  _t
) => {
  const { programChangeWish = [] } = state;
  const result = programChangeWish.length >= 1 ? "pass" : "fail";
  return result;
};

export const schema: ContentSchema = {
  Component: ProgramChangeWishContent,
  validator: programChangeWishContentValidator,
};
