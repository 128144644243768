import { request } from "lib/request";
import { getVersion } from "lib/version";
import { Language } from "models/locale";
import { QuestionnaireVersion } from "models/questionnaire";
import { SignupModel } from "models/signup";

export interface SignupPayloadModel {
  uuid: string;
  email: string;
  firstname?: string;
  password: string;
  uiLanguage: Language;
  questionnaire: QuestionnaireVersion;
}

export const signup = async (payload: SignupPayloadModel) => {
  const response = await request<SignupModel>({
    method: "POST",
    url: "/v1/signup",
    data: { ...payload, version: `v${getVersion()}` },

    // do not authenticate these requests
    authenticated: false,
  });
  return response.data;
};
