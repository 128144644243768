import { useQuestionnaire } from "hooks/questionnaire";
import { useQuestion } from "hooks/question";
import {
  calculateCurrentProgress,
  calculateRemainingProgress,
  generateGraph,
} from "lib/dijkstra";
import Graph from "node-dijkstra";
import { FunctionComponent, useEffect, useState } from "react";
import { StyledInner, StyledOuter, StyledProgress } from "./progress.styles";
import { useTheme } from "hooks/theme";

interface QuestionnaireProgressProps {
  hidden?: boolean;
}

const QuestionnaireProgress: FunctionComponent<QuestionnaireProgressProps> = ({
  hidden,
}) => {
  const theme = useTheme();
  const questionnaire = useQuestionnaire();
  const question = useQuestion();
  const [graph, toggleGraph] = useState<Graph | undefined>(undefined);
  const [questionId, toggleQuestionId] = useState(question.id);
  const [progress, toggleProgress] = useState(0);

  useEffect(() => {
    // create graph into local state
    let workingGraph = graph;
    if (!workingGraph) {
      workingGraph = generateGraph(questionnaire);
      toggleGraph(workingGraph);
    }

    try {
      // calculate actual progress
      const current = calculateCurrentProgress(questionnaire, question.id);
      const remaining = calculateRemainingProgress(
        workingGraph,
        question.id,
        questionnaire
      );
      let workingProgress = (current / (current + remaining)) * 100;

      // sanity check
      if (workingProgress > 100) {
        workingProgress = 100;
      }

      // if new progress is smaller than current one but we haven't gone backwards
      if (workingProgress < progress && question.id >= questionId) {
        console.info(
          `Moved from question ${questionId} to ${question.id}, but progress changed from ${progress} to ${workingProgress}`
        );
        workingProgress = progress;
      }

      // go live
      toggleProgress(workingProgress);
    } catch (err) {
      // calculus might have failed
      console.error(err);
    }

    // also change this one
    toggleQuestionId(question.id);
  }, [question.id]);

  return (
    <StyledProgress>
      {!hidden && (
        <StyledOuter $theme={theme}>
          <StyledInner $progress={progress}></StyledInner>
        </StyledOuter>
      )}
    </StyledProgress>
  );
};

export default QuestionnaireProgress;
