import { ThemeProps } from "models/theme";
import { ViewType } from "models/view";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";
import { archivo, semibold } from "theme/fonts";

export const StyledPayment = styled.div``;

const StyledLine = styled.div<ThemeProps>`
  border-bottom: 1px solid ${ColorCode.HEX_29324A};
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      border-color: ${ColorCode.HEX_dbdbdb};
      margin-top: 2.25rem;
      margin-bottom: 2.5rem;
    `}

  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      opacity: 0.1;
    `}

  @media ${devices.tablet} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

export const StyledTopLine = styled(StyledLine)`
  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      margin-bottom: 2.5rem;
    `}
`;

export const StyledPaymentLine = styled(StyledLine)`
  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    `}
`;

export const StyledBottomLine = styled(StyledLine)`
  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      margin-top: 2.5rem;
      margin-bottom: -0.5rem;
    `}
`;

export const StyledRow = styled.div`
  display: flex;
  color: ${ColorCode.WHITE};
  margin-top: 0;
  ${semibold()};

  @media ${devices.tablet} {
    font-size: 1.5em;
    line-height: 1.375em;
  }
`;

interface Props extends ThemeProps {
  $view: ViewType;
}

export const StyledTitle = styled.div<Props>`
  color: ${(props) =>
    props.$view === "dark" ? ColorCode.WHITE : ColorCode.BLACK};
  margin-top: 0;
  margin-bottom: 1.5rem;
  ${semibold()};

  @media ${devices.tablet} {
    font-size: 1.5em;
    line-height: 1.375em;
    margin-bottom: 2.25rem;
  }

  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      ${archivo()};
    `}
`;
