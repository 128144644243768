import { FunctionComponent, useEffect, useState } from "react";
import { ValidatorMethod } from "../../validations";
import { WaitlistContentType } from "models/content/wait-list";
import {
  StyledButtonContainer,
  StyledCheckmark,
  StyledForm,
  StyledInputContainer,
  StyledLine,
  StyledNumberWrapper,
  StyledPhoneWrapper,
  StyledPrefixWrapper,
  StyledThanks,
  StyledWaitlist,
} from "./waitlist.styles";
import { ContentProps, ContentSchema } from "../factory";
import Title from "components/title";
import Description from "components/description";
import Button from "components/button";
import Input from "components/form/input";
import { joinWaitlist } from "services/waitlist";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { getMediaUrl } from "lib/media";
import { useConfig } from "hooks/config";
import { getPhonePrefix } from "lib/phone";
import { saveQuestionnaireState } from "services/questionnaire";
import { useQuestionnaire } from "hooks/questionnaire";

interface WaitlistContentProps extends ContentProps {
  content: WaitlistContentType;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  prefix: string;
  tel: string;
}

const WaitlistContent: FunctionComponent<WaitlistContentProps> = ({
  content,
}) => {
  const questionnaire = useQuestionnaire();
  const state = useQuestionnaireState();
  const updateState = useUpdateQuestionnaireState();
  const config = useConfig();

  // mark initially submitted, if we found waitlistId from the state
  const [submitted, toggleSubmitted] = useState<boolean | undefined>(
    state.waitlistId !== undefined
  );
  const [values, toggleValues] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    prefix: "",
    tel: "",
  });

  const { waitlistOptions = { waitlistId: 1 } } = content;
  const { waitlistId } = waitlistOptions;

  useEffect(() => {
    const prefix = getPhonePrefix(config);

    // prefill some data
    toggleValues({
      ...values,
      firstName: state.user.firstname,
      email: state.user.email,
      prefix,
    });
  }, []);

  if (submitted === undefined) {
    return null;
  }

  const renderForm = () => {
    return (
      <StyledForm>
        <StyledLine />
        <Title>Datos de contacto</Title>
        <Description>
          Rellena esta información para que nos pongamos en contacto contigo
          cuando el programa esté disponible en tu región.
        </Description>
        <StyledInputContainer>
          <Input
            type="text"
            label="Nombre:"
            value={values.firstName}
            inputMode="text"
            onChange={(firstName) => toggleValues({ ...values, firstName })}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <Input
            type="text"
            label="Apellidos:"
            value={values.lastName}
            inputMode="text"
            onChange={(lastName) => toggleValues({ ...values, lastName })}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <Input
            type="text"
            label="Email:"
            value={values.email}
            inputMode="email"
            onChange={(email) => toggleValues({ ...values, email })}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledPhoneWrapper>
            <StyledPrefixWrapper>
              <Input
                type="text"
                label="Prefijo:"
                value={values.prefix}
                inputMode="text"
                onChange={(prefix) => toggleValues({ ...values, prefix })}
              />
            </StyledPrefixWrapper>
            <StyledNumberWrapper>
              <Input
                type="text"
                label="Número de teléfono:"
                value={values.tel}
                inputMode="tel"
                onChange={(tel) => toggleValues({ ...values, tel })}
              />
            </StyledNumberWrapper>
          </StyledPhoneWrapper>
        </StyledInputContainer>
        <Description>
          Podrás acceder a tu programa personalizado de inglés con una
          subscripción mensual de
          <span style={{ color: "#007AFF" }}> $13.99 USD/mes</span> una vez que
          esté disponible en tu región.
        </Description>
      </StyledForm>
    );
  };

  const renderThanks = () => {
    return (
      <StyledThanks>
        <StyledCheckmark
          src={getMediaUrl("icons/blue-checkmark", {
            extension: "svg",
          })}
        />
        <Title>¡Gracias!</Title>
        <Description>Has completado tu registro.</Description>
        <Description>
          Nos pondremos en contacto contigo una vez que el producto esté
          disponible en tu región.
        </Description>
        <Description>
          Mientras tanto, puedes escribirnos a soporte@masterenglish.com si
          tienes alguna pregunta.
        </Description>
      </StyledThanks>
    );
  };

  const handleSubmit = async () => {
    const { firstName, lastName, prefix, tel, email } = values;

    // send data
    await joinWaitlist(waitlistId, {
      firstName,
      lastName,
      email,
      tel: `${prefix} ${tel}`,
    });

    // alter state
    const newState = { ...state, waitlistId };

    // sync firstname from waitlist
    newState.user = {
      ...newState.user,
      firstname: firstName,
    };

    // persist questionnaire state
    console.warn(
      "TODO: if this is used, make sure the 3rd attribute is correct!"
    );
    await saveQuestionnaireState(questionnaire, newState, true);
    updateState(newState);

    // toggle view
    toggleSubmitted(true);
  };

  const isValid = () => {
    const { firstName, lastName, email, tel, prefix } = values;
    return (
      // all are required
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      tel !== "" &&
      prefix !== "" &&
      // special condition
      tel.length >= 6
    );
  };

  const renderButton = () => {
    const disabled = !isValid();
    return (
      <StyledButtonContainer>
        <Button
          text="Unirme a la lista de espera"
          disabled={disabled}
          type="primary"
          href=""
          onClick={handleSubmit}
        />
      </StyledButtonContainer>
    );
  };

  return (
    <StyledWaitlist>
      {submitted && renderThanks()}
      {!submitted && renderForm()}
      {!submitted && renderButton()}
    </StyledWaitlist>
  );
};

export default WaitlistContent;

export const waitlistContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: WaitlistContent,
  validator: waitlistContentValidator,
};
