import ThemeProvider from "providers/theme";
import HandleYourSubscriptionView from "./view";

const HandleYourSubscriptionViewWrapper = () => (
  <ThemeProvider>
    <HandleYourSubscriptionView />
  </ThemeProvider>
);

export default HandleYourSubscriptionViewWrapper;
