import { FunctionComponent, useEffect } from "react";
import { useTranslations } from "hooks/translations";
import { getInitialQuestion, getQuestionnaireUrl } from "lib/questionnaire";
import { useNavigate, useParams } from "react-router-dom";
import { QuestionnaireVersion } from "models/questionnaire";
import { loadCleanQuestionnaire } from "services/questionnaire";

const QuestionnaireVersionView: FunctionComponent = ({}) => {
  const navigate = useNavigate();
  const { version } = useParams();
  const v = version as QuestionnaireVersion;
  const t = useTranslations();

  useEffect(() => {
    const fn = async () => {
      // load the questionnaire
      const questionnaire = await loadCleanQuestionnaire("", v, false);

      // redirect user
      const url = getQuestionnaireUrl(
        t,
        v,
        getInitialQuestion(questionnaire).id
      );
      navigate(url);
    };
    fn();
  }, []);

  // show just empty view
  return null;
};

export default QuestionnaireVersionView;
