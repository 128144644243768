import Image from "components/image";
import { ImageContentType } from "models/content/image";
import { FunctionComponent } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import { ImageCSS, parseImageUrls } from "./image.styles";

interface ImageContentProps extends ContentProps {
  content: ImageContentType;
}

const ImageContent: FunctionComponent<ImageContentProps> = ({ content }) => {
  const { imageOptions } = content;
  const { alt } = imageOptions;

  const urls = parseImageUrls(imageOptions);

  return <Image urls={urls} alt={alt} css={ImageCSS} />;
};

export default ImageContent;

export const imageContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: ImageContent,
  validator: imageContentValidator,
};
