export type Currency = "USD" | "MXN" | "ARS";

const getCurrencyLocale = (currency: Currency) => {
  switch (currency) {
    case "MXN":
      return "es-MX";
    case "ARS":
      return "es-AR";
    default:
      return "en-US";
  }
};

// creates a currency formatter
export const format = (amount: number, currency: Currency) => {
  const prefix = getCurrencyPrefix(currency);
  const locale = getCurrencyLocale(currency);
  const formatted = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
  })
    .format(amount)
    .replace(prefix, "")
    .trim();
  return formatted;
};

// formats a single currency
export const formatCurrency = (amount: number, currency: Currency) => {
  const formatted = format(amount, currency);
  const prefix = getCurrencyPrefix(currency);
  const suffix = getCurrencySuffix(currency);
  const parts = [prefix, formatted, " ", suffix];
  return parts.join("");
};

export const formatCurrencyRange = (
  from: number,
  to: number,
  currency: Currency
) => {
  const formattedFrom = format(from, currency);
  const formattedTo = format(to, currency);
  const prefix = getCurrencyPrefix(currency);

  return [
    `${prefix}${formattedFrom.replace(currency, "")}`,
    `${prefix}${formattedTo}`,
  ].join(" - ");
};

export const getCurrencyPrefix = (currency: Currency) => {
  switch (currency) {
    case "USD":
    case "MXN":
    case "ARS":
    default:
      return "$";
  }
};

export const getCurrencySuffix = (currency: Currency) => {
  return currency;
};
