import styled from "styled-components";
import { ColorCode } from "theme/colors";
import { regular, semibold } from "theme/fonts";

export const StyledScheduleSummary = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: ${ColorCode.WHITE};
  border-radius: 20px;
  box-shadow: 0px 7px 77px rgba(0, 0, 0, 0.1);
  padding: 1.5rem 1.5rem;
  font-size: 0.875rem;
  color: ${ColorCode.BLACK};
`;

export const StyledText = styled.div`
  ${semibold()};
  margin-bottom: 1rem;
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
`;

export const StyledLabel = styled.div`
  ${regular()};
`;
