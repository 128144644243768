import styled from "styled-components";
import { alpha, ColorCode } from "theme/colors";
import { semibold } from "theme/fonts";

export const StyledDropdown = styled.div`
  margin-bottom: 1.5rem;
  &:last-of-type {
    margin-bottom: 0%;
  }
`;

export const StyledWrapper = styled.div`
  position: relative;
  height: 48px;
`;

export const StyledSelect = styled.select`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  appearance: none;
`;

export const StyledLabel = styled.label`
  font-size: 0.85em;
  margin-bottom: 0.25rem;
  color: ${ColorCode.BLACK};
`;

export const StyledOverlay = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  border: 1px solid ${alpha(ColorCode.BLACK, 0.03)};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${ColorCode.WHITE};
  box-shadow: 0px 3px 6px ${alpha(ColorCode.BLACK, 0.161)};

  pointer-events: none;
  flex: 1;
  display: flex;
  cursor: pointer;
  align-items: stretch;
`;

interface Props {
  selected: boolean;
}

export const StyledTextWrapper = styled.div<Props>`
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 1rem;
  overflow: hidden;
  ${semibold()};
  color: ${(props) => (props.selected ? ColorCode.BLUE : ColorCode.BLACK)};
`;

export const StyledText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

export const StyledArrowWrapper = styled.div`
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 1rem;
`;

export const StyledArrow = styled.img`
  width: 10px;
`;
