import { generateDeviceId, getEnv, isWindow } from "lib/env";
import { Fragment } from "react";
import Preconnect from "./components/external/preconnect";
import GoogleFonts from "./components/external/google-fonts";
import SharedHead from "components/head";
import Router from "routes/router";
import { getVersion } from "lib/version";
import { getPlatform } from "lib/platform";
import {
  ActiveQuestionnaireVersions,
  HotQuestionnaireVersions,
} from "models/questionnaire";
import OneTrust from "components/external/onetrust";
import GTagManager from "components/external/google-tag-manager";
import PaddleJS from "components/external/paddle";
import ErrorBoundary from "components/error-boundary";

const App = () => {
  // make sure clientUuid exists
  generateDeviceId();

  return (
    <Fragment>
      {/* these will be in <head> */}
      {import.meta.env.VITE_PUBLIC_NAME !== "production" && (
        <meta name="robots" content="noindex,follow" />
      )}
      <SharedHead />

      {/* these will be in <body> */}
      <Fragment>
        {/* Third party scripts */}
        <PaddleJS />
        <OneTrust />
        <GTagManager />

        {/* preconnect and google fonts */}
        <Preconnect />
        <GoogleFonts />
      </Fragment>

      {/* main thing */}
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </Fragment>
  );
};

export default App;

if (isWindow()) {
  const v = getVersion();
  const e = getEnv();
  const p = getPlatform();

  console.groupCollapsed(`MasterEnglish (env = ${e}, platform = ${p})`);
  console.info(`Running version ${v}`);
  console.info(`Supported versions: ${ActiveQuestionnaireVersions.join(", ")}`);
  console.info(`Hot versions: ${HotQuestionnaireVersions.join(", ")}`);
  console.groupEnd();
}
