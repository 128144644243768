import { useQuestionnaire } from "hooks/questionnaire";
import { useQuestionnaireState } from "hooks/state";
import { QuestionModel } from "models/question";
import { FunctionComponent, useEffect, useState } from "react";
import { StyledPreload } from "./preload.styles";
import { getFollowUpIds } from "lib/question";
import { parseHeaderUrl } from "./header.styles";
import { parseWallpaperUrl } from "./wallpaper";
import { getQuestionById } from "lib/questionnaire";
import { QuestionnaireModel } from "models/questionnaire";
import { SlideshowContentType } from "models/content/slideshow";
import { ImageContentType } from "models/content/image";
import { BubbleContentType } from "models/content/bubble";
import { MediaUrls } from "lib/media";
import { parseSlideshowUrls } from "views/questionnaire/question/content/base/types/slideshow";
import { parseImageUrls } from "views/questionnaire/question/content/base/types/image.styles";
import { parseBubbleUrl } from "views/questionnaire/question/content/base/types/bubble.styles";

interface PreloadProps {
  question: QuestionModel;
}

const Preload: FunctionComponent<PreloadProps> = ({ question }) => {
  const questionnaire = useQuestionnaire();
  const state = useQuestionnaireState();

  const [mediaUrls, toggleMediaUrls] = useState<string[]>([]);

  useEffect(() => {
    const preload = () => {
      const currentQuestion =
        question ||
        getQuestionById(questionnaire, questionnaire.initialQuestion);

      if (!currentQuestion) {
        // abort if no question found
        return;
      }

      // get next ids (remove visited ones)
      const nextIds = getFollowUpIds(currentQuestion).filter(
        (id) => !state.visitedQuestionIds.includes(id)
      );

      // parse next images
      const mediaUrls = getMediaUrls(nextIds, questionnaire);
      toggleMediaUrls(mediaUrls);
    };

    // start preload
    preload();
  }, [question.id]);

  return (
    <StyledPreload>
      {mediaUrls.map((url) => {
        return <img key={url} src={url} alt={url} />;
      })}
    </StyledPreload>
  );
};

export default Preload;

const getMediaUrls = (nextIds: number[], questionnaire: QuestionnaireModel) => {
  const mediaUrls: string[] = [];
  nextIds.forEach((id) => {
    const question = getQuestionById(questionnaire, id);
    if (question) {
      // parse each kind of image
      getQuestionWallpaperUrls(mediaUrls, question);
      getHeaderUrls(mediaUrls, question);
      getQuestionSlideshowContentUrls(mediaUrls, question);
      getImageContentUrls(mediaUrls, question);
      getBubbleContentUrls(mediaUrls, question);
    }
  });

  // filter out undefined ones and create a plain array
  let uniqueUrls: string[] = [...mediaUrls.filter((url) => url !== undefined)];

  // remove duplicates
  uniqueUrls = uniqueUrls.filter(
    (url, index) => uniqueUrls.indexOf(url) === index
  );

  return uniqueUrls;
};

const getQuestionWallpaperUrls = (
  mediaUrls: string[],
  question: QuestionModel
) => {
  if (question.wallpaper) {
    const urls = parseWallpaperUrl(question.wallpaper);
    push(mediaUrls, urls);
  }
};

const getHeaderUrls = (mediaUrls: string[], question: QuestionModel) => {
  if (question.header) {
    const urls = parseHeaderUrl(question.header);
    push(mediaUrls, urls);
  }
};

const getQuestionSlideshowContentUrls = (
  mediaUrls: string[],
  question: QuestionModel
) => {
  const slideshowContents = (question.contents || []).filter(
    (c) => c.type === "slideshow"
  );
  slideshowContents.forEach((c) => {
    const { slideshowOptions } = c as SlideshowContentType;
    if (slideshowOptions && slideshowOptions.items) {
      slideshowOptions.items.forEach((item) => {
        const urls = parseSlideshowUrls(item);
        push(mediaUrls, urls);
      });
    }
  });
};

const getImageContentUrls = (mediaUrls: string[], question: QuestionModel) => {
  const imageContents = (question.contents || []).filter(
    (c) => c.type === "image"
  );
  imageContents.forEach((c) => {
    const { imageOptions } = c as ImageContentType;
    const urls = parseImageUrls(imageOptions);
    push(mediaUrls, urls);
  });
};

const getBubbleContentUrls = (mediaUrls: string[], question: QuestionModel) => {
  const bubbleContents = (question.contents || []).filter(
    (c) => c.type === "bubble"
  );
  bubbleContents.forEach((c) => {
    const { bubbleOptions } = c as BubbleContentType;
    const urls = parseBubbleUrl(bubbleOptions);
    push(mediaUrls, urls);
  });
};

// adds items from ItemUrls collection to imageUrls string array
const push = (mediaUrls: string[], urls: MediaUrls) => {
  mediaUrls.push(urls.mobile);
  mediaUrls.push(urls.tablet);
  mediaUrls.push(urls.desktop);
  if (urls.mobileWebp) {
    mediaUrls.push(urls.mobileWebp);
  }
  if (urls.tabletWebp) {
    mediaUrls.push(urls.tabletWebp);
  }
  if (urls.desktopWebp) {
    mediaUrls.push(urls.desktopWebp);
  }
};
