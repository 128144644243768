import Description from "components/description";
import Image from "components/image";
import Title from "components/title";
import styled from "styled-components";

export const StyledPreparingProgram = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
`;

export const StyledSpinner = styled(Image)`
  width: 42px;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  animation: rotation 0.8s infinite linear;
  margin-bottom: 3rem;
`;

export const StyledTitle = styled(Title)`
  font-size: 1.25rem;
  line-height: 1.25;
  margin-bottom: 1.5rem;
`;

export const StyledDescription = styled(Description)`
  max-width: 75%;
  text-align: center;
`;
