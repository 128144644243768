import ThemeProvider from "providers/theme";
import LoginFailureView from "./view";

const LoginFailureViewWrapper = () => {
  return (
    <ThemeProvider>
      <LoginFailureView />
    </ThemeProvider>
  );
};

export default LoginFailureViewWrapper;
