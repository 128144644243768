import Description from "components/description";
import Input from "components/form/input";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Title from "components/title";
import { useTranslations } from "hooks/translations";
import { read, write } from "lib/storage";
import {
  emailValidator,
  requiredValidator,
  runValidations,
  ValidatorFn,
} from "lib/validators";
import { SIGNUP_EMAIL_KEY } from "models/user";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "services/user";

const LoginExistsView = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const t = useTranslations();

  useEffect(() => {
    // check if we have email in the cache
    const cachedEmail = read<string>(SIGNUP_EMAIL_KEY);
    if (cachedEmail) {
      setEmail(cachedEmail);
    }
  }, []);

  const handleEmailChange = (email: string) => {
    setEmail(email);
    write(SIGNUP_EMAIL_KEY, email);
  };

  const handleSubmit = async () => {
    try {
      await forgotPassword(email);
      navigate(t("/login/success"));
    } catch (err) {
      navigate(t("/login/failure"));
    }
  };

  const validators: ValidatorFn[] = [requiredValidator(t), emailValidator(t)];

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("account_exists_button"),
        disabled: runValidations(email, validators) !== undefined,
        onClick: handleSubmit,
        type: "primary",
        href: t("/login/success"),
      },
    },
  ];

  return (
    <View
      title={t("account_exists_title")}
      description={t("account_exists_body")}
      footer
      showLogoInFooter
      cover
    >
      <Navbar />
      <ContentContainer>
        <Title>{t("account_exists_title")}</Title>
        <Description>{t("account_exists_body")}</Description>
        <Input
          unique
          type="email"
          inputMode="email"
          autoFocus
          value={email}
          enterKeyHint="go"
          validators={validators}
          placeholder={t("Email")}
          onChange={handleEmailChange}
          onSubmit={handleSubmit}
          autoComplete="username"
        />
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default LoginExistsView;
