import Button from "components/button";
import Fade from "components/fade";
import styled from "styled-components";
import { ColorCode } from "theme/colors";
import { semibold } from "theme/fonts";

export const StyledPopup = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  overflow: hidden;
`;

export const StyledPopupShim = styled.div`
  background-color: ${ColorCode.BLACK};
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const StyledPopupContent = styled.div`
  width: 100%;
  height: calc(100% - 55px - 20px);

  padding-left: 1.25rem;
  padding-right: 1.25rem;

  overflow: scroll;
`;

export const StyledPopupScrollContainer = styled.div`
  background-color: ${ColorCode.WHITE};
  position: absolute;
  bottom: 1.875rem;
  left: 0.625rem;
  right: 0.625rem;
  top: 1.875rem;
  border-radius: 20px;
  overflow: hidden;
`;

export const StyledTopRow = styled.div`
  background-color: ${ColorCode.WHITE};
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
`;

export const StyledBottomRow = styled.div`
  background-color: ${ColorCode.WHITE};
  height: 20px;
`;

export const StyledExitIcon = styled.img`
  cursor: pointer;
  width: 35px;
  height: 35px;
`;

export const StyledPopupTitle = styled.div`
  margin-bottom: 1.25rem;
  ${semibold()};
  font-size: 1rem;
  color: ${ColorCode.BLACK};
  text-transform: uppercase;
`;

export const StyledFade = styled(Fade)`
  position: absolute;
`;

export const StyledButton = styled(Button)`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;
