import ThemeProvider from "providers/theme";
import PrivacyPolicyView from "./view";

const PrivacyPolicyViewWrapper = () => (
  <ThemeProvider>
    <PrivacyPolicyView />
  </ThemeProvider>
);

export default PrivacyPolicyViewWrapper;
