import ThemeProvider from "providers/theme";
import NotFoundView from "./view";

const NotFoundViewWrapper = () => {
  return (
    <ThemeProvider>
      <NotFoundView />
    </ThemeProvider>
  );
};

export default NotFoundViewWrapper;
