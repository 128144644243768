import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import { alpha, ColorCode } from "theme/colors";
import devices from "theme/devices";
import { semibold } from "theme/fonts";
import { stretchToFullWidth, stretchToTop } from "theme/mixins";

interface WrapperProps extends ThemeProps {
  $size: "normal" | "large";
}

export const StyledImageWrapper = styled.div<WrapperProps>`
  ${stretchToFullWidth()};
  ${(props) => stretchToTop(props.$theme || "MasterEnglish")};

  position: relative;
  overflow: hidden;

  height: ${(props) => (props.$size === "normal" ? 242 : 386)}px;
  margin-bottom: 1rem;

  @media ${devices.tablet} {
    height: ${(props) => (props.$size === "normal" ? 350 : 550)}px;
    width: 100%;
    margin-top: 1.6rem;

    /* these override stretchToFullWidth() properties  */
    margin-left: -2rem;
    margin-right: -2rem;
    width: calc(100% + 2rem + 2rem);
  }

  @media ${devices.desktop} {
    height: 550px;
    margin-top: 2rem;

    /* these override stretchToFullWidth() properties  */
    margin-left: -2rem;
    margin-right: -2rem;
    width: calc(100% + 2rem + 2rem);
  }
`;

interface SlideProps {
  opacity: number;
}

export const StyledSlide = styled.div<SlideProps>`
  transition: opacity 1s;
  opacity: ${(props) => props.opacity};
`;

export const ImageCSS = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;

  @media ${devices.tablet} {
    border-radius: 20px;
  }

  @media ${devices.desktop} {
    background-position-y: 20%;
  }
`;

interface TextWrapperProps {
  $size: "normal" | "large";
}

export const StyledTextWrapper = styled.div<TextWrapperProps>`
  position: relative;
  height: 9.75rem;
  margin-top: ${(props) => (props.$size === "normal" ? 1 : 0.5)}rem;
`;

interface TextProps {
  $opacity: number;
  $size: "normal" | "large";
}

export const StyledText = styled.div<TextProps>`
  opacity: ${(props) => props.$opacity};
  transition: opacity 1s;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${semibold()};
  font-size: ${(props) => (props.$size === "normal" ? 1.25 : 1)}em;
  color: ${ColorCode.BLACK};

  @media ${devices.tablet} {
    text-align: center;
    font-size: 1.675em;
  }

  @media ${devices.desktop} {
  }
`;

export const StyledProgressContainer = styled.div<ThemeProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2.25rem;

  @media ${devices.tablet} {
    height: 4.25rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  background: ${alpha(ColorCode.BLACK, 0.35)};
  background: linear-gradient(
    ${alpha(ColorCode.BLACK, 0)} 0%,
    ${alpha(ColorCode.BLACK, 0.35)} 100%
  );

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      height: 4rem;
      background-color: linear-gradient(
        rgba(5, 5, 5, 0) 0%,
        rgba(5, 5, 5, 0.35) 100%
      );
    `}
`;

export const StyledProgressOuter = styled.div<ThemeProps>`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  height: 0.25rem;
  background-color: rgba(255, 255, 255, 0.5);

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      background-color: ${alpha(ColorCode.BLACK, 0.5)};
    `}

  @media ${devices.tablet} {
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
  }

  @media ${devices.desktop} {
  }
`;

interface ProgressProps extends ThemeProps {
  $state: boolean;
  $duration: number;
}

export const StylesProgressInner = styled.div<ProgressProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${ColorCode.YELLOW};
  width: ${(props) => (props.$state ? 100 : 0)}%;
  transition: width ${(props) => props.$duration}ms linear;

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      background-color: ${ColorCode.WHITE};
    `}
`;
