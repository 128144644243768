import ThemeProvider from "providers/theme";
import LicensesView from "./view";

const LicensesViewWrapper = () => (
  <ThemeProvider>
    <LicensesView />
  </ThemeProvider>
);

export default LicensesViewWrapper;
