import Description from "components/description";
import { replaceTags } from "lib/string";
import { DescriptionContentType } from "models/content/description";
import { FunctionComponent } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";

interface DescriptionContentProps extends ContentProps {
  content: DescriptionContentType;
}

const DescriptionContent: FunctionComponent<DescriptionContentProps> = ({
  content,
  tags,
}) => {
  const description = replaceTags(content.text, tags);
  return <Description>{description}</Description>;
};

export default DescriptionContent;

export const descriptionContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: DescriptionContent,
  validator: descriptionContentValidator,
};
