import { ViewType } from "models/view";
import styled from "styled-components";
import { ColorCode } from "theme/colors";
import { semibold } from "theme/fonts";

export const StyledPrice = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
`;

interface PanelProps {
  view: ViewType;
}

export const StyledPanel = styled.div<PanelProps>`
  background-color: ${(props) =>
    props.view === "dark" ? ColorCode.WHITE : ColorCode.LIGHT};
  border-radius: 22px;
  width: auto;
  display: inline-block;
  padding: 2rem;
`;

export const StyledHeader = styled.div`
  ${semibold()};
  color: ${ColorCode.BLACK};
  font-size: 1em;
  display: flex;
  justify-content: center;
`;

export const StyledNumber = styled.div`
  ${semibold()};
  font-size: 1.25em;
  color: ${ColorCode.BLUE};
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
`;

export const StyledFooter = styled.div`
  color: ${ColorCode.GRAY800};
  font-size: 1em;
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
`;
