import { Locale } from "./locale";

export interface PaddleConfigModel {
  locale: Locale;
  country?: "MX";
  method?: "inline";
  frameTarget?: string;
  frameStyle?: string;
  override: string;
  loadCallback: () => void;
}

// Card Type	              Card Number
// Valid card without 3DS	  4242 4242 4242 4242
// Valid card with 3DS	    4000 0038 0000 0446
// Declined card	          4000 0000 0000 0002

// Real card information cannot be used in the Paddle Sandbox.
// For the test cards below, enter a valid expiration date in the future, and any random CVC number. Each test card’s billing country is set to U.S.

export interface PaddleOrderDetailsModel {
  checkout: { checkout_id: number };
  locker: any[];
  order: {
    order_id: number;
  };
  state: "processing" | "processed" | "incomplete";
}

export interface PaddleSuccessModel {
  checkout: PaddleCheckoutModel;
  product: any;
  user: any;
}

interface PaddleCheckoutModel {
  completed: boolean;
}

export type PaddlePaymentMethod = "";

export enum PaddleEventType {
  CheckoutLoaded = "Checkout.Loaded",
  CheckoutPaymentSelected = "Checkout.Payment.Selected",
  CheckoutPaymentSelection = "Checkout.Payment.Selection",
  CheckoutCustomerDetails = "Checkout.Customer.Details",
}

export interface PaddleEventModel {
  event: PaddleEventType;
}
