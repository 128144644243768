import { Fragment, FunctionComponent } from "react";

const preconnect = (url?: string) => {
  if (url) {
    // different domain, hence cors
    return <link rel="preconnect" href={url} crossOrigin="use-credentials" />;
  }
};

const preloadFont = (url: string) => {
  // same domain, hence no cors
  return <link rel="preload" as="font" href={url} crossOrigin="" />;
};

const Preconnect: FunctionComponent = () => {
  return (
    <Fragment>
      {preconnect(import.meta.env.VITE_PUBLIC_API_URL)}
      {preconnect(import.meta.env.VITE_PUBLIC_ONETRUST_URL)}
      {preconnect("https://www.googletagmanager.com")}
      {preconnect("https://cdn.paddle.com/paddle/")}
      {preloadFont("/fonts/open-sans-v27-latin-regular.woff2")}
      {preloadFont("/fonts/open-sans-v27-latin-600.woff2")}
    </Fragment>
  );
};

export default Preconnect;
