import { Fragment, FunctionComponent } from "react";

const OneTrust: FunctionComponent = () => {
  return (
    <Fragment>
      <script
        id="onetrust-load"
        src={import.meta.env.VITE_PUBLIC_ONETRUST_URL}
        data-document-language="true"
        data-domain-script={import.meta.env.VITE_PUBLIC_ONETRUST_DOMAIN}
      />
    </Fragment>
  );
};

export default OneTrust;
