import Button from "components/button";
import { getStoreUrl } from "lib/store";
import { AppStoreButtonContentType } from "models/content/app-store-button";
import { FunctionComponent, useEffect, useState } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";

interface AppStoreButtonContentProps extends ContentProps {
  content: AppStoreButtonContentType;
}

const AppStoreButtonContent: FunctionComponent<AppStoreButtonContentProps> = ({
  content,
}) => {
  const [url, toggleUrl] = useState("");

  const handleClick = () => {
    window.location.assign(url);
  };

  useEffect(() => {
    toggleUrl(getStoreUrl());
  });

  return (
    <Button
      text={content.appStoreButtonOptions.text}
      onClick={handleClick}
      type="primary"
      href={url}
    ></Button>
  );
};

export default AppStoreButtonContent;

export const appStoreButtonContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: AppStoreButtonContent,
  validator: appStoreButtonContentValidator,
};
