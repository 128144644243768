import styled from "styled-components";
import { ColorCode } from "theme/colors";

export const StyledWaitlist = styled.div``;

export const StyledForm = styled.div``;

export const StyledInputContainer = styled.div``;

export const StyledThanks = styled.div``;

export const StyledButtonContainer = styled.div``;

export const StyledCheckmark = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;

  width: 3rem;
  height: 3rem;
`;

export const StyledPhoneWrapper = styled.div`
  display: flex;
`;

export const StyledPrefixWrapper = styled.div`
  width: 75px;
  margin-right: 1rem;
`;

export const StyledNumberWrapper = styled.div`
  flex-grow: 1;
`;

export const StyledLine = styled.div`
  border-top: 1px solid ${ColorCode.MERCURY};
  margin-top: 2rem;
  margin-bottom: 3.5rem;
`;
