import { replaceTags } from "lib/string";
import { FunctionComponent } from "react";
import { StyledFootnote } from "./footnote.styles";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import { FootnoteContentType } from "models/content/footnote";
import { useTheme } from "hooks/theme";

interface FootnoteContentProps extends ContentProps {
  content: FootnoteContentType;
}

const FootnoteContent: FunctionComponent<FootnoteContentProps> = ({
  content,
  tags,
}) => {
  const theme = useTheme();
  const footnote = replaceTags(content.text, tags);
  return <StyledFootnote $theme={theme}>{footnote}</StyledFootnote>;
};

export default FootnoteContent;

export const footnoteContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: FootnoteContent,
  validator: footnoteContentValidator,
};
