import ConfigProvider from "providers/config";
import QuestionnaireQuestionView from "./view";
import ThemeProvider from "providers/theme";
import QuestionnaireProvider from "providers/questionnaire";
import { useParams } from "react-router-dom";
import { QuestionnaireVersion } from "models/questionnaire";
import StateProvider from "providers/state";
import QuestionProvider from "providers/question";
import ProductsProvider from "providers/products";

const QuestionnaireQuestionViewWrapper = () => {
  const { version: v, question: q } = useParams();
  const version = v as QuestionnaireVersion;
  const questionId = parseInt(q as string);

  return (
    <ThemeProvider>
      <ConfigProvider>
        <StateProvider>
          <QuestionnaireProvider version={version} questionId={questionId}>
            <QuestionProvider questionId={questionId}>
              <ProductsProvider>
                <QuestionnaireQuestionView />
              </ProductsProvider>
            </QuestionProvider>
          </QuestionnaireProvider>
        </StateProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default QuestionnaireQuestionViewWrapper;
