import { RouteObject } from "react-router-dom";
import TermsOfUseViewWrapper from "./terms-of-use/wrapper";
import PrivacyPolicyViewWrapper from "./privacy-policy/wrapper";
import LicensesViewWrapper from "./licenses/wrapper";

export const legalRoutes: RouteObject[] = [
  { path: "terminos-de-uso", element: <TermsOfUseViewWrapper /> },
  { path: "es/terminos-de-uso", element: <TermsOfUseViewWrapper /> },
  { path: "politica-de-privacidad", element: <PrivacyPolicyViewWrapper /> },
  { path: "es/politica-de-privacidad", element: <PrivacyPolicyViewWrapper /> },
  { path: "licencias", element: <LicensesViewWrapper /> },
];
