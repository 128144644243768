import { getMediaUrl, MediaUrls } from "lib/media";
import { BubbleOptions } from "models/content/bubble";
import styled, { css } from "styled-components";
import devices from "theme/devices";

export const parseBubbleUrl = (bubbleOptions: BubbleOptions): MediaUrls => {
  const filename = `bubble/${bubbleOptions.filename}`;
  const normal = getMediaUrl(filename, { size: "2x" });
  const webp = getMediaUrl(filename, { extension: "webp", size: "2x" });

  // no 3x available
  return {
    mobile: normal,
    mobileWebp: webp,
    tablet: normal,
    tabletWebp: webp,
    desktop: normal,
    desktopWebp: webp,
  };
};

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  align-items: center;
  z-index: 99;
`;

export const ImageCSS = css`
  width: 124px;
  height: 124px;
  margin: 0 auto;

  margin-top: 2.25rem;
  margin-bottom: 2rem;

  @media ${devices.mobile} {
    margin-top: 1.75rem;
    margin-bottom: 2.2rem;
  }

  @media ${devices.tablet} {
    width: 180px;
    height: 180px;
    margin-top: 5rem;
  }

  @media ${devices.desktop} {
    margin-top: 7rem;
  }
`;
