import { FunctionComponent } from "react";
import LicensesES from "./content/es";
import View from "components/layout/view";
import { StyledContentContainer } from "../view.styles";
import Navbar from "components/navigation/navbar";
import { useTranslations } from "hooks/translations";
import Fade from "components/fade";

const LicensesView: FunctionComponent = () => {
  const t = useTranslations();

  return (
    <View
      title={t("page_title_licenses")}
      description={t("meta_description_licenses_page")}
      footer
      showLogoInFooter
      noindex
      view="dark"
    >
      <Navbar />
      <Fade />
      <StyledContentContainer>
        <LicensesES />
      </StyledContentContainer>
    </View>
  );
};

export default LicensesView;
