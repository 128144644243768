import styled, { css, keyframes } from "styled-components";
import devices from "theme/devices";

import { ColorCode } from "theme/colors";
import { ThemeProps } from "models/theme";

interface SwitchProps extends ThemeProps {
  $active: boolean;
}

export const StyledSwitch = styled.div<SwitchProps>`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 0.15rem solid ${ColorCode.HEX_dbdbdb};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${ColorCode.WHITE};

  ${(props) => props.$active && css``}

  @media ${devices.tablet} {
    width: 30px;
    height: 30px;
  }

  ${(props) => css`
    ${props.$active &&
    css`
      border-color: ${ColorCode.BLUE};
    `};

    ${props.$theme === "HablaIngles" &&
    css`
      border-color: ${ColorCode.HEX_E4E4E4};

      ${props.$active &&
      css`
        border-color: ${ColorCode.BLUE};
      `};
    `}
  `}
`;

interface DotProps {
  $animate: boolean;
}

export const StyledDot = styled.div<DotProps>`
  background-color: ${ColorCode.BLUE};
  border-radius: 50%;
  width: 10px;
  height: 10px;

  ${(props) =>
    props.$animate &&
    css`
      animation-name: ${animation};
      animation-duration: 0.3s;
      animation-timing-function: ease-out;
    `}

  @media ${devices.tablet} {
    width: 16px;
    height: 16px;
  }
`;

interface CheckmarkProps {
  $animate: boolean;
}

export const StyledCheckmark = styled.img<CheckmarkProps>`
  width: 20px;
  height: 20px;

  ${(props) =>
    props.$animate &&
    css`
      animation-name: ${animation};
      animation-duration: 0.3s;
      animation-timing-function: ease-out;
    `}

  @media ${devices.tablet} {
    width: 30px;
    height: 30px;
  }
`;

const animation = keyframes`
    from {
        transform: scale(1.25, 1.25);
    }
    to {
        transform: scale(1, 1);
    }
`;
