import { getPlatform, PlatformType } from "./platform";

const APPLE_STORE_URL =
  "https://itunes.apple.com/app/master-english/id1543401574";
const ANDROID_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.masterenglishprogram";

export const getStoreUrl = () => {
  const platform = getPlatform();
  if (platform === PlatformType.ANDROID) {
    return ANDROID_STORE_URL;
  }
  return APPLE_STORE_URL;
};
