import Wait from "components/wait";
import styled from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";
import { stretchToFullWidth } from "theme/mixins";

export const StyledChartWrapper = styled.div`
  ${stretchToFullWidth()};

  @media ${devices.tablet} {
    margin-left: 0;
    margin-right: 0;
  }

  @media ${devices.desktop} {
  }

  margin-bottom: 3rem;
  padding-top: 3rem;
`;

export const StyledProgress = styled.div`
  height: 54px;
  position: relative;
  overflow: hidden;
  display: flex;

  @media ${devices.tablet} {
    height: 75px;
  }

  @media ${devices.desktop} {
    height: 75px;
  }
`;

export const StyledBarPositive = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${ColorCode.BLUE};
  color: ${ColorCode.WHITE};

  @media ${devices.tablet} {
    font-size: 1.25em;
  }

  @media ${devices.desktop} {
    font-size: 1.25em;
  }
`;

interface Props {
  speed: number;
}

export const StyledBarNegative = styled.div<Props>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${ColorCode.WHITE_SMOKE};
  color: ${ColorCode.BLACK};
  transition: clip-path ${(props) => props.speed}ms linear;
`;

export const StyledWait = styled(Wait)`
  margin-bottom: 1.25rem;
`;
