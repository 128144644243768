import styled, { css } from "styled-components";
import devices from "theme/devices";
import { ColorCode } from "theme/colors";
import { ViewType } from "models/view";
import Description from "components/description";
import { semibold } from "theme/fonts";
import { ThemeProps } from "models/theme";

export const StyledGuarantee = styled.div`
  position: relative;
`;

interface WrapperProps extends ThemeProps {}

export const StyledWrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  z-index: 75;

  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      display: none;
    `}
`;

export const StyledImage = styled.img`
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 200px;
  align-self: center; /* safari fix */

  @media ${devices.tablet} {
    width: 250px;
  }

  @media ${devices.desktop} {
    width: 320px;
    margin-top: 4rem;
    margin-bottom: 24rem;
  }
`;

interface WhiteProps extends ThemeProps {
  $view: ViewType;
}

export const StyledWhite = styled.div<WhiteProps>`
  background-color: ${ColorCode.WHITE};
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 9rem 2rem 2rem 2rem;
  margin-top: -100px;
  margin-bottom: 2rem;

  ${(props) =>
    props.$view === "light" &&
    css`
      margin-bottom: 0;
      padding-bottom: 0;
    `}

  @media ${devices.tablet} {
    margin-left: -4rem;
    margin-right: -4rem;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: -125px;
  }

  @media ${devices.desktop} {
    margin-top: -540px;
    padding: 12rem 2rem 1.5rem;
  }

  a {
    display: inline;
  }

  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      background-color: transparent;
    `}
`;

export const StyledLabel = styled.div`
  ${semibold()};
  color: ${ColorCode.BLACK};

  @media ${devices.tablet} {
    font-size: 1.25em;
    line-height: 1.375em;
  }

  @media ${devices.desktop} {
    font-size: 1.25em;
    line-height: 1.375em;
    margin-bottom: 1rem;
  }
`;

export const StyledText = styled(Description)`
  @media ${devices.tablet} {
    font-size: 1.25em;
    line-height: 1.375em;
  }

  @media ${devices.desktop} {
    font-size: 1.25em;
    line-height: 1.375em;
    margin-bottom: 1rem;
  }
`;
