import { FunctionComponent, ReactNode } from "react";
import { StyledCol } from "./col.styles";

interface ColProps {
  className?: string;
  children: ReactNode;
}

const Col: FunctionComponent<ColProps> = ({ children, className }) => {
  return <StyledCol className={className}>{children}</StyledCol>;
};

export default Col;
