import { isWindow } from "lib/env";
import { gtmJSException } from "lib/gtm";
import { Component, ErrorInfo } from "react";

export const reportException = (
  message: string,
  stack?: string,
  componentStack?: string
) => {
  const { userAgent } = navigator;
  gtmJSException(message, userAgent, stack, componentStack);
};

class ErrorBoundary extends Component<any> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // report error
    reportException(
      error.message,
      error.stack,
      errorInfo.componentStack || undefined
    );
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;

if (isWindow()) {
  // add global event tracker, this will catch errors not handled by <ErrorBoundary />
  (window as any).onerror = function (
    message: any,
    _source: any,
    _lineno: any,
    _colno: any,
    error: any
  ) {
    reportException(message, error.stack);
  };
}
