import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import { stretchToFullWidth } from "theme/mixins";

interface TableProps extends ThemeProps {}

export const StyledTable = styled.table<TableProps>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 1px 0px;
  margin-bottom: 2.5rem;
  margin-top: 1rem;

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      ${stretchToFullWidth()};
      border-spacing: 0px;
    `}
`;

interface TableIconProps extends ThemeProps {}

export const StyledTableIcon = styled.img<TableIconProps>`
  width: 24px;

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      width: 18px;
    `}
`;

export const StyledLogo = styled.img`
  width: 24px;
  height: 13px;
`;
