import { request } from "lib/request";

interface JoinWaitlistParams {
  firstName: string;
  lastName: string;
  email: string;
  tel: string;
  feedback?: string;
}

export const joinWaitlist = async (
  waitlistId: number,
  data: JoinWaitlistParams
) => {
  const response = await request({
    method: "POST",
    url: "/v1/join-waitlist",
    data: { ...data, waitlistId },

    // not not auth this endpoint
    authenticated: true,
  });
  return response.data;
};
