import { useView } from "hooks/view";
import { FunctionComponent, ReactNode } from "react";
import { StyledTableRow } from "./table-row.styles";

interface TableRowProps {
  children: ReactNode;
}

const TableRow: FunctionComponent<TableRowProps> = ({ children }) => {
  const view = useView();

  return <StyledTableRow $view={view}>{children}</StyledTableRow>;
};

export default TableRow;
