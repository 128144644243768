import { StaticViewIdEnum } from "models/question";
import QuestionnaireEmailExistsView from "./view";
import { useParams } from "react-router-dom";
import { QuestionnaireVersion } from "models/questionnaire";
import ConfigProvider from "providers/config";
import StateProvider from "providers/state";
import QuestionnaireProvider from "providers/questionnaire";

const QuestionnaireEmailExistsViewWrapper = () => {
    const { version: v } = useParams();
    const version = v as QuestionnaireVersion;

    return (
    <ConfigProvider>
      <StateProvider>
        <QuestionnaireProvider
          version={version}
          questionId={StaticViewIdEnum.EMAIL_EXISTS}
        >
          <QuestionnaireEmailExistsView />
        </QuestionnaireProvider>
      </StateProvider>
    </ConfigProvider>
  );
};

export default QuestionnaireEmailExistsViewWrapper;
