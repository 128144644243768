import Badge from "components/badge";
import styled from "styled-components";

export const StyledBadge = styled(Badge)`
  z-index: 99;

  img {
    top: -3rem;
    right: 0.5rem;
  }
`;
