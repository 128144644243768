import ConfigProvider from "providers/config";
import QuestionnaireRaffleView from "./view";

const QuestionnaireRaffleViewWrapper = () => {
  return (
    <ConfigProvider>
      <QuestionnaireRaffleView />
    </ConfigProvider>
  );
};

export default QuestionnaireRaffleViewWrapper;
