import { FunctionComponent } from "react";
import { StyledButton, StyledSpan, StyledToggle } from "./toggle.styles";

interface ToggleProps {
  collapsed: boolean;
  onToggle: (collapsed: boolean) => void;
}

const Toggle: FunctionComponent<ToggleProps> = ({ collapsed, onToggle }) => {
  const handleClick = () => {
    const newState = !collapsed;
    onToggle(newState);
    if (newState) {
      document.body.classList.remove("noscroll");
    } else {
      document.body.classList.add("noscroll");
    }
  };

  return (
    <StyledToggle onClick={handleClick}>
      <StyledButton>
        <StyledSpan $collapsed={collapsed} />
      </StyledButton>
    </StyledToggle>
  );
};

export default Toggle;
