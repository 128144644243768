import { useTheme } from "hooks/theme";
import { useView } from "hooks/view";
import { toHTML } from "lib/string";
import { FunctionComponent, ReactNode } from "react";
import { StyledTableCell, StyledTableCellContent } from "./table-cell.styles";

interface TableCellProps {
  className?: string;
  align?: "left" | "center" | "right";
  children?: ReactNode;
}

const TableCell: FunctionComponent<TableCellProps> = ({
  children,
  align = "left",
  className,
}) => {
  const theme = useTheme();
  const view = useView();
  const escaped = typeof children === "string" ? toHTML(children) : children;
  return (
    <StyledTableCell $theme={theme} className={className} $view={view}>
      <StyledTableCellContent $align={align}>{escaped}</StyledTableCellContent>
    </StyledTableCell>
  );
};

export default TableCell;
