import Image from "components/image";
import { useQuestion } from "hooks/question";
import { getMediaUrl, MediaExtension, MediaUrls } from "lib/media";
import { WallpaperModel } from "models/question";
import { FunctionComponent } from "react";
import {
  StyledGradient,
  StyledWallpaper,
  WallpaperCSS,
} from "./wallpaper.styles";

export const parseWallpaperUrl = (wallpaper: WallpaperModel): MediaUrls => {
  const { image } = wallpaper;
  const extension: MediaExtension = "png";
  const mobile = getMediaUrl(image, { extension, size: "2x" });
  const mobileWebp = getMediaUrl(image, {
    extension: "webp",
    size: "2x",
  });
  const tablet = getMediaUrl(image, { extension, size: "3x" });
  const tabletWebp = getMediaUrl(image, {
    extension: "webp",
    size: "3x",
  });
  const desktop = getMediaUrl(image, { extension, size: "3x" });
  const desktopWebp = getMediaUrl(image, {
    extension: "webp",
    size: "3x",
  });
  return { mobile, tablet, desktop, mobileWebp, tabletWebp, desktopWebp };
};

const Wallpaper: FunctionComponent = () => {
  const question = useQuestion();
  const { wallpaper, navbar } = question;

  if (!wallpaper) {
    return null;
  }

  const { image, gradient = false } = wallpaper;
  const urls = parseWallpaperUrl(wallpaper);

  return (
    <StyledWallpaper lowered={navbar !== undefined}>
      <Image urls={urls} alt={image} css={WallpaperCSS} />
      {gradient && <StyledGradient />}
    </StyledWallpaper>
  );
};

export default Wallpaper;
