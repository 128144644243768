import styled from "styled-components";
import Description from "components/description";
import devices from "theme/devices";
import { ThemeProps } from "models/theme";

export const StyledFootnote = styled(Description)<ThemeProps>`
  font-size: 0.75em;
  padding-bottom: 0rem;

  @media ${devices.tablet} {
    font-size: 1em;
  }

  @media ${devices.desktop} {
  }
`;
