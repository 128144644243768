import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";
import { semibold, weight } from "theme/fonts";

export const StyledWrapper = styled.div`
  position: relative;
  margin-top: 0.25rem;
`;

interface OptionProps {
  $active: boolean;
}

export const StyledOption = styled.div<OptionProps>`
  display: flex;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: -2rem;
  margin-right: -2rem;
  cursor: pointer;

  @media ${devices.tablet} {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }
`;

export const StyledSelector = styled.div<ThemeProps>`
  margin-right: 1rem;
  padding-top: 2px;
  display: flex;
  align-items: flex-start;

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      padding-top: 4px;
    `}

  @media ${devices.tablet} {
    margin-right: 1.5rem;
  }
`;

interface ContentProps extends ThemeProps {}

export const StyledContent = styled.div<ContentProps>`
  ${semibold()};

  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      ${weight(500)}
    `};

  color: ${ColorCode.BLACK};

  @media ${devices.tablet} {
    font-size: 1.25em;
  }
`;
