import ThemeProvider from "providers/theme";
import AppLinkView from "./view";

const AppLinkViewWrapper = () => {
  return (
    <ThemeProvider>
      <AppLinkView />
    </ThemeProvider>
  );
};

export default AppLinkViewWrapper;
