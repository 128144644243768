import { TagMap } from "lib/tag";
import { OptionModel } from "models/option";
import { FunctionComponent } from "react";
import { ContentType } from "models/content";
import { ValidatorMethod } from "../validations";

// schemas
import { schema as titleSchema } from "./types/title";
import { schema as descriptionSchema } from "./types/description";
import { schema as footnoteSchema } from "./types/footnote";
import { schema as signupEmailSchema } from "./types/signup-email";
import { schema as signupPasswordSchema } from "./types/signup-password";
import { schema as signupNameSchema } from "./types/signup-name";
import { schema as programPromiseSchema } from "./types/program-promise";
import { schema as studyScheduleSchema } from "./types/study-schedule";
import { schema as daySelectionSchema } from "./types/day-selection";
import { schema as daySelectionConfirmSchema } from "./types/day-selection-confirm";
import { schema as proofProgressSchema } from "./types/proof-progress";
import { schema as listSchema } from "./types/list";
import { schema as slideshowSchema } from "./types/slideshow";
import { schema as preparingProgramSchema } from "./types/preparing-program";
import { schema as preparingProgramSimpleSchema } from "./types/preparing-program-simple";
import { schema as paragraphsSchema } from "./types/paragraphs";
import { schema as guaranteeSchema } from "./types/guarantee";
import { schema as paymentSchema } from "./types/payment";
import { schema as appRatingSchema } from "./types/app-rating";
import { schema as waitSchema } from "./types/wait";
import { schema as appStoreButtonSchema } from "./types/app-store-button";
import { schema as startHereImageSchema } from "./types/start-here-image";
import { schema as tableSchema } from "./types/table";
import { schema as testimonialSchema } from "./types/testimonial";
import { schema as imageSchema } from "./types/image";
import { schema as bubbleSchema } from "./types/bubble";
import { schema as waitlistSchema } from "./types/waitlist";
import { schema as priceSchema } from "./types/price";
import { schema as skillsToImproveSchema } from "./types/skills-to-improve";
import { schema as moreInfoOfSchema } from "./types/more-info-of";
import { schema as programChangeWishSchema } from "./types/program-change-wish";
import { schema as paddleSchema } from "./types/paddle";
import { schema as scheduleSummarySchema } from "./types/schedule-summary";

export const buildSchema = (content: ContentType): ContentSchema => {
  const { type } = content;
  switch (type) {
    case "title":
      return titleSchema;
    case "description":
      return descriptionSchema;
    case "footnote":
      return footnoteSchema;
    case "signupEmail":
      return signupEmailSchema;
    case "signupPassword":
      return signupPasswordSchema;
    case "signupName":
      return signupNameSchema;
    case "programPromise":
      return programPromiseSchema;
    case "studySchedule":
      return studyScheduleSchema;
    case "daySelection":
      return daySelectionSchema;
    case "daySelectionConfirm":
      return daySelectionConfirmSchema;
    case "proofProgress":
      return proofProgressSchema;
    case "list":
      return listSchema;
    case "slideshow":
      return slideshowSchema;
    case "wait":
      return waitSchema;
    case "preparingProgram":
      return preparingProgramSchema;
    case "preparingProgramSimple":
      return preparingProgramSimpleSchema;
    case "paragraphs":
      return paragraphsSchema;
    case "guarantee":
      return guaranteeSchema;
    case "payment":
      return paymentSchema;
    case "appRating":
      return appRatingSchema;
    case "appStoreButton":
      return appStoreButtonSchema;
    case "startHereImage":
      return startHereImageSchema;
    case "table":
      return tableSchema;
    case "testimonial":
      return testimonialSchema;
    case "image":
      return imageSchema;
    case "bubble":
      return bubbleSchema;
    case "waitlist":
      return waitlistSchema;
    case "price":
      return priceSchema;
    case "skillsToImprove":
      return skillsToImproveSchema;
    case "moreInfoOf":
      return moreInfoOfSchema;
    case "programChangeWish":
      return programChangeWishSchema;
    case "paddle":
      return paddleSchema;
    case "scheduleSummary":
      return scheduleSummarySchema;
    default:
      throw new Error(`Unsupported questionnaire content type: ${type}`);
  }
};

export interface OptionClickInterface {
  onOptionClick: (option: OptionModel) => void;
}

export interface ContentProps {
  content: any;
  tags: TagMap;
  initial: boolean;
  onChange: () => void; // signal content status might have changes ==> runs validations
  onSubmit: () => void; // allows contents to trigger navigation

  // allows navigation
  handleNavigation: (option: OptionModel, scroll: boolean) => Promise<void>;

  // gives a reference to scrollInstance
  handleScroll: () => void;
}

export interface ContentSchema {
  Component: FunctionComponent<ContentProps>;
  validator: ValidatorMethod;
}
