import ThemeProvider from "providers/theme";
import TermsOfUseView from "./view";

const TermsOfUseViewWrapper = () => (
  <ThemeProvider>
    <TermsOfUseView />
  </ThemeProvider>
);

export default TermsOfUseViewWrapper;
