import { css } from "styled-components";

export const ImageCSS = css`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: repeat-x;
  background-position: center bottom;
  z-index: 30;
  opacity: 0.15;
  width: 100%;
`;

// there rules were once applied, but cannot be used with <img />
/*

 object-fit: 375px;

  @media (max-width: 374px) {
    display: none;
  }

  @media (min-width: 376px) {
    object-fit: 414px;
  }

  @media ${devices.tablet} {
    object-fit: 810px;
  }

  @media ${devices.desktop} {
    object-fit: 1280px;
  }

*/
