import { FunctionComponent, ReactNode } from "react";
import { StyledRow } from "./row.styles";

interface RowProps {
  className?: string;
  children: ReactNode;
}

const Row: FunctionComponent<RowProps> = ({ children, className }) => {
  return <StyledRow className={className}>{children}</StyledRow>;
};

export default Row;
