import translations from "../../translations.json";
// note: move translation jsons to different place when we have more locales

const castTranslations: { [key: string]: string | undefined } = translations;

// console.info(translations);
// define a method to be passed down in translations
export const translate = (key: string) => {
  if (!translations) {
    throw new Error("No translations available!");
  }
  // first try to find the translation
  const translation = castTranslations[key];
  if (!translation) {
    console.warn(`No translation for "${key}"`);
    return key;
  }
  return translation;
};
