import { request } from "lib/request";

export const updateName = async (firstname: string) => {
  const response = await request<any>({
    method: "POST",
    url: "/v1/update-name",
    data: { firstname },
  });
  return response.data;
};
