import { ProductModel } from "models/product";
import { Fragment, FunctionComponent } from "react";
import PaddleFrame from "./providers/paddle";

interface PaymentComponentProps {
  product: ProductModel;
}

const PaymentComponent: FunctionComponent<PaymentComponentProps> = ({
  product,
}) => {
  const { paymentProvider } = product;

  return (
    <Fragment>
      {paymentProvider === "paddle" && <PaddleFrame product={product} />}
    </Fragment>
  );
};

export default PaymentComponent;
