import { FunctionComponent } from "react";
import { toHTML } from "lib/string";
import { StyledH2, StyledH1, StyledH2WithH1 } from "./title.styles";

export interface TitleProps {
  children: string;
  className?: string;
  mode?: "h1" | "h2" | "h2-with-h1-styles";
}

const Title: FunctionComponent<TitleProps> = ({
  children,
  className,
  mode = "h1",
}) => {
  const escaped = toHTML(children);
  switch (mode) {
    case "h1":
      return <StyledH1 className={className}>{escaped}</StyledH1>;
    case "h2":
      return <StyledH2 className={className}>{escaped}</StyledH2>;
    case "h2-with-h1-styles":
      return <StyledH2WithH1 className={className}>{escaped}</StyledH2WithH1>;
  }
  return null;
};

export default Title;
