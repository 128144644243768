import { FunctionComponent, useEffect } from "react";
import View from "components/layout/view";
import Title from "components/title";
import Description from "components/description";
import { useTranslations } from "hooks/translations";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import { clear, read } from "lib/storage";
import Tiles from "components/tiles";
import { replaceTags } from "lib/string";
import { USED_EMAIL_KEY } from "../update-email/view";
import Navbar from "components/navigation/navbar";
import { sendVirtualQuestionnaireQuestionShownEvent } from "services/event";
import { StaticViewIdEnum, StaticViewPathEnum } from "models/question";
import { useConfig } from "hooks/config";
import { useQuestionnaire } from "hooks/questionnaire";
import { getStaticUrl, getVersion } from "lib/questionnaire";
import { useNavigate } from "react-router-dom";

const QuestionnaireEmailExistsView: FunctionComponent = () => {
  const navigate = useNavigate();
  const t = useTranslations();
  const config = useConfig();
  const questionnaire = useQuestionnaire();

  useEffect(() => {
    // send fixed event
    sendVirtualQuestionnaireQuestionShownEvent({
      uuid: config.uuid,
      questionId: StaticViewIdEnum.EMAIL_EXISTS,
    });
  }, []);

  const href = getStaticUrl(
    t,
    getVersion(questionnaire),
    StaticViewPathEnum.UPDATE_EMAIL
  );

  const handleClick = () => {
    // clear stored email
    clear(USED_EMAIL_KEY);
    navigate(href);
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("email_exists_button1"),
        type: "secondary",
        onClick: handleClick,
        href,
      },
    },
  ];

  const email = read<string>(USED_EMAIL_KEY);
  const description = replaceTags(t("email_exists_p1"), {
    EMAIL: email || "",
  });

  return (
    <View title={t("email_exists_title")} description={description} cover>
      <Navbar />
      <Tiles />
      <ContentContainer>
        <Title>{t("email_exists_title")}</Title>
        <Description>{description}</Description>
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default QuestionnaireEmailExistsView;
