import { FunctionComponent, ReactNode } from "react";
import { StyledLink } from "./link.styles";

interface LinkProps {
  children: ReactNode;
  href: string;
  desktop?: boolean;
  onClick?: () => any;
}

const Link: FunctionComponent<LinkProps> = ({
  href,
  children,
  onClick,
  desktop = true,
}) => {
  return (
    <StyledLink
      href={href}
      $desktop={desktop}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
