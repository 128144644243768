import Badge from "components/badge";
import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import { regular, semibold } from "theme/fonts";

interface WrapperProps {
  $hasBadge: boolean;
}

export const StyledWrapper = styled.div<WrapperProps>`
  ${(props) =>
    props.$hasBadge &&
    css`
      padding-top: 1rem;
    `}
`;

export const StyledFrame = styled.div`
  background-color: ${ColorCode.BLUE};
  border: 3px solid ${ColorCode.BLUE};
  border-radius: 34px;
  overflow: hidden;
  margin-bottom: 2rem;
`;

interface HeaderProps extends ThemeProps {
  $hasBadge: boolean;
}

export const StyledHeader = styled.div<HeaderProps>`
  background-color: ${ColorCode.BLUE};
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  ${regular()};
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
  justify-content: center;

  ${(props) =>
    props.$hasBadge === true &&
    css`
      justify-content: flex-start;
    `}

  ${(props) => css`
    ${props.$theme === "HablaIngles" &&
    css`
      color: ${ColorCode.WHITE};
    `}
  `}
`;

export const StyledBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${ColorCode.WHITE};
  padding-top: 2rem;
`;

export const StyledPriceRow = styled.div`
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

export const StyledPrice = styled.div`
  ${semibold()};
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: ${ColorCode.BLACK};
`;

export const StyledCurrency = styled.div`
  ${semibold()};
  display: flex;
  justify-content: center;
  font-size: 1.125rem;
  color: ${ColorCode.BLACK};
  margin-left: 0.5rem;
`;

export const StyledMonth = styled.div`
  ${semibold()};
  display: flex;
  justify-content: center;
  font-size: 1.125rem;
  color: ${ColorCode.BLACK};
`;

export const StyledTextWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const StyledBadge = styled(Badge)`
  & img {
    right: -1.15rem;
    top: -1.75rem;
  }
`;

export const StyledOriginalPrice = styled.div`
  color: ${ColorCode.BLACK};
  display: flex;
  justify-content: center;
  font-size: 1.125rem;
  margin-top: 0.5rem;
`;

export const StyledStrikeThrough = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-decoration: line-through;
`;
