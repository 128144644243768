import {
  DEFAULT_PRODUCT_CONFIGURATIONS,
  NO_PRODUCT_CONFIGURATION,
  ProductModel,
} from "models/product";
import { QuestionnaireModel } from "models/questionnaire";

export const getProductConfiguration = (
  questionnaire: QuestionnaireModel,
  product: ProductModel
) => {
  const { productType, productPlan = "1M", currency } = product;
  const { productConfigurations = DEFAULT_PRODUCT_CONFIGURATIONS } =
    questionnaire;

  let configuration = productConfigurations.find((c) => {
    if (c.productType !== undefined && c.productType !== productType) {
      return false;
    }
    if (c.productPlan !== undefined && c.productPlan !== productPlan) {
      return false;
    }
    if (c.currency !== undefined && c.currency !== currency) {
      return false;
    }
    return true;
  });

  if (!configuration) {
    console.warn(
      `No configuration for the following product: ${product.productId}, ${product.productType}, ${product.productPlan}. Using backup!`
    );
    configuration = NO_PRODUCT_CONFIGURATION;
  }

  return configuration;
};
