import  { FunctionComponent } from "react";
import Background from "components/layout/background";
import Block from "components/layout/block";
import Section from "components/layout/section";
import Title from "components/title";
import Description from "components/description";
import { useTranslations } from "hooks/translations";

interface RedLadyProps {}

const RedLady: FunctionComponent<RedLadyProps> = () => {
  const t = useTranslations();

  return (
    <Section>
      <Block order={2}>
        <Title mode="h2-with-h1-styles">{t("section_2_title")}</Title>
        <Description>{t("section_2_description_p1_embedded")}</Description>
        <Description>{t("section_2_description_p2_embedded")}</Description>
        <Description>{t("section_2_description_p3_embedded")}</Description>
      </Block>
      <Block order={1}>
        <Background
          align="left"
          mobileImageUrl={"home/ManMobileBg@2x"}
          tabletImageUrl={"home/ManTabletBg"}
          desktopImageUrl={"home/ManDesktopBg"}
          extension={"jpg"}
        ></Background>
      </Block>
    </Section>
  );
};

export default RedLady;
