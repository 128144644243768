import Switch from "components/form/switch";
import { useTheme } from "hooks/theme";
import { FunctionComponent } from "react";
import {
  StyledContent,
  StyledOption,
  StyledSelector,
  StyledWrapper,
} from "./multi-selection.styles";

export interface MultiSelectionItemModel {
  id: number;
  text: string;
}

interface MultiSelectionProps {
  selected: number[]; // id of the selected option
  options: MultiSelectionItemModel[];
  onChoose: (id: number) => void;
}

const MultiSelection: FunctionComponent<MultiSelectionProps> = ({
  selected,
  options,
  onChoose,
}) => {
  const theme = useTheme();
  return (
    <StyledWrapper>
      {options.map((option) => {
        const isActive = selected.includes(option.id);
        return (
          <StyledOption
            $active={isActive}
            key={option.id}
            onClick={() => onChoose(option.id)}
          >
            <StyledSelector $theme={theme}>
              <Switch checkmark active={isActive} />
            </StyledSelector>
            <StyledContent $theme={theme}>{option.text}</StyledContent>
          </StyledOption>
        );
      })}
    </StyledWrapper>
  );
};

export default MultiSelection;
