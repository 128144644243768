import { RouteObject } from "react-router-dom";
import LoginExpiredViewWrapper from "./expired/wrapper";
import LoginExistsViewWrapper from "./exists/wrapper";
import LoginFailureViewWrapper from "./failure/wrapper";
import LoginFormViewWrapper from "./form/wrapper";
import LoginSuccessViewWrapper from "./success/wrapper";

export const loginRoutes: RouteObject[] = [
  { path: "inicio-de-sesion", element: <LoginFormViewWrapper /> },
  { path: "inicio-de-sesion/link", element: <LoginSuccessViewWrapper /> },
  { path: "inicio-de-sesion/caducado", element: <LoginExpiredViewWrapper /> },
  { path: "inicio-de-sesion/fallido", element: <LoginFailureViewWrapper /> },
  {
    path: "inicio-de-sesion/cuenta-existe",
    element: <LoginExistsViewWrapper />,
  },
];
