import { Fragment, FunctionComponent, useState } from "react";

const isSandbox = import.meta.env.VITE_PUBLIC_PADDLE_SANDBOX === "true";
const initScript = `
  ${isSandbox ? 'Paddle.Environment.set("sandbox");' : ""}
  Paddle.Setup({
    vendor: ${import.meta.env.VITE_PUBLIC_PADDLE_VENDOR_ID},
    eventCallback: function (data) {
      // Paddle react component will add delegatePaddleEvent method to handle events in a proper scope
      window.delegatePaddleEvent(data);
    },
  });
`;

const PaddleJS: FunctionComponent = () => {
  const [loaded, toggleLoaded] = useState(false);
  const [init, toggleInit] = useState(false);
  return (
    <Fragment>
      <script
        id="paddle-load"
        src="https://cdn.paddle.com/paddle/paddle.js"
        onLoad={() => {
          toggleLoaded(true);
          setTimeout(() => toggleInit(true), 1000);
        }}
      />
      {loaded && !init && <script id="paddle-init">{initScript}</script>}
    </Fragment>
  );
};

export default PaddleJS;
