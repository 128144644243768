import { ContentType } from "models/content";
import { TranslateFn } from "models/locale";
import { StateModel } from "models/state";

// block = won't show buttons at all (but view can be still changed)
export type ValidatorResult = "pass" | "fail" | "block";

export type ValidatorMethod = (
  state: StateModel,
  t: TranslateFn,
  content: ContentType
) => ValidatorResult;

// fail = show buttons, but as disabled
export const areAllPass = (validations: ValidatorResult[]) => {
  return validations.filter((v) => v === "pass").length === validations.length;
};

export const isThereFail = (validations: ValidatorResult[]) => {
  return validations.filter((v) => v === "fail").length > 0;
};

export const isThereBlock = (validations: ValidatorResult[]) => {
  return validations.filter((v) => v === "block").length > 0;
};
