import Description from "components/description";
import { ViewType } from "models/view";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";
import { semibold } from "theme/fonts";
import { ThemeProps } from "models/theme";

export const StyledAppRating = styled.div<ThemeProps>`
  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      display: flex;
      flex-direction: column;
      text-align: center;
    `}
`;

interface TextProps extends ThemeProps {
  $view: ViewType;
}

export const StyledAppRatingText = styled(Description)<TextProps>`
  ${(props) =>
    props.$view === "dark" &&
    css`
      color: ${ColorCode.WHITE};
      ${semibold()};
    `}

  margin-bottom: 2.5rem;
`;

export const StyledAppRatingStars = styled.div`
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

export const ImageCSS = css`
  object-fit: contain;
  background-repeat: no-repeat;
  object-position: center;
  width: 75%;
  display: block;
  height: 4rem;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.tablet} {
  }

  @media ${devices.desktop} {
    object-position: center 20%;
  }
`;

export const StyledStars = styled.img`
  width: 108px;
  align-self: center;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
`;
