import { FunctionComponent, useEffect } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import { PreparingProgramSimpleContentType } from "models/content/preparing-program-simple";
import {
  StyledDescription,
  StyledPreparingProgram,
  StyledSpinner,
  StyledTitle,
} from "./preparing-program-simple.styles";
import { getMediaUrl, MediaUrls } from "lib/media";

interface PreparingProgramSimpleContentProps extends ContentProps {
  content: PreparingProgramSimpleContentType;
}

const PreparingProgramSimpleContent: FunctionComponent<
  PreparingProgramSimpleContentProps
> = ({ onSubmit, content }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onSubmit();
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  const { preparingProgramSimpleOptions } = content;
  const { title, text } = preparingProgramSimpleOptions;

  const filename = `chat/Chat_Spinner`;
  const urls: MediaUrls = {
    mobile: getMediaUrl(filename, { size: "3x" }),
    mobileWebp: getMediaUrl(filename, {
      size: "3x",
      extension: "webp",
    }),
    tablet: getMediaUrl(filename, { size: "3x" }),
    tabletWebp: getMediaUrl(filename, {
      extension: "webp",
      size: "3x",
    }),
    desktop: getMediaUrl(filename, { size: "3x" }),
    desktopWebp: getMediaUrl(filename, {
      extension: "webp",
      size: "3x",
    }),
  };

  return (
    <StyledPreparingProgram>
      <StyledSpinner urls={urls} />
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{text}</StyledDescription>
    </StyledPreparingProgram>
  );
};

export default PreparingProgramSimpleContent;

export const preparingProgramSimpleContentValidator: ValidatorMethod = () => {
  // always block, use onSubmit mechanism to trigger nav
  return "block";
};

export const schema: ContentSchema = {
  Component: PreparingProgramSimpleContent,
  validator: preparingProgramSimpleContentValidator,
};
