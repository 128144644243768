import { getMediaUrl, MediaUrls } from "lib/media";
import { FunctionComponent } from "react";
import { ImageCSS, StyledLogo } from "./logo.styles";
import Image from "components/image";

interface LogoProps {
  link?: boolean;
}

const Logo: FunctionComponent<LogoProps> = ({ link = true }) => {
  const filenameMobile = `logos/LogoMarkWhiteMobile`;
  const filename = `logos/LogoMarkWhite`;

  const urls: MediaUrls = {
    mobile: getMediaUrl(filenameMobile, {
      extension: "svg",
    }),
    tablet: getMediaUrl(filename, {
      extension: "svg",
    }),
    desktop: getMediaUrl(filename, {
      extension: "svg",
    }),
  };

  return (
    <StyledLogo href={link ? "/" : undefined}>
      <Image urls={urls} alt="Master English" css={ImageCSS} />
    </StyledLogo>
  );
};

export default Logo;
