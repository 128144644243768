import styled from "styled-components";

export const StyledFade = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90px;
  z-index: 100;
  pointer-events: none;
  opacity: 0.3;
  background: linear-gradient(
    180deg,
    rgba(7, 14, 33, 0) 0%,
    rgba(7, 14, 33, 1) 100%
  );
`;
