import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";
import { archivo, light, regular, semibold } from "theme/fonts";

interface Props extends ThemeProps {
  $active: boolean;
}

export const StyledProducts = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
`;

export const StyledLargeText = styled.div<Props>`
  ${semibold()};
  font-size: 1.25rem;
  color: ${ColorCode.BLACK};
  ${(props) =>
    props.$active &&
    css`
      color: ${ColorCode.GREEN_DARK};
    `}

  @media ${devices.tablet} {
    font-size: 1.875rem;
  }

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      line-height: 1;
      margin-bottom: 0.25rem;
      color: ${ColorCode.BLACK};
      ${props.$active &&
      css`
        color: ${ColorCode.GREEN_DARK};
      `}
    `};

  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      ${archivo()};
    `};

  ${(props) =>
    props.$theme === "HablaIngles" &&
    props.$active &&
    css`
      color: ${ColorCode.BLACK};
    `};
`;

export const StyledNormalText = styled.div<Props>`
  ${semibold()};
  font-size: 0.85rem;
  margin-bottom: 0.25rem;

  @media ${devices.tablet} {
    font-size: 1.125rem;
  }

  color: ${ColorCode.BLACK};
  ${(props) =>
    props.$active &&
    css`
      color: ${ColorCode.GREEN_DARK};
    `}

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      ${semibold()};
      line-height: 1;
      margin-bottom: 1rem;
      color: ${ColorCode.BLACK};
      ${props.$active &&
      css`
        color: ${ColorCode.GREEN_DARK};
      `}
    `}

    ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      ${regular()};
      ${archivo()};
    `};

  ${(props) =>
    props.$theme === "HablaIngles" &&
    props.$active &&
    css`
      color: ${ColorCode.BLACK};
    `};
`;

export const StyledSmallText = styled.div<Props>`
  ${regular()};
  line-height: 1.5rem;
  font-size: 0.85rem;
  margin-top: 0.5rem;

  @media ${devices.tablet} {
    font-size: 1.125rem;
  }

  color: ${ColorCode.GRAY800};
  ${(props) =>
    props.$active &&
    css`
      color: ${ColorCode.GREEN_DARK};
    `}

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      ${light()};
      line-height: 1.5rem;
      color: ${ColorCode.BLACK};
      ${props.$active &&
      css`
        color: ${ColorCode.GREEN_DARK};
      `}
    `}

    ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      ${archivo()};
    `};

  ${(props) =>
    props.$theme === "HablaIngles" &&
    props.$active &&
    css`
      color: ${ColorCode.GRAY800};
    `};
`;
