import {
  Fragment,
  FunctionComponent,
  useState,
  useEffect,
  useRef,
} from "react";
import { useQuestion } from "hooks/question";
import ContentContainer from "components/layout/content";
import ActionContainer, { ActionType } from "components/layout/actions";
import { useQuestionnaire, useUpdateQuestionnaire } from "hooks/questionnaire";
import { useTranslations } from "hooks/translations";
import { buildSchema } from "./content/base/factory";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { useConfig, useUpdateConfig } from "hooks/config";
import {
  getMonthTags,
  getOnetimeFeeTags,
  getDayTags,
  getStudyScheduleTags,
  getInitialLevelTags,
  TagMap,
  getSubscriptionFeeTags,
  getSupportTags,
  getUserTags,
  getTargetLevelTags,
} from "lib/tag";
import { OptionModel } from "models/option";
import { useProducts } from "hooks/products";
import { ContentType } from "models/content";
import { hasContent, isSignupQuestion, parseNextQuestion } from "lib/question";
import { storeAnswer } from "lib/state";
import {
  areAllPass,
  isThereBlock,
  isThereFail,
  ValidatorMethod,
  ValidatorResult,
} from "./content/validations";
import Footnote from "components/questionnaire/footnote";
import { StateModel } from "models/state";
import { ConfigModel } from "models/config";
import { ProductModel } from "models/product";
import { TranslateFn } from "models/locale";
import { QuestionModel, StaticViewPathEnum } from "models/question";
import { checkRequirements } from "lib/requirement";
import { QuestionnaireModel, QuestionnaireVersion } from "models/questionnaire";
import {
  sendQuestionnaireAnswerEvent,
  sendQuestionnaireQuestionShownEvent,
} from "services/event";
import QuickMode from "components/questionnaire/quickmode";
import { saveQuestionnaireState } from "services/questionnaire";
import { signupUser } from "lib/signup";
import { OLD_OS_KEY, isLegacyPlatform } from "lib/platform";
import { read, write } from "lib/storage";
import OldOSWarning from "components/old-os-warning";
import { updateName } from "services/update-name";
import {
  getLastSignupView,
  getQuestionnaireUrl,
  getStaticUrl,
  getVersion,
} from "lib/questionnaire";
import { getPublicUrl } from "lib/env";
import { checkEmail } from "services/user";
import { useNavigate } from "react-router-dom";

export const SCROLL_DELAY = 2000;

const QUICKMODE = import.meta.env.VITE_PUBLIC_QUICKMODE === "true";

const QuestionnaireContent: FunctionComponent = () => {
  const config = useConfig();
  const updateConfig = useUpdateConfig();
  const questionnaire = useQuestionnaire();
  const updateQuestionnaire = useUpdateQuestionnaire();
  const question = useQuestion();
  const state = useQuestionnaireState();
  const updateQuestionnaireState = useUpdateQuestionnaireState();
  const products = useProducts();
  const t = useTranslations();
  const [initial, toggleInitial] = useState(true);
  const [oldOSWarning, toggleOldOSWarning] = useState(false);
  const version = getVersion(questionnaire);
  const navigate = useNavigate();

  // scroll status
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [scrolled, toggleScrolled] = useState(false);

  // store validation results for every content component (NOTE: also hidden contents will have entry)
  const [validations, toggleValidations] = useState<ValidatorResult[]>([]);

  // validation results
  const hasFail = isThereFail(validations);
  const hasBlock = isThereBlock(validations);

  // deconstruct question
  const { contents = [], options = [], scroll = "off" } = question;

  // an utility that checks if the content is visible (or if it should be)
  const isVisible = (content: ContentType) =>
    checkRequirements({
      questionnaire,
      config,
      state,
      requirements: content.requirements,
    });

  useEffect(() => {
    // send event of showing the question (fire and forget)
    sendQuestionnaireQuestionShownEvent({
      uuid: config.uuid,
      question,
      questionnaire,
    });

    // reset scroll in the beginning
    toggleScrolled(false);
    toggleInitial(false);

    // we we have afterDelay scroll
    let scrollTimer: number;
    if (scroll === "afterDelay") {
      scrollTimer = setTimeout(() => handleScroll(), SCROLL_DELAY);
    }

    // run validations with initial state
    const initialValidations: ValidatorResult[] = contents.map((content) => {
      if (isVisible(content)) {
        const schema = buildSchema(content);
        return schema.validator(state, t, content);
      }

      // non visibles return "pass" automatically
      return "pass";
    });

    // togle validaitons
    toggleValidations(initialValidations);

    // clear scroll timer
    return () => clearTimeout(scrollTimer);
  }, [question.id]);

  // Define navigation handler, option always means the actual button/option
  const handleNavigation = async (option: OptionModel): Promise<void> => {
    // first check for legacy
    if (isLegacyPlatform() && read<boolean>(OLD_OS_KEY) !== true) {
      toggleOldOSWarning(true);
      write(OLD_OS_KEY, true);
      return;
    }

    // if we are in the pw view, then do the signup (but not in V69!!!)
    if (
      hasContent(question, "signupEmail") &&
      version !== QuestionnaireVersion.V69
    ) {
      try {
        const email = state.user?.email || "";
        await checkEmail(email);
      } catch (_err) {
        // email taken, redirect to new page
        const url = getStaticUrl(
          t,
          version,
          StaticViewPathEnum.LOGIN_WITH_PASSWORD
        );
        navigate(url);
        return;
      }
    }

    // if we are in the pw view, then do the signup
    if (hasContent(question, "signupPassword")) {
      try {
        await signupUser({
          state,
          updateQuestionnaireState,
          question,
          questionnaire,
          updateQuestionnaire,
          t,
          navigate,
          config,
          updateConfig,
        });
      } catch (_err) {
        // prevent executing further code, if we are here it means signup has done a redirect
        return;
      }
    }

    // if name view, update the name to server (fire and forget)
    if (hasContent(question, "signupName")) {
      try {
        const firstname = state.user?.firstname || "";
        updateName(firstname);
      } catch (_err) {
        // nada, allow continuation
      }
    }

    // store answer to state
    storeAnswer(question, option, state, updateQuestionnaireState);

    // send event to backend (fire and forget)
    sendQuestionnaireAnswerEvent({
      uuid: config.uuid,
      config,
      state,
      questionnaire,
      question,
      option,
    });

    // if we have done the signup, save the questionnaire
    if (state.signupDone === true) {
      // note: fire and forget
      const finished = question.type === "PreparingProgram";
      saveQuestionnaireState(questionnaire, state, finished);
    }

    // recalculate logic as it might have changed after storing answer
    let nextId = parseNextQuestion(questionnaire, question, option, state);

    // a very special case
    if (nextId === -1) {
      navigate(getPublicUrl());
      return;
    }

    // if we have signupDone and the next view is a signup, but not this one ==> skip the signup
    if (
      state.signupDone &&
      !isSignupQuestion(question.id, questionnaire) &&
      isSignupQuestion(nextId, questionnaire)
    ) {
      nextId = getLastSignupView(questionnaire).id;
    }

    const url = getQuestionnaireUrl(t, getVersion(questionnaire), nextId);
    navigate(url);
  };

  // executes smooth scroll
  const handleScroll = () => {
    if (scrollRef.current) {
      // use native (yet polyfilled)
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }

    // toggle internal state
    toggleScrolled(true);
  };

  const handleChange = (
    index: number,
    validator: ValidatorMethod,
    content: ContentType
  ) => {
    // when ever the content changes, rerun the validation
    const newValidations = [...validations];
    newValidations[index] = validator(state, t, content);
    toggleValidations(newValidations);

    // scroll to end
    if (!scrolled && scroll === "onPass" && areAllPass(newValidations)) {
      handleScroll();
    }
  };

  const handleSubmit = () => {
    // if validation status is "fail" exit block
    if (hasFail) {
      return;
    }

    // trigger navigation using first option
    const option = options[0];
    handleNavigation(option);
  };

  // build tags
  const tags = composeTags(questionnaire, state, config, products);

  // render all content
  const renderedContent = (
    <Fragment>
      {contents.map((content, index) => {
        // fetch content object by index
        const schema = buildSchema(content);
        const { type, requirements } = content;

        // check if the content has any dependencies
        if (
          !checkRequirements({ questionnaire, state, requirements, config })
        ) {
          return null;
        }

        // return desired content component
        return (
          <schema.Component
            key={`${index}-${type}`}
            tags={tags}
            initial={initial}
            content={content}
            onChange={() => handleChange(index, schema.validator, content)}
            onSubmit={() => handleSubmit()}
            handleNavigation={handleNavigation}
            handleScroll={handleScroll}
          />
        );
      })}
    </Fragment>
  );

  // create buttons
  const actions = composeActions(
    options,
    state,
    config,
    t,
    questionnaire,
    question,
    hasFail,
    handleNavigation
  );

  return (
    <Fragment>
      <ContentContainer>{renderedContent}</ContentContainer>
      {!hasBlock && (
        <ActionContainer
          extraId={question.id.toString()}
          actions={hasBlock ? [] : actions}
        />
      )}
      <Footnote />
      {QUICKMODE && <QuickMode />}
      {oldOSWarning && (
        <OldOSWarning onClose={() => toggleOldOSWarning(false)} />
      )}
      <div ref={scrollRef} />
    </Fragment>
  );
};

export default QuestionnaireContent;

export const composeTags = (
  questionnaire: QuestionnaireModel,
  state: StateModel,
  config: ConfigModel,
  products?: ProductModel[]
) => {
  const tags: TagMap = {
    ...getUserTags(state),
    ...getSupportTags(),
    ...getStudyScheduleTags(questionnaire, state),
    ...getMonthTags(),
    ...getDayTags(state),
    ...getInitialLevelTags(questionnaire, state),
    ...getTargetLevelTags(questionnaire, state),
    ...getOnetimeFeeTags(config, products),
    ...getSubscriptionFeeTags(questionnaire, config, state, products),
  };
  return tags;
};

export const composeActions = (
  options: OptionModel[],
  state: StateModel,
  config: ConfigModel,
  t: TranslateFn,
  questionnaire: QuestionnaireModel,
  question: QuestionModel,
  hasFail: boolean,
  handleNavigation: (option: OptionModel, scroll?: boolean) => Promise<void>
) => {
  const defaultButtonStyle = options.length === 1 ? "primary" : "default";
  const actions: ActionType[] = options
    // to button to show up, it must meet requirements
    .filter((option) => {
      const { requirements } = option;

      // check if the content has any dependencies
      if (!checkRequirements({ questionnaire, state, requirements, config })) {
        return false;
      }

      // must be ok
      return true;
    })
    // convert the rest to ButtonProps
    .map((option) => {
      const targetId = parseNextQuestion(
        questionnaire,
        question,
        option,
        state
      );
      const href = getQuestionnaireUrl(t, getVersion(questionnaire), targetId);
      const action: ActionType = {
        type: "button",
        button: {
          text: option.description || "",
          disabled: hasFail,
          href,
          type: option.buttonStyle || defaultButtonStyle,
          onClick: () => handleNavigation(option, true),
        },
      };
      return action;
    });
  return actions;
};
