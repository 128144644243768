import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";

const shared = () =>
  css`
    z-index: 99;
    position: fixed;
    bottom: 0;
    background-color: ${ColorCode.CTA_RED};
    color: ${ColorCode.WHITE};
    display: flex;
    font-size: 0.825rem;
    padding: 1rem;
  `;

export const Previous = styled.div`
  ${shared()};
  left: 0;
`;

export const Next = styled.div`
  ${shared()};
  right: 0;
`;
