import { ViewType } from "models/view";
import styled, { css } from "styled-components";
import devices from "theme/devices";

import { alpha, ColorCode } from "theme/colors";
import { archivo, light } from "theme/fonts";
import { ThemeProps } from "models/theme";
import Description from "components/description";

interface WrapperProps {
  $hidden: boolean;
}

export const StyledWrapper = styled.div<WrapperProps>`
  ${(props) =>
    props.$hidden &&
    css`
      display: none;
    `}
`;

interface FieldProps extends ThemeProps {
  $error: boolean;
}

export const StyledInput = styled.input<FieldProps>`
  width: 100%;
  display: block;
  border-radius: 0.3rem;
  font-family: inherit;
  font-size: 1em;
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  appearance: none;
  border: 1px solid ${ColorCode.HEX_ced4da};
  color: ${ColorCode.HEX_212529};
  margin-bottom: 1.25rem;
  ${light()};

  :focus {
    color: ${ColorCode.HEX_212529};
    background-color: ${ColorCode.WHITE};
    border-color: ${ColorCode.HEX_80bdff};
    outline: 0;
    box-shadow: 0 0 0 0.25rem ${alpha(ColorCode.BLUE, 0.25)};
  }

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      ${light()};
      &::placeholder {
        color: ${ColorCode.GRAY800};
      }
    `}

  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      ${archivo()};
    `}

  @media ${devices.tablet} {
    font-size: 1.25em;
  }

  @media ${devices.desktop} {
    font-size: 1.25em;
    max-width: 100%;
  }

  ${(props) =>
    props.$error &&
    css`
      background-color: ${ColorCode.PINK} !important;
      border-color: ${ColorCode.SALMON};

      ${props.$theme === "MasterEnglish" &&
      css`
        &::placeholder {
          color: ${ColorCode.CTA_RED};
        }
      `}
    `}
`;

export const StyledTextarea = styled.textarea<FieldProps>`
  width: 100%;
  display: block;
  border-radius: 0.3rem;
  font-family: inherit;
  font-size: 1em;
  min-height: 8rem;
  padding: 0.5rem 1rem;
  appearance: none;
  border: 1px solid ${ColorCode.HEX_ced4da};
  color: ${ColorCode.HEX_212529};
  margin-bottom: 0.5rem;

  :focus {
    color: ${ColorCode.HEX_212529};
    background-color: ${ColorCode.WHITE};
    border-color: ${ColorCode.HEX_80bdff};
    outline: 0;
    box-shadow: 0 0 0 0.25rem ${alpha(ColorCode.BLUE, 0.25)};
  }

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      ${light()};
      &::placeholder {
        color: ${ColorCode.GRAY800};
      }
    `}

  @media ${devices.tablet} {
    font-size: 1.25em;
  }

  @media ${devices.desktop} {
    font-size: 1.25em;
    max-width: 100%;
  }

  ${(props) =>
    props.$error &&
    css`
      background-color: ${ColorCode.PINK} !important;
      border-color: ${ColorCode.SALMON};

      ${props.$theme === "MasterEnglish" &&
      css`
        &::placeholder {
          color: ${ColorCode.CTA_RED};
        }
      `}
    `}
`;

export const StyledHint = styled(Description)`
  font-size: 0.75em;
  display: block;

  @media ${devices.tablet} {
    font-size: 0.9em;
  }

  @media ${devices.desktop} {
    font-size: 1.125em;
  }
`;

interface ErrorProps {
  $valid: boolean;
}

export const StyledError = styled.span<ErrorProps>`
  color: ${ColorCode.RED};
  font-size: 0.75em;
  display: block;
  margin-bottom: 0.25rem;

  @media ${devices.tablet} {
    font-size: 0.9em;
    margin-bottom: 0.5rem;
  }

  @media ${devices.desktop} {
    font-size: 1.125em;
  }

  opacity: ${(props) => (props.$valid ? 0 : 1)};
`;

export const StyledUniqueError = styled.span`
  color: ${ColorCode.RED};
  font-size: 0.75em;
  display: block;

  @media ${devices.tablet} {
    font-size: 0.9em;
  }

  @media ${devices.desktop} {
    font-size: 1.125em;
  }
`;

interface LabelProps {
  $view: ViewType;
}

export const StyledLabel = styled.label<LabelProps>`
  font-size: 0.85em;
  margin-bottom: 0.25rem;
  display: block;
  color: ${(props) =>
    props.$view === "light" ? ColorCode.BLACK : ColorCode.HEX_8C91A4};
`;
