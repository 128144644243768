import { getMediaUrl } from "lib/media";
import { FunctionComponent } from "react";
import { createPortal } from "react-dom";
import {
  StyledExitIcon,
  StyledTopRow,
  StyledPopup,
  StyledPopupContent,
  StyledPopupShim,
  StyledPopupScrollContainer,
  StyledPopupTitle,
  StyledFade,
  StyledButton,
  StyledBottomRow,
} from "./popup.styles";

interface PopupProps {
  title: string;
  button?: string;
  onClose: () => void;
  children?: any;
}

const Popup: FunctionComponent<PopupProps> = ({
  children,
  title,
  button = "Entendido",
  onClose,
}) => {
  const url = getMediaUrl("icons/IconButtonGrayExit", {
    extension: "png",
    size: "1x",
  });

  const render = () => {
    return (
      <StyledPopup>
        <StyledPopupShim />
        <StyledPopupScrollContainer>
          <StyledTopRow>
            <StyledExitIcon src={url} onClick={onClose} />
          </StyledTopRow>
          <StyledPopupContent>
            <StyledPopupTitle>{title}</StyledPopupTitle>
            {children}
            <StyledButton
              href=""
              type="primary"
              text={button}
              onClick={onClose}
            />
          </StyledPopupContent>
          <StyledBottomRow />
          <StyledFade />
        </StyledPopupScrollContainer>
      </StyledPopup>
    );
  };

  return createPortal(render(), document.body) as any;
};

export default Popup;
