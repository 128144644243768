import Button from "components/button";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import View from "components/layout/view";
import Title from "components/title";
import { useTranslations } from "hooks/translations";
import ContentContainer from "components/layout/content";
import Tiles from "components/tiles";
import { getStoreUrl } from "lib/store";
import Navbar from "components/navigation/navbar";
import List, { ListItemProps } from "components/list";
import { replaceTags, highlightEmail, breakAll } from "lib/string";
import { useQuestionnaireState } from "hooks/state";
import { sendVirtualQuestionnaireQuestionShownEvent } from "services/event";
import { StaticViewIdEnum } from "models/question";
import { useConfig } from "hooks/config";
import { useQuestionnaire } from "hooks/questionnaire";
import StartHere from "components/questionnaire/start-here";
import { getViewType } from "../utils";

const QuestionnaireStartLevelTestView: FunctionComponent = () => {
  const t = useTranslations();
  const questionnaire = useQuestionnaire();
  const [email, toggleEmail] = useState("");
  const state = useQuestionnaireState();
  const config = useConfig();
  const [url, toggleUrl] = useState("");

  useEffect(() => {
    if (state) {
      const { user } = state;
      toggleEmail(user.email);
    }

    toggleUrl(getStoreUrl());

    // send fixed event
    sendVirtualQuestionnaireQuestionShownEvent({
      uuid: config.uuid,
      questionId: StaticViewIdEnum.START_LEVEL_TEST,
    });
  });

  const handleClick = () => {
    window.location.assign(url);
  };
  const view = getViewType(questionnaire);

  // paragraph texts
  const paragraph1 = t("thank_you_p2");
  const paragraph2 = t("thank_you_p3");
  const paragraph3 = replaceTags(t("follow_steps_p3"), {
    EMAIL: highlightEmail(view, breakAll(email)), // this is an override to normal EMAIL
  });
  const paragraph4 = t("follow_steps_p4");

  // compile bullet nro 3
  const items: ListItemProps[] = [
    { number: 1, text: paragraph1 },
    { number: 2, text: paragraph2 },
    { number: 3, text: paragraph3 },
    { number: 4, text: paragraph4 },
  ];

  return (
    <Fragment>
      <View
        noindex
        view={view}
        title={t("follow_steps_title")}
        description={t("meta_description_thank_you_page")}
        cover
      >
        <Tiles />
        <Navbar />
        <ContentContainer>
          <Title>{t("follow_steps_title")}</Title>
          <List type="number" items={items} />
          <Button
            text={t("thank_you_button")}
            onClick={handleClick}
            type="primary"
            href={url}
          ></Button>
          <StartHere />
        </ContentContainer>
      </View>
    </Fragment>
  );
};

export default QuestionnaireStartLevelTestView;
