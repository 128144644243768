import Description from "components/description";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Title from "components/title";
import { useTranslations } from "hooks/translations";
import { useNavigate } from "react-router-dom";

const LoginExpiredView = () => {
  const navigate = useNavigate();
  const t = useTranslations();

  const handleSubmit = async () => {
    navigate(t("/login"));
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("login_link_expired_button"),
        type: "primary",
        onClick: handleSubmit,
        href: t("/login"),
      },
    },
  ];

  return (
    <View
      title={t("login_link_expired_title")}
      description={t("login_link_expired_body")}
      footer
      showLogoInFooter
      cover
    >
      <Navbar />
      <ContentContainer>
        <Title>{t("login_link_expired_title")}</Title>
        <Description>{t("login_link_expired_body")}</Description>
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default LoginExpiredView;
