import { ThemeProps } from "models/theme";
import { ViewType } from "models/view";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";
import { light, semibold } from "theme/fonts";

interface TableCellProps extends ThemeProps {
  $view: ViewType;
}

export const StyledTableCell = styled.td<TableCellProps>`
  padding: 1rem;
  color: ${(props) =>
    props.$view === "dark" ? ColorCode.WHITE : ColorCode.BLACK};
  ${semibold()};

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      ${light()};

      &:first-child {
        padding-left: 2rem;
      }
      &:last-child {
        padding-right: 2rem;
      }
    `}
  font-size: 0.75em;

  @media ${devices.tablet} {
    font-size: 1em;
    padding: 1rem;
  }
`;

interface Props {
  $align: "left" | "center" | "right";
}

export const StyledTableCellContent = styled.div<Props>`
  display: flex;
  align-items: center;
  font-size: 12px;

  ${(props) =>
    props.$align === "left" &&
    css`
      justify-content: left;
      text-align: left;
    `}

  ${(props) =>
    props.$align === "center" &&
    css`
      justify-content: center;
      text-align: center;
    `}

    ${(props) =>
    props.$align === "right" &&
    css`
      justify-content: right;
      text-align: right;
    `}
`;
