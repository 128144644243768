import { FunctionComponent, useEffect } from "react";
import Popup from "./layout/popup";
import ViewProvider from "providers/view";
import { ContentType } from "models/content";
import { QuestionnairePopupShownEvent } from "services/event";
import { useConfig } from "hooks/config";
import { useQuestionnaire } from "hooks/questionnaire";
import { buildSchema } from "views/questionnaire/question/content/base/factory";

interface OldOSWarningProps {
  onClose: () => void;
}

const OldOSWarning: FunctionComponent<OldOSWarningProps> = ({ onClose }) => {
  const config = useConfig();
  const questionnaire = useQuestionnaire();

  const contents: ContentType[] = [
    {
      type: "description",
      text: "La aplicación Master English admite los siguientes sistemas operativos:",
    },
    {
      type: "description",
      text: "iOS: versión 14.0 o posterior\nAndroid: versión 11.0 o posterior",
    },
    {
      type: "description",
      text: "Por favor, actualice su sistema operativo antes de continuar.",
    },
  ];

  useEffect(() => {
    QuestionnairePopupShownEvent({
      uuid: config.uuid,
      questionnaire,
      popupId: "OldOSPopupOpened",
    });
  }, []);

  return (
    <Popup
      title="Tu sistema operativo no es compatible"
      button="Aceptar"
      onClose={() => onClose()}
    >
      <ViewProvider type="light">
        {contents.map((c, index) => {
          const schema = buildSchema(c);
          const { Component } = schema;
          return (
            <Component
              key={index}
              content={c}
              tags={{}}
              initial={false}
              onChange={() => {}}
              onSubmit={() => {}}
              handleNavigation={async () => {}}
              handleScroll={() => {}}
            />
          );
        })}
      </ViewProvider>
    </Popup>
  );
};

export default OldOSWarning;
