import { Fragment, FunctionComponent, useEffect } from "react";
import View from "components/layout/view";
import Title from "components/title";
import Description from "components/description";
import { useTranslations } from "hooks/translations";
import ContentContainer from "components/layout/content";
import Navbar from "components/navigation/navbar";
import ActionContainer, { ActionType } from "components/layout/actions";
import { sendVirtualQuestionnaireQuestionShownEvent } from "services/event";
import { useConfig } from "hooks/config";
import { StaticViewIdEnum, StaticViewPathEnum } from "models/question";
import { getRaffleUrl, getStaticUrl, getVersion } from "lib/questionnaire";
import { useQuestionnaire } from "hooks/questionnaire";
import { useNavigate } from "react-router-dom";

const QuestionnaireNewConfirmView: FunctionComponent = () => {
  const t = useTranslations();
  const questionnaire = useQuestionnaire();
  const config = useConfig();
  const navigate = useNavigate();

  const href = getStaticUrl(
    t,
    getVersion(questionnaire),
    StaticViewPathEnum.REACTIVATE_OR_NEW
  );
  const raffleHref = getRaffleUrl(t);

  useEffect(() => {
    // send fixed event
    sendVirtualQuestionnaireQuestionShownEvent({
      uuid: config.uuid,
      questionId: StaticViewIdEnum.NEW_CONFIRM,
    });
  }, []);

  const handleYes = () => {
    navigate(raffleHref);
  };

  const handleNo = () => {
    navigate(href);
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: "Sí, quiero reiniciar mi progreso y comenzar un nuevo programa",
        type: "default",
        onClick: handleYes,
        href: raffleHref,
      },
    },
    {
      type: "button",
      button: {
        text: "No, no quiero reiniciar mi progreso",
        type: "default",
        onClick: handleNo,
        href: href,
      },
    },
  ];

  return (
    <Fragment>
      <View
        noindex
        title="Confirmación"
        description={t("congratulations_text")}
      >
        <Navbar />
        <ContentContainer>
          <Title>Confirmación</Title>
          <Description>
            Estás a punto de reiniciar tu progreso acumulado en Master English
            para comenzar un nuevo programa. ¿Es esta tu intención?
          </Description>
        </ContentContainer>
        <ActionContainer actions={actions} />
      </View>
    </Fragment>
  );
};

export default QuestionnaireNewConfirmView;
