import styled from "styled-components";
import Description from "components/description";
import devices from "theme/devices";

import Container from "components/layout/container";

interface Props {}

export const StyledContainer = styled(Container)<Props>`
  flex-shrink: 0;
  z-index: 60;
  padding-top: 1rem;
  padding-bottom: 4rem;

  @media ${devices.desktop} {
    max-width: 720px;
    align-items: center;
  }
`;

export const StyledText = styled(Description)`
  font-size: 0.825em;
  z-index: 50;

  @media ${devices.tablet} {
    font-size: 1em;
  }

  @media ${devices.desktop} {
  }
`;
