import { FunctionComponent, useEffect } from "react";
import { useConfig } from "hooks/config";
import { clear, clearUserSelectionsFromState } from "lib/storage";
import {
  TOKEN,
  REFRESH_TOKEN,
  SIGNUP_EMAIL_KEY,
  SIGNUP_NAME_KEY,
} from "models/user";
import {
  ControlQuestionnaireVersion,
  QuestionnaireVersion,
} from "models/questionnaire";
import { useTranslations } from "hooks/translations";
import { getQuestionnaireUrl } from "lib/questionnaire";
import { loadCleanQuestionnaire } from "services/questionnaire";
import { useNavigate } from "react-router-dom";

const QuestionnaireRaffleView: FunctionComponent = () => {
  const config = useConfig();
  const { uuid, questionnaireVersion = ControlQuestionnaireVersion } = config;
  const t = useTranslations();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVersion = async () => {
      // clear some shit
      clearUserSelectionsFromState();

      // additional clearings
      clear(TOKEN);
      clear(REFRESH_TOKEN);
      clear(SIGNUP_EMAIL_KEY);
      clear(SIGNUP_NAME_KEY);

      // special handling for some variants
      if (questionnaireVersion === QuestionnaireVersion.AI) {
        navigate("https://www.masterenglish.ai");
        return;
      } else if (questionnaireVersion === QuestionnaireVersion.AIMX) {
        navigate("https://www.masterenglish.ai/mx");
        return;
      }

      // fetch questionnaire so we know initialQuestion
      const questionnaire = await loadCleanQuestionnaire(
        uuid,
        questionnaireVersion,
        false
      );

      // make a redirect
      const url = getQuestionnaireUrl(
        t,
        questionnaire.id,
        questionnaire.initialQuestion
      );
      navigate(url);
    };

    fetchVersion();
  }, []);

  // show just empty view
  return null;
};

export default QuestionnaireRaffleView;
