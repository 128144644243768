import { FunctionComponent } from "react";

const __html = `
/* open-sans-light - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(''),
       url('/fonts/open-sans-v34-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/open-sans-v34-latin-300.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-light-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local(''),
       url('/fonts/open-sans-v34-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/open-sans-v34-latin-300italic.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('/fonts/open-sans-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/open-sans-v27-latin-regular.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
       url('/fonts/open-sans-v27-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/open-sans-v27-latin-600.woff') format('woff'); /* Modern Browsers */
}
/* archivo-400 - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('/fonts/archivo-v19-latin.woff2') format('woff2'), /* Super Modern Browsers */
}
/* archivo-600 - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
       url('/fonts/archivo-v19-latin.woff2') format('woff2'), /* Super Modern Browsers */
}
}
`;

const GoogleFonts: FunctionComponent = () => {
  return <style type="text/css" dangerouslySetInnerHTML={{ __html }} />;
};

export default GoogleFonts;
