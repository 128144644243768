import Description from "components/description";
import View from "components/layout/view";
import { FunctionComponent } from "react";
import Section from "components/layout/section";
import Block from "components/layout/block";
import Title from "components/title";
import Navbar from "components/navigation/navbar";
import SectionWrapper from "components/layout/section-wrapper";
import Background from "components/layout/background";
import List from "components/list";
import { getStoreUrl } from "lib/store";

const HandleYourSubscriptionView: FunctionComponent = () => {
  return (
    <View
      title="Gestiona tu suscripción"
      description="Gestiona tu suscripción al programa Master English en esta página."
      footer
      showLogoInFooter
      view="light"
    >
      <Navbar links />
      <SectionWrapper gray>
        <Section>
          <Block order={2}>
            <Background
              mobileImageUrl="home/WomanSunglassesHandleSubBg"
              tabletImageUrl="home/WomanSunglassesTabletBg"
              desktopImageUrl="home/WomanSunglassesHandleSubBg"
              align="right"
            />
          </Block>
          <Block order={1}>
            <Title>Gestiona tu suscripción</Title>
            <Description>
              Empieza una suscripción para acceder a tu programa Master English.
              Una vez iniciada tu suscripción solo tendrás que descargar nuestra
              app Master English en tu dispositivo móvil iPhone o Android e
              iniciar sesión.
            </Description>
            <Description>
              Si quieres lograr un nivel avanzado de inglés con Master English
              pero todavía no tienes tu programa,{" "}
              <a href="/habla-ingles">empieza aquí</a>.
            </Description>
            <Title mode="h2">Cómo cancelar tu suscripción</Title>
            <Description>
              Tu suscripción se renueva automáticamente hasta que la canceles.
            </Description>
            <Description>
              Puedes cancelar tu suscripción en cualquier momento siguiendo
              estos pasos:
            </Description>
            <List
              type="number"
              items={[
                {
                  number: 1,
                  text: `Abre la <a href="${getStoreUrl()}">app Master English</a> y ve al menú de la izquierda`,
                },
                {
                  number: 2,
                  text: 'Selecciona <span style="font-weight: 600; color: #050505;">Configuración</span>',
                },
                {
                  number: 3,
                  text: 'Ve a <span style="font-weight: 600; color: #050505;">Administrar suscripción</span>',
                },
              ]}
            />
            <Description>
              Si iniciaste tu suscripción desde nuestro{" "}
              <strong>sitio web:</strong>
            </Description>
            <List
              type="normal"
              items={[
                {
                  text: 'Haz clic en el botón <span style="font-weight: 600; color: #050505;">Administrar suscripción</span>',
                },
                {
                  text: 'Serás dirigido a nuestro sitio web donde podrás <span style="font-weight: 600; color: #050505;">cancelar</span> tu suscripción',
                },
              ]}
            />
            <Description>
              Si iniciaste tu suscripción dentro de la{" "}
              <strong>app Master English:</strong>
            </Description>
            <List
              type="normal"
              items={[
                {
                  text: 'Serás dirigido al menú <span style="font-weight: 600; color: #050505;">Suscripciones</span> de tu <span style="font-weight: 600; color: #050505;">Apple App Store</span> o <span style="font-weight: 600; color: #050505;">Google Play Store</span> desde donde podrás cancelar tu suscripción',
                },
              ]}
            />
            <Description>
              Si no estás satisfecho con tu compra, contáctanos a
              <a href="mailto:soporte@masterenglish.com">
                soporte@masterenglish.com
              </a>{" "}
              dentro de los 30 días posteriores a tu cargo de suscripción y te
              haremos un reembolso.
            </Description>
            <Title mode="h2">¿Necesitas ayuda?</Title>
            <Description>
              Si tienes dudas o necesitas ayuda para cancelar tu suscripción,
              envíanos un correo a{" "}
              <a href="mailto:soporte@masterenglish.com">
                soporte@masterenglish.com
              </a>{" "}
              y estaremos encantados de ayudarte. Responderemos a tu mensaje en
              un plazo de 2 días.
            </Description>
          </Block>
        </Section>
      </SectionWrapper>
    </View>
  );
};

export default HandleYourSubscriptionView;
