import styled, { CSSObject } from "styled-components";

export const StyledPicture = styled.picture``;

interface ImageProps {
  className?: string;
  $css?: CSSObject;
}

export const StyledImage = styled.img<ImageProps>`
  display: block;
  ${(props) => props.$css}
`;
