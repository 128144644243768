import { getVersion } from "lib/questionnaire";
import { QuestionnaireModel, QuestionnaireVersion } from "models/questionnaire";
import { ViewType } from "models/view";

export const getViewType = (questionnaire: QuestionnaireModel): ViewType => {
  const version = getVersion(questionnaire);
  if (
    version === QuestionnaireVersion.V70 ||
    version === QuestionnaireVersion.V71 ||
    version === QuestionnaireVersion.V72
  ) {
    return "light";
  }
  return "dark";
};
