import { ViewType } from "models/view";
import styled from "styled-components";
import { ColorCode } from "theme/colors";

interface Props {
  $view: ViewType;
}

export const StyledTableRow = styled.tr<Props>`
  td {
    background-color: ${(props) =>
      props.$view === "dark" ? ColorCode.HEX_1B243C : ColorCode.MERCURY};
  }

  &:nth-child(odd) td {
    background-color: ${(props) =>
      props.$view === "dark" ? ColorCode.HEX_11192E : ColorCode.LIGHT};
  }
`;
