import { TranslateFn } from "models/locale";
import isEmail from "validator/lib/isEmail";
import isPhone from "validator/lib/isMobilePhone";

export type ValidatorFn = (value: string) => string | undefined;

// eslint-disable-next-line
export const phoneValidator = (t: TranslateFn) => {
  return (value: string) => {
    if (
      !isPhone(String(value).toLowerCase(), undefined, { strictMode: true })
    ) {
      return "Introduce un número válido con prefijo";
    }
    return undefined;
  };
};

export const requiredValidator = (t: TranslateFn) => {
  return (value: string) => {
    if (value.length === 0) {
      return t("This field is required");
    }
    return undefined;
  };
};

export const emailValidator = (t: TranslateFn) => {
  return (value: string) => {
    if (!isEmail(String(value).toLowerCase())) {
      return t("Enter a valid email address");
    }
    return undefined;
  };
};

export const passwordValidator = (t: TranslateFn) => {
  return (value: string) => {
    if (value.length < 8) {
      return t("At least 8 characters");
    }
    return undefined;
  };
};

export const runValidations = (
  value: string,
  validations: ValidatorFn[]
): string | undefined => {
  for (let i = 0; i < validations.length; i += 1) {
    const result = validations[i](value);
    if (result !== undefined) {
      return result;
    }
  }
  return undefined;
};
