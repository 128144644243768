import { ProductModel } from "models/product";
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useConfig } from "hooks/config";
import { useQuestionnaireState } from "hooks/state";
import { getMinutesPerWeek, getProgramPlan } from "lib/study-schedule";
import { useQuestionnaire } from "hooks/questionnaire";
import { getStudySchedule } from "lib/question";
import { ProductFeatureParams } from "services/feature";
import { fetchProducts } from "services/products";
import { ProgramPlan } from "models/plan";
import { getVersionWithV } from "lib/questionnaire";

interface ProductsContextInterface {
  products: ProductModel[] | undefined;
}

export const ProductsContext = createContext<
  ProductsContextInterface | undefined
>(undefined);

interface ProductsProviderProps {
  children?: ReactNode;

  // these can be used to override dynamic content
  plan?: ProgramPlan;
  minutesPerWeek?: number;
  reactivate?: boolean;
}

const ProductsProvider: FunctionComponent<ProductsProviderProps> = ({
  children,
  plan,
  minutesPerWeek,
  reactivate = false,
}) => {
  const config = useConfig();
  const questionnaire = useQuestionnaire();
  const [products, toggleProducts] = useState<ProductModel[] | undefined>(
    undefined
  );

  // some states to compare
  const [oldCouponCode, toggleCouponCode] = useState<string | undefined>(
    undefined
  );
  const [oldPlan, togglePlan] = useState<ProgramPlan | undefined>(undefined);
  const [oldMinutesPerWeek, toggleMinutesPerWeek] = useState<
    number | undefined
  >(undefined);
  const [oldReactivate, toggleReactivate] = useState<boolean | undefined>(
    undefined
  );

  // get study schedule, might be undefined
  const state = useQuestionnaireState();

  const updateProducts = (newProducts: ProductModel[]) => {
    // update the local state
    toggleProducts(newProducts);
    console.info(`Updated Products Context`);
  };

  // DEVNOTE: if you click around in the studySchedule view the data is fetched using wrong attributes (because answer is not stored yet)
  useEffect(() => {
    const update = async () => {
      console.info("ProductsProvider");

      // get program plan
      const newCouponCode = questionnaire.coupon;
      const newPlan = getProgramPlan(questionnaire, state);
      const newStudySchedule = getStudySchedule(questionnaire, state);
      const newMinutesPerWeek = newStudySchedule
        ? getMinutesPerWeek(newStudySchedule)
        : undefined;
      const newReactivate = reactivate;

      // if we have any studySchedule chosen and any of the attributes has changed
      const hasChanges =
        oldPlan !== newPlan ||
        oldMinutesPerWeek !== newMinutesPerWeek ||
        newReactivate !== oldReactivate ||
        newCouponCode !== oldCouponCode;
      if (hasChanges) {
        const parseMinutes = () => {
          if (minutesPerWeek) {
            return minutesPerWeek.toString();
          }
          return newMinutesPerWeek !== undefined
            ? newMinutesPerWeek.toString()
            : undefined;
        };

        // create params
        const params: ProductFeatureParams = {
          questionnaire: getVersionWithV(questionnaire),
          plan: plan || newPlan,
          minutesPerWeek: parseMinutes(),
          reactivate,
        };

        // persis states
        togglePlan(newPlan);
        toggleMinutesPerWeek(newMinutesPerWeek);
        toggleCouponCode(newCouponCode?.code);
        toggleReactivate(reactivate);

        // note: this will run whenever things in state change
        // fetch products that ARE CURRENTLY APPLICABLE TO THE USER BASED ON STATE
        const fetchedProducts = await fetchProducts(
          config,
          params,
          newCouponCode?.code
        );
        updateProducts(fetchedProducts);
      } else {
        console.info("No changes detected.");
      }
    };

    // toggle check
    update();

    // only update if any of these change
  }, [state.schedule]);

  return (
    <ProductsContext.Provider value={{ products }}>
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;
