import Image from "components/image";
import { useQuestion } from "hooks/question";
import { FunctionComponent, useEffect, useState } from "react";
import { ImageCSS, ImageCSSFlexible, parseHeaderUrl } from "./header.styles";
import {
  getFirstSignupView,
  getQuestionnaireUrl,
  getVersion,
} from "lib/questionnaire";
import { useQuestionnaire } from "hooks/questionnaire";
import { useTranslations } from "hooks/translations";
import { useNavigate } from "react-router-dom";

const Background: FunctionComponent = () => {
  const questionnaire = useQuestionnaire();
  const question = useQuestion();
  const t = useTranslations();
  const navigate = useNavigate();
  const { header } = question;
  const [clicks, toggleClicks] = useState(0);

  useEffect(() => {
    toggleClicks(0);
  }, [question.id]);

  if (header === undefined) {
    return null;
  }

  const urls = parseHeaderUrl(header);
  const { alt, flexible = false } = header;

  // this is magic shortcut to allow fast access to signup using shift+click
  const handleClick = (e: any) => {
    if (e.shiftKey) {
      // after fifth click take the user to signup
      if (clicks === 5) {
        const signup = getFirstSignupView(questionnaire);
        const url = getQuestionnaireUrl(
          t,
          getVersion(questionnaire),
          signup.id
        );
        navigate(url);
      } else {
        toggleClicks(clicks + 1);
      }
    }
  };

  return (
    <div onClick={handleClick}>
      <Image
        urls={urls}
        alt={alt}
        css={flexible ? ImageCSSFlexible : ImageCSS}
      />
    </div>
  );
};

export default Background;
