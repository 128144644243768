import { ConfigModel } from "models/config";

export const getPhonePrefix = (config: ConfigModel) => {
  switch (config.country) {
    case "US":
      return "+1";
    case "MX":
      return "+52";
    case "AR":
      return "+54";
    case "EC":
      return "+593";
    case "SV":
      return "+503";
    case "PA":
      return "+507";
    case "PR":
      return "+1";
    case "BO":
      return "+591";
    case "CL":
      return "+56";
    case "CO":
      return "+57";
    case "CR":
      return "+506";
    case "DO":
      return "+1";
    case "GT":
      return "+502";
    case "HN":
      return "+504";
    case "NI":
      return "+505";
    case "PY":
      return "+595";
    case "PE":
      return "+51";
    case "UY":
      return "+598";
    case "VE":
      return "+58";
    default:
      return "";
  }
};
