import { getMediaUrl } from "lib/media";
import { ChangeEvent, FunctionComponent } from "react";
import {
  StyledArrow,
  StyledArrowWrapper,
  StyledDropdown,
  StyledWrapper,
  StyledLabel,
  StyledOverlay,
  StyledSelect,
  StyledText,
  StyledTextWrapper,
} from "./dropdown.styles";

export interface DropDownItemModel {
  id: string | number;
  text: string;
}

interface DropdownProps {
  name: string;
  label: string;
  items: DropDownItemModel[];
  selected?: string | number;
  placeholder?: string;
  onChoose: (id: string | number) => void;
  numeric?: boolean;
}

const Dropdown: FunctionComponent<DropdownProps> = ({
  name,
  label,
  items,
  selected,
  placeholder = "?",
  onChoose,
  numeric = true,
}) => {
  let text = placeholder;
  if (selected !== undefined) {
    const item = items.find((i) => i.id === selected);
    if (item) {
      text = item.text;
    }
  }

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    onChoose(numeric ? parseInt(id) : id);
  };

  const arrowUrl = getMediaUrl("icons/DropDownArrow", {
    size: "1x",
    extension: "svg",
  });

  return (
    <StyledDropdown>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <StyledWrapper>
        <StyledSelect id={name} onChange={handleChange} value={selected}>
          <option value="" disabled>
            {placeholder}
          </option>
          {items.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.text}
              </option>
            );
          })}
        </StyledSelect>
        <StyledOverlay>
          <StyledTextWrapper selected={selected !== null}>
            <StyledText>{text}</StyledText>
          </StyledTextWrapper>
          <StyledArrowWrapper>
            <StyledArrow src={arrowUrl}></StyledArrow>
          </StyledArrowWrapper>
        </StyledOverlay>
      </StyledWrapper>
    </StyledDropdown>
  );
};

export default Dropdown;
