import { CouponModel } from "./coupon";
import { ProgramPlan } from "./plan";
import { ProductConfiguration } from "./product";
import { QuestionModel } from "./question";
import { ThemeType } from "./theme";

export const STATE_KEY = "state";

export enum QuestionnaireVersion {
  // v28 bundle
  V61 = "61",

  // monthly sub pricing
  V66 = "66",
  V67 = "67",
  V68 = "68",

  // email discount variant
  V69 = "69",

  // ultrashort questionnaire with Framer landing page
  V70 = "70",
  V71 = "71",
  V72 = "72",

  // tv campaign
  V73 = "73",
  V74 = "74",

  // special cases, these variants redirect user to masterenglish.ai domain
  AI = "ai",
  AIMX = "aimx",
}

export const QUESTIONNAIRE_KEY = "questionnaire";

export const VERSION_KEY = "version";

export const TARGET_KEY = "target";

// NOTE: this controls the build process
export const ActiveQuestionnaireVersions = [
  QuestionnaireVersion.V61,
  QuestionnaireVersion.V66,
  QuestionnaireVersion.V67,
  QuestionnaireVersion.V68,
  QuestionnaireVersion.V69,
  QuestionnaireVersion.V70,
  QuestionnaireVersion.V71,
  QuestionnaireVersion.V72,
  QuestionnaireVersion.V73,
  QuestionnaireVersion.V74,
];

// list of hot jsons (won't be cached)
const hotJsons = import.meta.env.VITE_PUBLIC_HOT_JSON || "";
export const HotQuestionnaireVersions = hotJsons.split(
  ","
) as QuestionnaireVersion[];

export const isHot = (version: QuestionnaireVersion) => {
  return HotQuestionnaireVersions.includes(version);
};

export const ControlQuestionnaireVersion = QuestionnaireVersion.V66;
export const EmbeddedQuestionnaireVersion = QuestionnaireVersion.V73;
export const EmbeddedQuestionnaireQuestion = 2;
export const EmbeddedQuestionnaireFollowUpQuestion = 3;

interface ProgressModel {
  type?: "navbar" | "top"; // top means wide bar under the navbar
  start: number;
  end: number; // this one should be ThankYouView id
}

export type UserLevelAlgorithm = "LevelQuestion" | "InitialLevel";

export interface QuestionnaireModel {
  id: QuestionnaireVersion;
  initialQuestion: number;
  questions: QuestionModel[];

  // optional, in some cases
  userLevelAlgorithm?: UserLevelAlgorithm;
  userLevelMappings?: UserLevelMapping[];

  // progress bar definition
  progress: ProgressModel;

  // to force set theme
  theme?: ThemeType;

  // to customise product configurations
  productConfigurations?: ProductConfiguration[];

  // new since v23
  lastLevelQuestionFollowUpQuestion?: number;
  levelQuestionRules?: LevelQuestionRule[];

  // used to overwrite study schedule selection
  programPlan?: ProgramPlan;

  // if we want to use paddle coupons
  coupon?: CouponModel;
}

// the values are minimums
export interface LevelQuestionRule {
  no?: number;
  maybe?: number;
  followUpQuestion: number;
}

export type UserLevelType =
  | "beginner"
  | "elementary"
  | "basic"
  | "intermediate"
  | "upperintermediate"
  | "advanced";

interface UserLevelMapping {
  userLevel: UserLevelType;
  first?: number;
  last?: number;

  /** @deprecated in V57 */
  lastNoAfter?: number; // optional because API might omit zero
  /** @deprecated in V57 */
  lastNoBefore?: number;
}
