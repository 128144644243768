import { useTheme } from "hooks/theme";
import { useView } from "hooks/view";
import { FunctionComponent, ReactNode } from "react";
import {
  StyledTableHeader,
  StyledTableHeaderContent,
} from "./table-header.styles";

interface TableHeaderProps {
  className?: string;
  width?: number;
  align?: "left" | "center" | "right";
  small?: boolean;
  children?: ReactNode;
}

const TableHeader: FunctionComponent<TableHeaderProps> = ({
  children,
  className,
  width,
  align = "center",
  small = false,
}) => {
  const theme = useTheme();
  const view = useView();
  return (
    <StyledTableHeader
      $theme={theme}
      className={className}
      $view={view}
      $width={width}
    >
      <StyledTableHeaderContent $align={align} $small={small}>
        {children}
      </StyledTableHeaderContent>
    </StyledTableHeader>
  );
};

export default TableHeader;
