import styled, { css, keyframes } from "styled-components";

const nudgeKeyframes = keyframes`
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
`;

interface Props {
  $nudge: boolean;
}

export const StyledNudge = styled.div<Props>`
  ${(props) =>
    props.$nudge &&
    css`
      animation: ${nudgeKeyframes} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97)
        both;
    `};
`;
