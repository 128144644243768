import { getMediaUrl } from "lib/media";
import { Fragment, FunctionComponent } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import { ScheduleSummaryContentType } from "models/content/schedule-summary";
import {
  StyledIcon,
  StyledItem,
  StyledLabel,
  StyledScheduleSummary,
  StyledText,
} from "./schedule-summary.styles";
import { replaceTags } from "lib/string";

interface ScheduleSummaryContentProps extends ContentProps {
  content: ScheduleSummaryContentType;
}

const ScheduleSummaryContent: FunctionComponent<
  ScheduleSummaryContentProps
> = ({ tags }) => {
  const monthsIcon = getMediaUrl("icons/CalendarIcon", {
    size: "2x",
    extension: "webp",
  });
  const monthsLabel = replaceTags("#MONTHS# meses", tags);

  const sessionsIcon = getMediaUrl("icons/SunIcon", {
    size: "2x",
    extension: "webp",
  });
  const sessionsLabel = replaceTags(
    "#PRACTICE_DAYS_PER_WEEK# sesiones / semana",
    tags
  );

  const minutesIcon = getMediaUrl("icons/ClockIcon", {
    size: "2x",
    extension: "webp",
  });
  const minutesLabel = replaceTags("#MINUTES_PER_SESSION# min", tags);
  return (
    <Fragment>
      <StyledScheduleSummary>
        <StyledText>Completarás tu programa en:</StyledText>
        <StyledItem>
          <StyledIcon src={monthsIcon} />
          <StyledLabel>{monthsLabel}</StyledLabel>
        </StyledItem>
        <StyledItem>
          <StyledIcon src={sessionsIcon} />
          <StyledLabel>{sessionsLabel}</StyledLabel>
        </StyledItem>
        <StyledItem>
          <StyledIcon src={minutesIcon} />
          <StyledLabel>{minutesLabel}</StyledLabel>
        </StyledItem>
      </StyledScheduleSummary>
      <div />
    </Fragment>
  );
};

export default ScheduleSummaryContent;

export const testimonialContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: ScheduleSummaryContent,
  validator: testimonialContentValidator,
};
