import styled from "styled-components";
import devices from "theme/devices";

import Container from "./container";

interface ContentProps {}

export const StyledContent = styled(Container)<ContentProps>`
  flex: 0;

  & > :last-child {
    margin-bottom: 0rem !important;
  }

  padding-bottom: 1.25rem;

  @media ${devices.mobile} {
    padding-bottom: 2.25rem;
  }

  @media ${devices.tablet} {
    margin-bottom: 2rem;
  }

  @media ${devices.desktop} {
    flex: 0;
    max-width: 720px;
    margin-top: 3rem;
  }
`;
