import { useTheme } from "hooks/theme";
import { FunctionComponent, ReactNode } from "react";
import { StyledContainer } from "./container.styles";

interface ContainerProps {
  children: ReactNode;
  buttons?: boolean;
  className?: string;
}

const Container: FunctionComponent<ContainerProps> = ({
  children,
  buttons = false,
  className,
}) => {
  const theme = useTheme();
  return (
    <StyledContainer className={className} $buttons={buttons} $theme={theme}>
      {children}
    </StyledContainer>
  );
};

export default Container;
