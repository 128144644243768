import { FunctionComponent } from "react";
import TermsOfUseES from "./content//es";
import View from "components/layout/view";
import { useTranslations } from "hooks/translations";
import Navbar from "components/navigation/navbar";
import Fade from "components/fade";
import { StyledContentContainer } from "../view.styles";

const TermsOfUseView: FunctionComponent = () => {
  const t = useTranslations();

  return (
    <View
      view="dark"
      title={t("Terms of use")}
      description={t("meta_description_terms_page")}
      footer
      showLogoInFooter
    >
      <Navbar links />
      <Fade />
      <StyledContentContainer>
        <TermsOfUseES />
      </StyledContentContainer>
    </View>
  );
};

export default TermsOfUseView;
