import { Fragment, FunctionComponent } from "react";

const PrivacyPolicyES: FunctionComponent = () => {
  return (
    <Fragment>
      <h1>Política de Privacidad</h1>

      <ol>
        <li>
          <a href="#s1">Nombre y dirección del encargado del registro</a>
        </li>
        <li>
          <a href="#s2">Información de contacto en asuntos de registro</a>
        </li>
        <li>
          <a href="#s3">Nombre de registro</a>
        </li>
        <li>
          <a href="#s4">¿Qué tipo de información se recopila?</a>
        </li>
        <li>
          <a href="#s5">¿Por qué se recopila mi información?</a>
        </li>
        <li>
          <a href="#s6">
            ¿Cuáles son los fundamentos para el procesamiento de la información?
          </a>
        </li>
        <li>
          <a href="#s7">¿De qué fuentes proviene la información?</a>
        </li>
        <li>
          <a href="#s8">¿Se compartirá la información personal con terceros?</a>
        </li>
        <li>
          <a href="#s9">
            Derechos relacionados con el tratamiento de la información personal
          </a>
        </li>
        <li>
          <a href="#s10">Almacenamiento de la información</a>
        </li>
        <li>
          <a href="#s11">Uso de cookies</a>
        </li>
        <li>
          <a href="#s12">Protección del registro</a>
        </li>
        <li>
          <a href="#s12">Para residentes de california</a>
        </li>
      </ol>

      <h2>Política de Privacidad</h2>

      <p>
        Su privacidad es importante para nosotros, por lo que hemos desarrollado
        una Política de Privacidad que cubre la forma en la que nosotros (más
        adelante &quot;Compañía&quot;) recopilamos, usamos, revelamos,
        transferimos y almacenamos su información personal (más adelante
        &quot;Usuario&quot;). Si tiene alguna pregunta sobre la forma en que se
        maneja su información personal, por favor, contáctenos en la siguiente
        dirección.
      </p>

      <h2 id="s1">1. Nombre y dirección del encargado del registro</h2>

      <p>
        Master English Ltd
        <br />
        Verstaankatu 1<br />
        33100 Tampere, Finlandia
      </p>

      <h2 id="s2">2. Información de contacto en asuntos de registro</h2>

      <p>
        <a href="mailto:soporte@masterenglish.com">soporte@masterenglish.com</a>
      </p>

      <h2 id="s3">3. Nombre de registro</h2>

      <p>Registro de clientes del servicio Master English.</p>

      <h2 id="s4">4. ¿Qué tipo de información se recopila?</h2>

      <p>
        <strong>Información enviada por el Usuario durante su registro:</strong>
      </p>

      <p>
        Para registrarse en el servicio Master English y utilizarlo, el Usuario
        debe proporcionar su nombre, dirección de correo electrónico y
        contraseña preferida para su identificación de usuario (dirección de
        correo electrónico).
      </p>

      <p>
        Si el Usuario se registra en el servicio a través de Facebook, la
        Compañía conocerá el nombre de usuario de Facebook del Usuario. Si el
        Usuario se registra en el servicio a través de Apple, la Compañía
        conocerá el ID de Apple del Usuario.
      </p>

      <p>
        Además, el Usuario tiene la opción de dar voluntariamente alguna
        información adicional durante el registro o la utilización. La posible
        información adicional se utiliza para poder proporcionar el servicio de
        atención al cliente, desarrollar el servicio, desarrollar la
        comercialización u optimizar el ejercicio.
      </p>

      <p>
        <strong>
          Información recopilada automáticamente durante el registro:
        </strong>
      </p>

      <p>
        Hora de registro
        <br />
        Canal de registro
        <br />
        Idioma de interfaz del Usuario
        <br />
        Tipo de usuario
        <br />
        Zona horaria
        <br />
        País
        <br />
        Identificador de Facebook
        <br />
        Identificador de Apple
        <br />
        Identificador de la publicidad
        <br />
        Versión original de la app y plataforma
        <br />
        Zona horaria deducida del país de la IP
        <br />
        Idioma de interfaz del Usuario
      </p>

      <p>
        <strong>
          Información generada automáticamente durante el uso del servicio:
        </strong>
      </p>

      <p>
        Identificador de Usuario personal
        <br />
        Marca horaria del registro
        <br />
        Eventos de compra
        <br />
        Evento de activación de prueba gratuita
        <br />
        Fecha final de la licencia
        <br />
        Datos de inicio de sesión (cookies &quot;Recuérdame&quot;)
        <br />
        Datos de la campaña de oferta
        <br />
        Datos analíticos (Google Analytics)
        <br />
        Resultados del aprendizaje
        <br />
        Logros del Usuario en la app móvil
      </p>

      <p>
        <strong>
          Información enviada o seleccionada por el Usuario durante el uso del
          servicio:
        </strong>
      </p>

      <p>
        Archivos de audio generados por el Usuario al hacer ejercicios de
        speaking
        <br />
        Respuestas incorrectas (no conectadas a usuarios individuales)
        <br />
        Feedback del material de estudio (opcional)
        <br />
        Suscripción o anulación de suscripción del Boletín de noticias
        <br />
        Ajustes de uso de servicio
      </p>

      <p>
        <strong>
          Otra información recopilada y manejada para los fines descritos en la
          sección 5
        </strong>
      </p>

      <p>
        Notas agregadas manualmente por el servicio de atención al cliente o por
        I+D
        <br />
        Información sobre qué correos electrónicos enviados por Master English
        abrió el Usuario
      </p>

      <h2 id="s5">5. ¿Por qué se recopila mi información?</h2>

      <p>
        La información especificada en la sección 4 se recopila y procesa con
        los siguientes fines:
      </p>

      <p>
        <strong>Uso del servicio</strong>
      </p>

      <p>
        La dirección de correo electrónico y la contraseña del Usuario son
        necesarias para el registro, para iniciar sesión en el servicio, así
        como para acceder al servicio si se pierde la contraseña. Durante los
        eventos de compra, la información relacionada con la compra se almacena
        para que podamos entregar al Usuario el servicio que ha comprado.
      </p>

      <p>
        El servicio Master English se basa en la optimización personal (creación
        de perfiles), que requiere el almacenamiento de información como los
        datos de ejercicio del Usuario, incluidas las respuestas seleccionadas,
        escritas y grabadas.
      </p>

      <p>
        El perfil que se forma como resultado de esta optimización consiste en
        la información recopilada del Usuario durante el registro y la
        utilización del servicio. Esta información es necesaria para la
        optimización individual del servicio.
      </p>

      <p>
        Gran parte del resto de la información especificada en la sección 4 es
        necesaria para utilizar las diferentes características del servicio. Los
        ajustes seleccionados por el Usuario se almacenan para ajustar el
        funcionamiento del servicio seg&uacute;n los deseos del Usuario.
      </p>

      <p>
        <strong>Investigación y desarrollo</strong>
      </p>

      <p>
        El desarrollo de las características y el algoritmo de ejercicio de
        Master English se basa en diferentes pruebas y análisis estadísticos de
        los datos de comportamiento del Usuario almacenados en la base de datos,
        así como en los resultados de aprendizaje del Usuario. Los datos
        utilizados en la investigación y el desarrollo no se pueden rastrar
        hasta un Usuario individual. El análisis se realiza estadísticamente
        utilizando una gran masa de datos, donde la información se almacena de
        forma anónima, y los Usuarios individuales ya no se pueden identificar.
      </p>

      <p>
        <strong>
          Servicio de atención al cliente e información a los Usuarios
        </strong>
      </p>

      <p>
        La información de contacto del Usuario, la información de pago y la
        información de los ejercicios también es necesaria para responder a las
        solicitudes de asistencia al cliente. Esas solicitudes suelen implicar
        el examen de la información del Usuario y de los datos de los ejercicios
        en el panel de gestión de Master English y la comprobación de la
        información de pago en los paneles de gestión de diversos proveedores de
        servicios de pago. Las direcciones de correo electrónico de los usuarios
        también son necesarias para fines informativos.
      </p>

      <p>
        <strong>Marketing</strong>
      </p>

      <p>
        Utilizamos píxeles de la plataforma de marketing, el envío de
        direcciones de correo electrónico en forma de hash y otras técnicas
        similares para recoger información sobre el movimiento y las acciones de
        los Usuarios en el servicio Master English, con el fin de ayudarnos a
        ofrecer publicidad dirigida y personalizada.
      </p>

      <p>
        <strong>Contabilidad</strong>
      </p>

      <p>
        La información personal del Usuario también se maneja para cumplir con
        las obligaciones legales de la Compañía, como la fiscalidad y la
        contabilidad. A efectos contables y fiscales, la Compañía debe saber,
        por ejemplo, qué productos han adquirido los Usuarios, cuánto cuestan
        los productos, qué proveedor de servicios de pago se ha utilizado y en
        qué país se han realizado las compras (la información sobre el país
        afecta, por ejemplo, al importe del Impuesto sobre el Valor Añadido). La
        información de contacto del Usuario es necesaria en caso de que surjan
        problemas o cuestiones de uso indebido relacionadas con los pagos.
      </p>

      <p>
        <strong>
          Cambios o adiciones a los fines del uso de la información
        </strong>
      </p>

      <p>
        En caso de que se produzcan cambios esenciales en los fines de uso de la
        información personal o se adopten nuevos fines, la Compañía informará al
        Usuario con antelación antes de llevar a cabo los cambios. En estos
        casos, la Compañía también debe pedir el consentimiento del Usuario para
        dicho uso esencialmente cambiado o nuevo.
      </p>

      <h2 id="s6">
        6. ¿Cuáles son los fundamentos para el procesamiento de la información?
      </h2>

      <p>
        Hay varios motivos diferentes para procesar la información personal,
        como por ejemplo:
      </p>

      <ul>
        <li>Consentimiento</li>
        <li>Ejecución de un contrato</li>
        <li>Cumplimiento de una obligación legal</li>
        <li>Motivos relacionados con la autoridad oficial</li>
        <li>Intereses legítimos</li>
      </ul>

      <p>
        Master English recopila y procesa información personal principalmente
        por motivos de consentimiento, ejecución de un contrato, motivos
        relacionados con el cumplimiento de las obligaciones legales de la
        Compañía e intereses legítimos.
      </p>

      <p>
        Antes de que el Usuario se registre en el servicio Master English y
        envíe información personal a la Compañía, se solicita al Usuario que lea
        atentamente el contenido de la Política de Privacidad relacionada con el
        uso de este servicio antes de consentir el procesamiento de la
        información personal del Usuario. La solicitud de consentimiento se
        presenta de manera clara y separada de otros asuntos, de forma
        comprensible y accesible, en un lenguaje claro y sencillo. Al mismo
        tiempo, se informa al Usuario de que tiene derecho a retirar su
        consentimiento en cualquier momento. En la presente política de
        privacidad se ofrece más información sobre la retirada del
        consentimiento. De acuerdo con el principio de libre consentimiento, el
        Usuario puede elegir los fines de uso de los datos personales con los
        que consiente.
      </p>

      <p>
        Los motivos relacionados con la ejecución de un contrato significan que
        hay que recabar cierta información del Usuario para que sea técnicamente
        posible utilizar y adquirir el servicio.
      </p>

      <p>
        El cumplimiento de una obligación legal significa el procesamiento de
        información personal, por ejemplo, a los efectos del cumplimiento de las
        obligaciones de la Compañía relacionadas con la contabilidad y la
        fiscalidad.
      </p>

      <p>
        La Compañía puede tener intereses legítimos relacionados con las
        siguientes funciones, entre otras: atención al cliente, manejo de casos
        de uso indebido, desarrollo de producto y servicio.
      </p>

      <p>
        La reunión y el procesamiento de toda la información enumerada en la
        sección 4 se basa en algunos de estos motivos. Si desea obtener una
        explicación más detallada de estos motivos, puede solicitarla por correo
        electrónico a&nbsp;
        <a href="mailto:soporte@masterenglish.com">soporte@masterenglish.com</a>
        .
      </p>

      <p>
        Si el Usuario no desea enviar información personal que se requiera por
        motivos de ejecución de contrato, por motivos relacionados con el
        cumplimiento de obligaciones legales de la empresa o por otros intereses
        legítimos de la Compañía, no se podrá utilizar el servicio.
      </p>

      <h2 id="s7">7. ¿De qué fuentes proviene la información?</h2>

      <p>
        La información personal se recopila principalmente de los propios
        Usuarios, ya sea directamente o a través de cookies (véase la sección
        11). La información también se genera automáticamente durante el
        registro, la compra y la utilización del servicio (consulte la sección
        4).
      </p>

      <p>
        De ser necesario, también se puede recopilar y actualizar información
        personal de otros registros de la empresa y de otras Compañías del mismo
        grupo empresarial o de registros de terceros (por ejemplo, el registro
        de información crediticia).
      </p>

      <h2 id="s8">8. ¿Se compartirá la información personal con terceros?</h2>

      <p>
        La información personal se compartirá con los proveedores de servicios
        de pago que cobran por los productos y servicios pedidos a Master
        English. Cierta información personal también se transmitirá a los
        siguientes proveedores de servicios:
      </p>

      <ul>
        <li>Zendesk (software de servicio al cliente)</li>
        <li>Mailchimp (plataforma de marketing)</li>
        <li>Braze (herramienta de automatización de compromiso para móvil)</li>
        <li>Split.io (plataforma de suministro de funciones)</li>
        <li>RevenueCat (plataforma de suscripción en aplicaciones)</li>
        <li>AppsFlyer (plataforma de atribución móvil)</li>
        <li>
          Meta Platforms (empresa matriz de Facebook, Instagram, Threads y
          WhatsApp)
        </li>
        <li>Google Firebase (plataforma de desarrollo móvil)</li>
        <li>Google Analytics (servicio de analítica)</li>
        <li>Apple App Store (plataforma de distribución)</li>
        <li>Helpware (servicio al cliente digital subcontratado)</li>
        <li>Google Ads (plataforma de publicidad digital)</li>
        <li>TikTok Ads (plataforma de publicidad digital)</li>
        <li>Pinterest Ads (plataforma de publicidad digital)</li>
      </ul>

      <p>
        Ciertos datos de compra y direcciones de correo electrónico en forma de
        hash también se transmiten a Meta Platforms, TikTok, Pinterest y/o a
        Google para controlar y mejorar la eficacia de nuestras campañas
        publicitarias.
      </p>

      <p>
        El sitio web masterenglish.com y la aplicación móvil Master English se
        encuentran en los fiables servidores internacionales AWS (Amazon Web
        Services) en Estados Unidos.
      </p>

      <p>
        En los siguientes enlaces se puede encontrar más información sobre la
        protección de datos en estas empresas, así como sus certificados
        internacionales de protección de datos:
      </p>

      <ul>
        <li>
          <a
            href="https://www.zendesk.com.mx/product/zendesk-security/"
            rel="noreferrer"
            target="_blank"
          >
            https://www.zendesk.com.mx/product/zendesk-security/
          </a>
        </li>
        <li>
          <a
            href="https://mailchimp.com/about/security/"
            rel="noreferrer"
            target="_blank"
          >
            https://mailchimp.com/about/security/
          </a>
        </li>
        <li>
          <a
            href="https://www.braze.com/product/data-agility-management/regulations-and-compliance"
            rel="noreferrer"
            target="_blank"
          >
            https://www.braze.com/product/data-agility-management/regulations-and-compliance
          </a>
        </li>
        <li>
          <a
            href="https://www.split.io/trust/compliance/"
            rel="noreferrer"
            target="_blank"
          >
            https://www.split.io/trust/compliance/
          </a>
        </li>
        <li>
          <a
            href="https://www.revenuecat.com/privacy"
            rel="noreferrer"
            target="_blank"
          >
            https://www.revenuecat.com/privacy
          </a>
        </li>
        <li>
          <a
            href="https://www.appsflyer.com/sp/product/security-and-privacy/"
            rel="noreferrer"
            target="_blank"
          >
            https://www.appsflyer.com/sp/product/security-and-privacy/
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/about/privacy"
            rel="noreferrer"
            target="_blank"
          >
            https://www.facebook.com/about/privacy
          </a>
        </li>
        <li>
          <a
            href="https://policies.google.com/privacy?hl=es-419"
            rel="noreferrer"
            target="_blank"
          >
            https://policies.google.com/privacy?hl=es-419
          </a>
        </li>
        <li>
          <a
            href="https://aws.amazon.com/es/compliance/?nc1=h_ls"
            rel="noreferrer"
            target="_blank"
          >
            https://aws.amazon.com/es/compliance/?nc1=h_ls
          </a>
        </li>
        <li>
          <a
            href="https://www.apple.com/mx/privacy/"
            rel="noreferrer"
            target="_blank"
          >
            https://www.apple.com/mx/privacy/
          </a>
        </li>
        <li>
          <a
            href="https://www.tiktok.com/legal/page/eea/privacy-policy/en"
            rel="noreferrer"
            target="_blank"
          >
            https://www.tiktok.com/legal/page/eea/privacy-policy/en
          </a>
        </li>
        <li>
          <a
            href="https://help.pinterest.com/es/topics/privacy-safety-and-legal"
            rel="noreferrer"
            target="_blank"
          >
            https://help.pinterest.com/es/topics/privacy-safety-and-legal
          </a>
        </li>
      </ul>

      <p>
        La Compañía puede compartir la información sobre el Usuario con otros
        terceros si el Usuario lo ha consentido o si la Compañía puede
        garantizar que el subcontratista act&uacute;a bajo la obligación de
        confidencialidad y que llevará a cabo las acciones técnicas y
        organizativas adecuadas en relación con el procesamiento de la
        información personal.
      </p>

      <p>
        La Compañía también puede transferir información a una empresa
        perteneciente al mismo grupo empresarial, así como durante la
        reorganización de la empresa.
      </p>

      <p>
        La Compañía es responsable ante el Usuario del procesamiento de la
        información personal de los subcontratistas, así como de la suya propia.
      </p>

      <h2 id="s9">
        9. Derechos relacionados con el tratamiento de la información personal
      </h2>

      <p>
        <strong>
          Derecho de inspección y derecho a solicitar una corrección
        </strong>
      </p>

      <p>
        Master English no comprueba la corrección de la información presentada
        por el Usuario. Los Usuarios que se conectan al servicio pueden
        comprobar y cambiar sus ajustes relacionados con el uso del servicio,
        así como la información personal presentada durante el registro.
      </p>

      <p>
        El Usuario tiene derecho a solicitar al servicio de atención al cliente
        de Master English una copia de toda la información que le concierne, así
        como el derecho a solicitar al servicio de atención al cliente la
        corrección de cualquier posible información incorrecta en el sistema. La
        copia puede enviarse por correo electrónico a&nbsp;
        <a href="mailto:soporte@masterenglish.com">soporte@masterenglish.com</a>
        . En principio, solicitar una copia es gratuito. Sin embargo, Master
        English puede cobrar una tarifa razonable por las solicitudes repetidas
        de información sobre la base de los gastos administrativos, o negarse a
        realizar la acción solicitada por el Usuario si la solicitud es
        claramente infundada y no razonable.
      </p>

      <p>
        <strong>Eliminación de información</strong>
      </p>

      <p>
        El Usuario puede eliminar su propia cuenta de Usuario por correo
        electrónico a&nbsp;
        <a href="mailto:soporte@masterenglish.com">soporte@masterenglish.com</a>
        . El Usuario también tiene derecho a solicitar a la Compañía que elimine
        toda la información personal del Usuario de la base de datos de Master
        English. La eliminación de la información puede solicitarse por correo
        electrónico a&nbsp;
        <a href="mailto:soporte@masterenglish.com">soporte@masterenglish.com</a>
        .
      </p>

      <p>
        Toda otra información, aparte de la requerida por las autoridades
        oficiales (por ejemplo, los datos de las transacciones de pago para la
        contabilidad) y los datos generados por el uso del servicio y necesarios
        para la investigación y el desarrollo (por ejemplo, las respuestas
        escritas o registradas) pueden eliminarse manualmente de la base de
        datos. Tenga en cuenta que los datos generados por el uso del servicio
        para los ejercicios no pueden, conectarse con el Usuario, después de
        haber eliminado el resto de la información. En otras palabras, se
        convierte en información anónima seg&uacute;n lo recomendado por el
        marco jurídico de protección de datos aplicable.
      </p>

      <p>
        <strong>Rechazo del marketing directo</strong>
      </p>

      <p>
        El Usuario puede prohibir el uso de su información para fines de
        marketing directo, investigación de mercado y encuestas de opinión. El
        Usuario puede darse de baja de los correos electrónicos de marketing de
        la Compañía haciendo clic en el enlace para darse de baja del boletín
        informativo que se encuentra en el pie de página de cada boletín.
      </p>

      <p>
        <strong>Transferencia de información</strong>
      </p>

      <p>
        El Usuario tiene el derecho de transferir la información que le
        concierne de un encargado de registro a otro. Para asuntos relacionados
        con la transferencia de información, por favor, póngase en contacto
        con&nbsp;
        <a href="mailto:soporte@masterenglish.com">soporte@masterenglish.com</a>
        .
      </p>

      <p>
        <strong>Derecho de retirada del consentimiento</strong>
      </p>

      <p>
        Si el Usuario ha consentido el tratamiento de datos personales que no se
        basa en la ejecución del contrato, el cumplimiento de las obligaciones
        legales de la Compañía o los intereses legítimos de la Compañía, el
        Usuario también tiene derecho a retirar este consentimiento. La retirada
        del consentimiento puede hacerse notificándolo a&nbsp;
        <a href="mailto:soporte@masterenglish.com">soporte@masterenglish.com</a>
        .
      </p>

      <h2 id="s10">10. Almacenamiento de la información</h2>

      <p>
        Master English almacenará la información de Usuario y de uso por un
        mínimo de un año después de la finalización del &uacute;ltimo período de
        suscripción del Usuario, a menos que el Usuario solicite específicamente
        que se elimine la información. Después de esto, la información puede
        eliminarse del registro durante las limpiezas de la base de datos hechas
        a ciertos intervalos de acuerdo con la práctica actual.
      </p>

      <p>
        No obstante lo anterior y en aras de la claridad, cabe señalar que la
        información relativa a los eventos de compra puede almacenarse incluso
        después de ese momento, en la medida y durante el tiempo que el
        proveedor de servicios considere necesario para almacenar esa
        información a fin de cumplir con las obligaciones contables y otras
        obligaciones legales.
      </p>

      <h2 id="s11">11. Uso de cookies</h2>

      <p>
        Con el fin de supervisar el uso del servicio y hacerlo posible, a veces
        se pueden introducir cookies en la computadora del Usuario. Las cookies
        hacen posible que la Compañía proporcione al Usuario un servicio que
        tenga en cuenta sus deseos y preferencias.
      </p>

      <p>
        Las cookies también pueden utilizarse para ofrecer al Usuario mejores
        ofertas y recomendaciones de productos más personales, ya sea
        directamente o a través de un servicio externo, como Google, TikTok,
        Pinterest o Facebook.
      </p>

      <p>
        Además, las cookies se utilizan para controlar el n&uacute;mero de
        visitantes del servicio. Algunas partes del sitio web requieren la
        aceptación de las cookies para poder funcionar.
      </p>

      <p>
        La configuración de la publicidad puede administrarse en la propia
        cuenta de Facebook, TikTok, Pinterest o Google del Usuario:
      </p>

      <p>
        <a
          href="https://myaccount.google.com/privacycheckup"
          rel="noreferrer"
          target="_blank"
        >
          https://myaccount.google.com/privacycheckup
        </a>
      </p>

      <p>
        <a
          href="https://www.facebook.com/ads/preferences"
          rel="noreferrer"
          target="_blank"
        >
          https://www.facebook.com/ads/preferences
        </a>
      </p>

      <p>
        <a
          href="https://www.tiktok.com/privacy/ads-and-your-data/en"
          rel="noreferrer"
          target="_blank"
        >
          https://www.tiktok.com/privacy/ads-and-your-data/en
        </a>
      </p>

      <p>
        <a
          href="https://help.pinterest.com/es/article/your-privacy-and-data-settings
"
          rel="noreferrer"
          target="_blank"
        >
          https://help.pinterest.com/es/article/your-privacy-and-data-settings
        </a>
      </p>

      <p>
        Las cookies también pueden administrarse en la computadora mediante un
        servicio diseñado para tal fin, como Desconectar.
      </p>

      <p>
        <a
          href="https://disconnect.me/disconnect"
          rel="noreferrer"
          target="_blank"
        >
          https://disconnect.me/disconnect
        </a>
      </p>

      <p>
        Para más información sobre el marketing en línea basado en el navegador
        y sobre la privacidad en línea:
      </p>

      <p>
        <a
          href="http://www.youronlinechoices.com/"
          rel="noreferrer"
          target="_blank"
        >
          http://www.youronlinechoices.com/
        </a>
      </p>

      <h2 id="s12">12. Protección del registro</h2>

      <p>
        Todas las personas que manejan el registro poseen un permiso personal de
        Usuario (nombre de Usuario, contraseña y nivel de derechos) del
        encargado del registro. Estas personas operan con arreglo a un acuerdo
        de no divulgación o están legalmente obligadas a mantener la
        confidencialidad de otro modo. La información personal de los Usuarios
        solo está accesible a las personas que la necesitan para realizar su
        trabajo. Entre ellos se encuentran el personal de atención al cliente
        (interno y subcontratado) y el personal técnico del servicio.
      </p>

      <p>
        Master English se esforzará, en la medida de sus posibilidades, por
        ofrecer el servicio de tal manera que las partes externas no tengan
        acceso ilegal a la información o las comunicaciones del Usuario. Otras
        partes conectadas a la prestación del servicio (por ejemplo, los
        operadores de redes o de telefonía móvil) son responsables de la
        seguridad de sus propios datos.
      </p>

      <p>
        Master English se esfuerza por ofrecer el servicio siguiendo el
        principio de privacidad por defecto. La información personal se procesa
        de forma seudónima o anónima siempre que sea posible. El contenido de la
        información del registro está técnicamente asegurado y se hacen copias
        de seguridad regularmente.
      </p>

      <p>
        Master English lleva a cabo las medidas técnicas y organizativas
        adecuadas en relación con el tratamiento de la información personal,
        especialmente para proteger la información personal de las violaciones
        de la seguridad de los datos. Si, a pesar de ello, se produjera una
        violación de la seguridad de los datos (destrucción accidental o ilegal,
        pérdida, alteración, transferencia o acceso ilícitos, que causen un alto
        riesgo para los derechos y libertades de los Usuarios), informaremos a
        los Usuarios del riesgo, sus posibles efectos y las medidas recomendadas
        para contrarrestar sus efectos perjudiciales sin demora.
      </p>

      <h2 id="s12">13. PARA RESIDENTES DE CALIFORNIA</h2>

      <p>
        Consulte las secciones 4, 5 y anteriores para información sobre las
        categorías de datos de carácter personal recopilados, así como los fines
        de la recopilación de estos datos. Consulte la sección 8 para
        información sobre confidencia de tus datos de carácter personal con
        terceros.
      </p>

      <p>
        No &quot;vendemos&quot; tus datos de carácter personal a terceros tal y
        como acostumbra a entender el término. Sin embargo, podemos compartir tu
        identificador publicitario y dirección de correo electrónico en forma de
        hash con nuestras plataformas de publicidad externas para ofrecer
        publicidad dirigida y personalizada, y para monitorear la efectividad de
        la publicidad.
      </p>

      <p>
        Los Usuarios visitantes tiene derecho a denegar esta publicidad basada
        en su perfil o a retirar el consentimiento deshabilitando &quot;Cookies
        de preferencias&quot; de los ajustes de cookies del sitio web.
      </p>

      <p>
        Los Usuarios registrados pueden gestionar sus ajustes de publicidad
        directamente en los servicios de las redes sociales que utilizan.
        Consulte la sección 8 para más información e instrucciones.
      </p>

      <p>
        Los Usuarios también pueden limitar el seguimiento de los anuncios en su
        dispositivo celular restableciendo sus identificadores publicitarios y
        seleccionando no participar en anuncios basados en sus intereses
        (Android) o activar la opción de Limitar seguimiento publicitario (iOS).
      </p>
    </Fragment>
  );
};

export default PrivacyPolicyES;
