import { FunctionComponent } from "react";
import { getMediaUrl, MediaUrls } from "lib/media";
import Image from "./image";
import { ImageCSS } from "./tiles.styles";
import { useTheme } from "hooks/theme";

const Tiles: FunctionComponent = () => {
  const theme = useTheme();
  if (theme === "MasterEnglish" || theme === "HablaIngles") {
    return null;
  }

  const urls: MediaUrls = {
    mobile: getMediaUrl("TileMobile"),
    mobileWebp: getMediaUrl("TileMobile", { extension: "webp" }),
    tablet: getMediaUrl("TileTabletDesktopBG"),
    tabletWebp: getMediaUrl("TileTabletDesktopBG", { extension: "webp" }),
    desktop: getMediaUrl("TileTabletDesktopBG"),
    desktopWebp: getMediaUrl("TileTabletDesktopBG", { extension: "webp" }),
  };

  return <Image urls={urls} alt="Tiles" css={ImageCSS} />;
};

export default Tiles;
