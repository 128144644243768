import { FunctionComponent } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import { PaddleContentType } from "models/content/paddle";
import PaddleFrame from "components/payments/providers/paddle";
import { StyledLine, StyledPaddle } from "./paddle.styles";
import { useProducts } from "hooks/products";

interface PaddleContentProps extends ContentProps {
  content: PaddleContentType;
}

const PaddleContent: FunctionComponent<PaddleContentProps> = ({
  handleScroll,
}) => {
  const products = useProducts();

  if (!products) {
    return null;
  }

  const handleOnLoad = () => {
    handleScroll();
  };

  return (
    <StyledPaddle>
      <StyledLine />
      <PaddleFrame product={products[0]} onLoad={handleOnLoad} />
    </StyledPaddle>
  );
};

export default PaddleContent;

export const paddleContentValidator: ValidatorMethod = () => {
  return "block";
};

export const schema: ContentSchema = {
  Component: PaddleContent,
  validator: paddleContentValidator,
};
