import { ProductModel } from "models/product";

/* NOTE: these methods should return unrounded values! */
/* NOTE: add rounding in where the methods are used */

// this is supposed to return the full price of the program
export const getProgramTotal = (product: ProductModel, months: number) => {
  const { productType } = product;
  switch (productType) {
    case "subscription":
      return getSubscriptionTotal(product, months);
    case "onetime":
      return getOnetimeFee(product);
    default:
      throw new Error("Unknown productType");
  }
};

export const getOnetimeFee = (product: ProductModel) => {
  const { price } = product;
  return price.gross;
};

export const getSubscriptionFee = (product: ProductModel) => {
  const { price } = product;
  return price.gross;
};

export const getSubscriptionTotal = (product: ProductModel, months: number) => {
  const { price, frequency = 1 } = product;
  return (months / frequency) * price.gross;
};
