import QuestionnaireProvider from "providers/questionnaire";
import { useParams } from "react-router-dom";
import { QuestionnaireVersion } from "models/questionnaire";
import StateProvider from "providers/state";
import ThemeProvider from "providers/theme";
import ConfigProvider from "providers/config";
import { StaticViewIdEnum } from "models/question";
import QuestionnaireStartingLevelTestView from "./view";

const QuestionnaireStartingLevelTestViewWrapper = () => {
  const { version: v } = useParams();
  const version = v as QuestionnaireVersion;

  return (
    <ThemeProvider>
      <ConfigProvider>
        <StateProvider>
          <QuestionnaireProvider
            version={version}
            questionId={StaticViewIdEnum.STARTING_LEVEL_TEST}
          >
            <QuestionnaireStartingLevelTestView />
          </QuestionnaireProvider>
        </StateProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default QuestionnaireStartingLevelTestViewWrapper;
