import Wait from "components/wait";
import styled from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";
import { semibold } from "theme/fonts";

export const StyledTitle = styled(Wait)`
  ${semibold()};
  font-size: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  line-height: 2rem;
  min-height: 4rem;
  justify-content: left;
  display: block;
  color: ${ColorCode.BLACK};

  & > div {
    display: inline-block;
    vertical-align: sub;
    margin-left: 0.25rem;
  }

  @media ${devices.tablet} {
    font-size: 1.675em;
  }

  @media ${devices.desktop} {
  }
`;

export const StyledItem = styled.div`
  margin-bottom: 2rem;
`;

export const StyledBarWrapper = styled.div`
  background-color: ${ColorCode.WHITE_SMOKE};
  height: 0.5rem;
  margin-bottom: 0.25rem;

  @media ${devices.tablet} {
    height: 0.75rem;
  }

  @media ${devices.desktop} {
  }
`;

export const StyledBar = styled.div`
  background-color: ${(props) => props.color};
  height: 100%;
  transition: width 0.05s;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  ${semibold()};
  font-size: 0.75em;

  @media ${devices.tablet} {
    font-size: 1.125em;
  }

  @media ${devices.desktop} {
  }
`;

export const StyledLabel = styled.div`
  flex: 1;
  text-transform: uppercase;
`;

export const StyledPercentage = styled.div``;
