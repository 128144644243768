import { FunctionComponent } from "react";
import { ContentSchema, ContentProps } from "../factory";
import Wait from "components/wait";
import { ValidatorMethod } from "../../validations";
import { WaitContentType } from "models/content/wait";

interface WaitContentProps extends ContentProps {
  content: WaitContentType;
}

const WaitContent: FunctionComponent<WaitContentProps> = ({ content }) => {
  return <Wait text={content.waitText} />;
};

export default WaitContent;

export const waitContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: WaitContent,
  validator: waitContentValidator,
};
