import Description from "components/description";
import styled from "styled-components";
import { ColorCode } from "theme/colors";

interface Props {
  $blue: boolean;
}

export const StyledDescription = styled(Description)<Props>`
  color: ${(props) => (props.$blue ? ColorCode.BLUE : ColorCode.BLACK)};
`;
