import { useQuestionnaire } from "hooks/questionnaire";
import { useView } from "hooks/view";
import { formatCurrency } from "lib/currency";
import { getProductConfiguration } from "lib/product-configuration";
import { ProductModel } from "models/product";
import { FunctionComponent } from "react";
import {
  StyledFooter,
  StyledHeader,
  StyledNumber,
  StyledPanel,
  StyledPrice,
} from "./price.styles";

interface PriceProps {
  product: ProductModel;
}

const Price: FunctionComponent<PriceProps> = ({ product }) => {
  const view = useView();
  const questionnaire = useQuestionnaire();
  const configuration = getProductConfiguration(questionnaire, product);
  const { price, currency } = product;
  const { selectionTexts } = configuration;
  const { header, footer } = selectionTexts;
  const formatetted = formatCurrency(price.gross, currency);
  return (
    <StyledPrice>
      <StyledPanel view={view}>
        <StyledHeader>{header}</StyledHeader>
        <StyledNumber>{formatetted}</StyledNumber>
        <StyledFooter>{footer}</StyledFooter>
      </StyledPanel>
    </StyledPrice>
  );
};

export default Price;
