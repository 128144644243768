import Table from "components/table/table";
import TableCell from "components/table/table-cell";
import TableHeader from "components/table/table-header";
import TableRow from "components/table/table-row";
import { StyledLogo, StyledTableIcon } from "components/table/table.styles";
import { getMediaUrl } from "lib/media";
import {
  TableContentCell,
  TableContentHeader,
  TableContentType,
} from "models/content/table";
import { Fragment, FunctionComponent } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import { toHTML } from "lib/string";
import { useTheme } from "hooks/theme";
import { StyledIcon } from "./table.styles";

interface TableContentProps extends ContentProps {
  content: TableContentType;
}

const TableContent: FunctionComponent<TableContentProps> = ({ content }) => {
  const theme = useTheme();
  const { tableOptions } = content;
  const { columns, rows } = tableOptions;

  const renderCell = (cell: TableContentCell, cellIndex: number) => {
    const { type, content, align = "center" } = cell;
    return (
      <TableCell key={cellIndex} align={align}>
        {/* this should be deprecated */}
        {type === "title" && toHTML(content)}
        {type === "text" && toHTML(content)}
        {type === "positive-icon" && (
          <StyledTableIcon
            $theme={theme}
            src={getMediaUrl("icons/CompleteTick_Basic", {
              extension: "svg",
            })}
          ></StyledTableIcon>
        )}
        {type === "negative-icon" && (
          <StyledTableIcon
            $theme={theme}
            src={getMediaUrl("icons/Tickless_Disabled", {
              extension: "svg",
            })}
          ></StyledTableIcon>
        )}
      </TableCell>
    );
  };

  const renderIcon = (icon: string) => {
    const url = getMediaUrl(`icons/${icon}`, {
      size: "2x",
      extension: "webp",
    });
    return <StyledIcon src={url} />;
  };

  const renderHeaderContent = (column: TableContentHeader) => {
    const { type, icon, content } = column;
    if (type === "logo") {
      return (
        <StyledLogo
          src={getMediaUrl("icons/Logo_Mark", {
            extension: "svg",
            size: "3x",
          })}
        />
      );
    }

    return (
      <Fragment>
        {icon && renderIcon(icon)}
        {toHTML(content || "")}
      </Fragment>
    );
  };

  return (
    <Table>
      <TableRow>
        {columns.map((column, columnIndex) => {
          const { align = "center", small = false } = column;
          return (
            <TableHeader
              width={column.width}
              key={columnIndex}
              align={align}
              small={small}
            >
              {renderHeaderContent(column)}
            </TableHeader>
          );
        })}
      </TableRow>
      {rows.map((row, index) => {
        return (
          <TableRow key={index}>
            {row.cells.map((cell, cellIndex) => renderCell(cell, cellIndex))}
          </TableRow>
        );
      })}
    </Table>
  );
};

export default TableContent;

export const tableContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: TableContent,
  validator: tableContentValidator,
};
