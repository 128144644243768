import { ViewType } from "models/view";
import styled, { css } from "styled-components";
import devices from "theme/devices";

import { ColorCode } from "theme/colors";
import { ThemeProps } from "models/theme";

interface HeaderProps extends ThemeProps {
  $view: ViewType;
  $width?: number;
}

export const StyledTableHeader = styled.th<HeaderProps>`
  font-size: 0.875em;
  color: ${(props) =>
    props.$view === "dark" ? ColorCode.WHITE : ColorCode.BLACK};
  background-color: transparent;
  font-weight: 600;
  padding-bottom: 1rem;
  text-transform: uppercase;
  text-align: center;

  ${(props) =>
    props.$width &&
    css`
      width: ${props.$width}%;
    `}

  @media ${devices.tablet} {
    font-size: 0.75em;
  }

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      padding-bottom: 1.25rem;
      padding-top: 1.1rem;

      &:first-child {
        padding-left: 2rem;
        padding-right: 1rem;
      }
      &:last-child {
        padding-left: 1rem;
        padding-right: 2rem;
      }
    `}
`;

interface Props {
  $align: "left" | "center" | "right";
  $small: boolean;
}

export const StyledTableHeaderContent = styled.div<Props>`
  display: flex;
  align-items: center;
  font-size: 14px;

  ${(props) =>
    props.$small &&
    css`
      font-size: 10px;

      & > span {
        margin-left: -1rem;
        margin-right: -1rem;
      }
    `}

  ${(props) =>
    props.$align === "left" &&
    css`
      justify-content: left;
    `}

  ${(props) =>
    props.$align === "center" &&
    css`
      justify-content: center;
    `}

    ${(props) =>
    props.$align === "right" &&
    css`
      justify-content: right;
    `}
`;
