import { FunctionComponent, useEffect } from "react";
import View from "components/layout/view";
import {
  Lady,
  RedLady,
  ConfidentMale,
  Effective,
  Business,
  BuildProgram,
} from "./sections";
import { useTranslations } from "hooks/translations";
import Navbar from "components/navigation/navbar";
import SectionWrapper from "components/layout/section-wrapper";
import EmbeddedQuestion from "./sections/embedded";
import { gtmHomepage } from "lib/gtm";

const HomeView: FunctionComponent = () => {
  const t = useTranslations();

  useEffect(() => {
    gtmHomepage();
  }, []);

  return (
    <View
      title={t("page_title_front_page")}
      description={t("meta_description_front_page")}
      footer
      showLogoInFooter
    >
      <Navbar links />
      <SectionWrapper gray>
        <EmbeddedQuestion />
        <Lady />
        <RedLady />
        <ConfidentMale />
        <Effective />
        <Business />
        <BuildProgram />
      </SectionWrapper>
    </View>
  );
};

export default HomeView;
