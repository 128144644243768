import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";
import { light, regular, semibold } from "theme/fonts";

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledPriceContainer = styled.div`
  margin-top: 0.25rem;
`;

export const StyledStudyScheduleContainer = styled.div`
  padding-top: 1rem;
`;

interface Props extends ThemeProps {
  $active: boolean;
}

export const StyledMonths = styled.div<Props>`
  ${semibold()};
  font-size: 1.25rem;
  margin-bottom: 0.625rem;
  color: ${ColorCode.BLACK};
  ${(props) =>
    props.$active &&
    css`
      color: ${ColorCode.GREEN_DARK};
    `}

  @media ${devices.tablet} {
    font-size: 1.875rem;
  }

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      margin-bottom: 0.25rem;
      line-height: 1;
      color: ${ColorCode.BLACK};
      ${props.$active &&
      css`
        color: ${ColorCode.GREEN_DARK};
      `}
    `};

  ${(props) =>
    props.$theme === "HablaIngles" &&
    props.$active &&
    css`
      color: ${ColorCode.BLACK};
    `};
`;

export const StyledMonthsText = styled.div<Props>`
  ${regular()};
  font-size: 0.75rem;
  margin-bottom: 0.75rem;

  @media ${devices.tablet} {
    font-size: 1.125rem;
  }

  color: ${ColorCode.GRAY800};
  margin-bottom: 0.825rem;
  ${(props) =>
    props.$active &&
    css`
      color: ${ColorCode.GREEN_DARK};
    `}

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      ${light()};
      font-size: 0.85rem;
      line-height: 1.5rem;
      margin-top: 0.5rem;
      color: ${ColorCode.BLACK};
      ${props.$active &&
      css`
        color: ${ColorCode.GREEN_DARK};
      `}
    `}

    ${(props) =>
    props.$theme === "HablaIngles" &&
    props.$active &&
    css`
      color: ${ColorCode.GRAY800};
    `};
`;

export const StyledLessonsPerWeek = styled.div<Props>`
  ${semibold()};
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
  color: ${ColorCode.BLACK};

  @media ${devices.tablet} {
    font-size: 1.275rem;
  }

  ${(props) => css`
    ${props.$active &&
    css`
      color: ${ColorCode.GREEN_DARK};
    `}

    ${props.$theme === "MasterEnglish" &&
    css`
      ${semibold()};
      line-height: 1.25rem;
      margin-bottom: 0.25rem;

      color: ${ColorCode.BLACK};
      ${props.$active &&
      css`
        color: ${ColorCode.GREEN_DARK};
      `}
    `}

    ${props.$theme === "HablaIngles" &&
    css`
      ${regular()};
      ${props.$active &&
      css`
        color: ${ColorCode.BLACK};
      `}
    `};
  `}
`;

export const StyledMinutesPerSession = styled.div<Props>`
  ${semibold()};
  font-size: 0.85rem;
  color: ${ColorCode.BLACK};

  @media ${devices.tablet} {
    font-size: 1.275rem;
  }

  ${(props) => css`
    ${props.$active &&
    css`
      color: ${ColorCode.GREEN_DARK};
    `};

    ${props.$theme === "MasterEnglish" &&
    css`
      ${semibold()};
      line-height: 1.25rem;

      color: ${ColorCode.BLACK};
      ${props.$active &&
      css`
        color: ${ColorCode.GREEN_DARK};
      `}
    `};

    ${props.$theme === "HablaIngles" &&
    css`
      ${regular()};
      ${props.$active &&
      css`
        color: ${ColorCode.BLACK};
      `};
    `};
  `}
`;
