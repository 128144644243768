import { useTheme } from "hooks/theme";
import { Helmet } from "react-helmet";
import { ColorCode } from "theme/colors";

const ThemeColor = () => {
  const theme = useTheme();
  let color: ColorCode | string = ColorCode.LAYOUT_DARK;
  if (theme === "HablaIngles") {
    color = "#030c41";
  }
  const HelmetCastedAsAny: any = Helmet;
  return (
    <HelmetCastedAsAny>
      <meta name="theme-color" content={color} />
    </HelmetCastedAsAny>
  );
};

export default ThemeColor;
