import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ProductModel } from "models/product";
import { useConfig } from "hooks/config";
import { ValidatorMethod } from "../../validations";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import {
  StudyScheduleContentType,
  StudyScheduleModel,
} from "models/content/study-schedule";
import {
  StyledLessonsPerWeek,
  StyledMinutesPerSession,
  StyledMonths,
  StyledMonthsText,
  StyledStudyScheduleContainer,
} from "./study-schedule.styles";
import { TagMap } from "lib/tag";
import { replaceTags } from "lib/string";
import Radio from "components/form/radio";
import { sendStudyScheduleSelectedEvent } from "services/event";
import { useTheme } from "hooks/theme";
import { ThemeType } from "models/theme";
import { StyledBlue } from "components/form/radio.styles";

interface StudyScheduleContentProps extends ContentProps {
  content: StudyScheduleContentType;
}

export interface ProductsStateModel {
  [method: string]: ProductModel;
}

const DEFAULT_OPTIONS = {
  mini: false,
};

const StudyScheduleContent: FunctionComponent<StudyScheduleContentProps> = ({
  content,
  tags,
  onChange,
}) => {
  const config = useConfig();
  const theme = useTheme();
  const { studySchedules = [], studyScheduleOptions = DEFAULT_OPTIONS } =
    content;
  const [active, toggleActive] = useState<number | undefined>(undefined);
  const state = useQuestionnaireState();
  const updateQuestionnaireState = useUpdateQuestionnaireState();

  useEffect(() => {
    // check state
    if (state.schedule) {
      toggleActive(state.schedule);
    }
  }, []);

  const handleChoose = (
    schedule: number,
    studySchedule: StudyScheduleModel
  ) => {
    toggleActive(schedule);
    state.schedule = schedule;
    updateQuestionnaireState(state);

    // send event
    sendStudyScheduleSelectedEvent({ uuid: config.uuid, studySchedule });

    // signal higher
    onChange();
  };

  return (
    <StyledStudyScheduleContainer>
      {studySchedules.map((studySchedule) => {
        const isActive = studySchedule.id === active;

        return (
          <Radio
            active={isActive}
            onSelect={() => handleChoose(studySchedule.id, studySchedule)}
            key={studySchedule.id}
          >
            {!studyScheduleOptions.mini && (
              <Fragment>
                {renderMonths(isActive, studySchedule, theme)}
                {renderMonthsText(isActive, studySchedule, tags, theme)}
                {renderLessonsPerWeek(isActive, studySchedule, theme)}
                {renderMinutesPerWeek(isActive, studySchedule, theme)}
              </Fragment>
            )}
            {studyScheduleOptions.mini && (
              <Fragment>
                {renderLessonsPerWeek(isActive, studySchedule, theme)}
                {renderMinutesPerWeek(isActive, studySchedule, theme)}
              </Fragment>
            )}
          </Radio>
        );
      })}
    </StyledStudyScheduleContainer>
  );
};

export default StudyScheduleContent;

export const studyScheduleContentValidator: ValidatorMethod = (state) => {
  const { schedule } = state;
  return schedule === undefined ? "fail" : "pass";
};

export const schema: ContentSchema = {
  Component: StudyScheduleContent,
  validator: studyScheduleContentValidator,
};

const renderMonths = (
  isActive: boolean,
  option: StudyScheduleModel,
  theme: ThemeType
) => {
  return (
    <StyledMonths $theme={theme} $active={isActive}>
      <StyledBlue $theme={theme} $active={isActive}>
        {option.months}{" "}
      </StyledBlue>
      {option.monthsText}
    </StyledMonths>
  );
};

const renderMonthsText = (
  isActive: boolean,
  option: StudyScheduleModel,
  tags: TagMap,
  theme: ThemeType
) => {
  return (
    <StyledMonthsText $theme={theme} $active={isActive}>
      {replaceTags(option.monthsTextBottom, tags)}
    </StyledMonthsText>
  );
};

const renderLessonsPerWeek = (
  isActive: boolean,
  option: StudyScheduleModel,
  theme: ThemeType
) => {
  return (
    <StyledLessonsPerWeek $theme={theme} $active={isActive}>
      <StyledBlue $theme={theme} $active={isActive}>
        {option.practiseDaysPerWeek}{" "}
      </StyledBlue>
      {option.practiseDaysPerWeekText}
    </StyledLessonsPerWeek>
  );
};

const renderMinutesPerWeek = (
  isActive: boolean,
  option: StudyScheduleModel,
  theme: ThemeType
) => {
  return (
    <StyledMinutesPerSession $theme={theme} $active={isActive}>
      <StyledBlue $theme={theme} $active={isActive}>
        {option.minutesPerSession}{" "}
      </StyledBlue>
      {option.minutesPerSessionText}
    </StyledMinutesPerSession>
  );
};
