import ThemeProvider from "providers/theme";
import QuestionnaireGenerateView from "./view";

const QuestionnaireGenerateViewWrapper = () => {
  return (
    <ThemeProvider>
      <QuestionnaireGenerateView />
    </ThemeProvider>
  );
};

export default QuestionnaireGenerateViewWrapper;
