import { useProducts } from "hooks/products";
import { write } from "lib/storage";
import { ProductModel, PRODUCT_KEY } from "models/product";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import { PriceContentType } from "models/content/price";
import Price from "components/questionnaire/price";

interface PriceContentProps extends ContentProps {
  content: PriceContentType;
}

const PriceContent: FunctionComponent<PriceContentProps> = ({ content }) => {
  const [productId, toggleProductId] = useState<number | undefined>(undefined);
  const { priceOptions } = content;

  // use fixed product, if available
  const fixedProduct = priceOptions?.product;

  // fetch prices
  const products = useProducts();

  const chooseProduct = async (product: ProductModel) => {
    const { productId } = product;

    toggleProductId(productId);

    // write to storage so we can use it later
    write<ProductModel>(PRODUCT_KEY, product);
  };

  useEffect(() => {
    if (!products) {
      return;
    }

    // choose first product (should always exist)
    chooseProduct(products[0]);

    // revert scroll
    window.scrollTo(0, 0);
  }, [products]);

  // do not continue without a product
  if (!productId || !products) {
    return null;
  }

  // find product
  const product = products.find((p) => p.productId === productId);

  if (!product) {
    throw new Error("No product found!");
  }

  return (
    <Fragment>
      <Price product={fixedProduct || product} />
    </Fragment>
  );
};

export default PriceContent;

export const priceContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: PriceContent,
  validator: priceContentValidator,
};
