import styled from "styled-components";
import devices from "theme/devices";

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 75;
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

export const StyledImage = styled.img`
  width: 70%;
  align-self: center; /* safari fix */
  margin-bottom: 12rem;

  @media ${devices.tablet} {
    width: 250px;
    margin-top: 2rem;
    margin-bottom: 12rem;
  }

  @media ${devices.desktop} {
    width: 450px;
    margin-top: 4rem;
    margin-bottom: 24rem;
  }
`;
