import styled from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";

export const StyledPaddle = styled.div``;

export const StyledLine = styled.div`
  border-bottom: 1px solid ${ColorCode.HEX_29324A};
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;

  @media ${devices.tablet} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
