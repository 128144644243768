import { getMediaUrl } from "lib/media";
import { FunctionComponent } from "react";
import { StyledImage, StyledWrapper } from "./start-here.styles";

const StartHere: FunctionComponent = () => {
  return (
    <StyledWrapper>
      <StyledImage src={getMediaUrl("StartHere")} />
    </StyledWrapper>
  );
};

export default StartHere;
