import { useProducts } from "hooks/products";
import { write } from "lib/storage";
import { ProductModel, PRODUCT_KEY } from "models/product";
import { FunctionComponent, useEffect, useState } from "react";
import {
  StyledBody,
  StyledCurrency,
  StyledFrame,
  StyledHeader,
  StyledMonth,
  StyledPrice,
  StyledPriceRow,
  StyledBadge,
  StyledOriginalPrice,
  StyledTextWrapper,
  StyledStrikeThrough,
  StyledWrapper,
} from "./price-frame.styles";
import { format, getCurrencyPrefix } from "lib/currency";
import { useConfig } from "hooks/config";
import { useTheme } from "hooks/theme";

interface PriceFrameProps {
  product: ProductModel;
  badge?: string;
}

const PriceContent: FunctionComponent<PriceFrameProps> = ({
  product,
  badge,
}) => {
  const theme = useTheme();
  const config = useConfig();
  const [productId, toggleProductId] = useState<number | undefined>(undefined);

  // fetch prices
  const products = useProducts();

  useEffect(() => {
    if (products) {
      const product = products[0];
      toggleProductId(product.productId);

      // write to storage so we can use it later
      write<ProductModel>(PRODUCT_KEY, product);
    }
  }, products);

  // do not continue without a product
  if (!productId || !products) {
    return null;
  }

  // get price
  const { currency, price, listPrice } = product;
  const priceStr = [
    getCurrencyPrefix(currency),
    format(price.gross, currency),
  ].join("");

  const listPriceStr = [
    getCurrencyPrefix(currency),
    format(listPrice?.gross || 0, currency),
  ].join("");

  const showOriginalPrice = price.gross !== listPrice?.gross;

  return (
    <StyledWrapper $hasBadge={!!badge}>
      {badge && <StyledBadge filename={badge} />}
      <StyledFrame>
        <StyledHeader $theme={theme} $hasBadge={!!badge}>
          Tu suscripción será de:
        </StyledHeader>
        <StyledBody>
          <StyledPriceRow>
            <StyledPrice>{priceStr}</StyledPrice>
            <StyledCurrency>{product.currency}</StyledCurrency>
          </StyledPriceRow>
          <StyledTextWrapper>
            <StyledMonth>al mes</StyledMonth>
            <StyledOriginalPrice>
              {config.country === "AR" && <span>+ impuestos&nbsp;</span>}
            </StyledOriginalPrice>
            {showOriginalPrice && (
              <StyledOriginalPrice>
                Antes:{" "}
                <StyledStrikeThrough>
                  {listPriceStr} {product.currency}
                </StyledStrikeThrough>
                al mes
              </StyledOriginalPrice>
            )}
          </StyledTextWrapper>
        </StyledBody>
      </StyledFrame>
    </StyledWrapper>
  );
};

export default PriceContent;
