import ContentContainer from "components/layout/content";
import styled from "styled-components";
import devices from "theme/devices";

import { ColorCode } from "theme/colors";

export const StyledContentContainer = styled(ContentContainer)`
  @media ${devices.desktop} {
    max-width: 1000px;
  }

  p {
    color: ${ColorCode.HEX_cdd1dd};
  }
`;
