import { RouteObject } from "react-router-dom";
import AppLinkViewWrapper from "./link/wrapper";
import AppExerciseViewWrapper from "./exercise/wrapper";
import AppDashboardViewWrapper from "./dashboard/wrapper";

// these are to support people coming from...?
export const appRoutes: RouteObject[] = [
  { path: "app/dashboard", element: <AppDashboardViewWrapper /> },
  { path: "app/exercise", element: <AppExerciseViewWrapper /> },
  { path: "app/forgot-password", element: <AppLinkViewWrapper /> },
];
