import { RouteObject } from "react-router-dom";
import ForgotPasswordViewWrapper from "./wrapper";

export const forgotPasswordRoutes: RouteObject[] = [
  { path: "forgot-password", element: <ForgotPasswordViewWrapper /> },

  // some old legacy?
  {
    path: "recuperacion-de-contrasena",
    element: <ForgotPasswordViewWrapper />,
  },
];
