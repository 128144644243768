import ThemeProvider from "providers/theme";
import LoginSuccessView from "./view";

const LoginSuccessViewWrapper = () => {
  return (
    <ThemeProvider>
      <LoginSuccessView />
    </ThemeProvider>
  );
};

export default LoginSuccessViewWrapper;
