import Description from "components/description";
import ContentContainer from "components/layout/content";
import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Title from "components/title";
import { useTranslations } from "hooks/translations";
import { read } from "lib/storage";
import { breakAll, replaceTags } from "lib/string";
import { SIGNUP_EMAIL_KEY } from "models/user";

const LoginSuccessView = () => {
  const t = useTranslations();
  const cachedEmail = read<string>(SIGNUP_EMAIL_KEY);

  const description = replaceTags(t("link_sent_body"), {
    EMAIL: breakAll(cachedEmail || ""),
  });

  return (
    <View
      title={t("link_sent_title")}
      description={t("link_sent_body")}
      footer
      showLogoInFooter
      cover
    >
      <Navbar />
      <ContentContainer>
        <Title>{t("link_sent_title")}</Title>
        <Description>{description}</Description>
      </ContentContainer>
    </View>
  );
};

export default LoginSuccessView;
