import ThemeProvider from "providers/theme";
import LoginExistsView from "./view";

const LoginExistsViewWrapper = () => {
  return (
    <ThemeProvider>
      <LoginExistsView />
    </ThemeProvider>
  );
};

export default LoginExistsViewWrapper;
