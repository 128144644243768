import Image from "components/image";
import { BubbleContentType } from "models/content/bubble";
import { FunctionComponent } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import { ImageCSS, parseBubbleUrl, StyledWrapper } from "./bubble.styles";

interface BubbleContentProps extends ContentProps {
  content: BubbleContentType;
}

const BubbleContent: FunctionComponent<BubbleContentProps> = ({ content }) => {
  const { bubbleOptions } = content;

  const urls = parseBubbleUrl(bubbleOptions);

  return (
    <StyledWrapper>
      <Image urls={urls} alt={bubbleOptions.filename} css={ImageCSS} />
    </StyledWrapper>
  );
};

export default BubbleContent;

export const imageContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: BubbleContent,
  validator: imageContentValidator,
};
