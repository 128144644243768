import ThemeProvider from "providers/theme";
import PaymentFailureView from "./view";

const PaymentFailureViewWrapper = () => {
  return (
    <ThemeProvider>
      <PaymentFailureView />
    </ThemeProvider>
  );
};

export default PaymentFailureViewWrapper;
