import { TranslateFn } from "models/locale";
import { QuestionnaireModel } from "models/questionnaire";
import { getStaticUrl, getVersion } from "./questionnaire";
import { StaticViewPathEnum } from "models/question";

export const getReturnPath = (
  t: TranslateFn,
  questionnaire: QuestionnaireModel
) => {
  const returnPath = getStaticUrl(
    t,
    getVersion(questionnaire),
    StaticViewPathEnum.POST_PURCHASE
  );
  return returnPath;
};
