import ThemeProvider from "providers/theme";
import LoginExpiredView from "./view";

const LoginExpiredViewWrapper = () => {
  return (
    <ThemeProvider>
      <LoginExpiredView />
    </ThemeProvider>
  );
};

export default LoginExpiredViewWrapper;
