import ThemeProvider from "providers/theme";
import QuestionnaireResetView from "./view";

const QuestionnaireResetViewWrapper = () => {
  return (
    <ThemeProvider>
      <QuestionnaireResetView />
    </ThemeProvider>
  );
};

export default QuestionnaireResetViewWrapper;
