import { StudyScheduleModel } from "models/content/study-schedule";
import { ProgramPlan } from "models/plan";
import { QuestionnaireModel } from "models/questionnaire";
import { StateModel } from "models/state";
import { getStudySchedule } from "./question";

export const getProgramPlan = (
  questionnaire: QuestionnaireModel,
  state: StateModel
) => {
  // check if we have a forced one
  const { programPlan } = questionnaire;
  if (programPlan) {
    return programPlan;
  }

  // pick from normal study schedule
  const studySchedule = getStudySchedule(questionnaire, state);
  if (studySchedule) {
    const { months } = studySchedule;
    return `${months}M` as ProgramPlan;
  }
  return "3M";
};

export const getMinutesPerWeek = (studySchedule: StudyScheduleModel) => {
  const { practiseDaysPerWeek, minutesPerSession } = studySchedule;
  return practiseDaysPerWeek * minutesPerSession;
};
