import { ThemeProps } from "models/theme";
import { ViewType } from "models/view";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";
import { semibold } from "theme/fonts";

export const StyledProgramPromiseContent = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1.75rem;
  padding-bottom: 2.25rem;
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
  }
`;

export const StyledMonths = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 0;

  @media ${devices.tablet} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media ${devices.desktop} {
  }
`;

export const StyledBeginMonth = styled.div<ProgramLengthProps>`
  color: ${(props) =>
    props.$view === "dark" ? ColorCode.WHITE : ColorCode.LIGHT_BLUE};
  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      color: ${props.$view === "dark" ? ColorCode.WHITE : ColorCode.BLACK};
    `}
`;

export const StyledEndMonth = styled.div<ProgramLengthProps>`
  color: ${(props) =>
    props.$view === "dark" ? ColorCode.WHITE : ColorCode.LIGHT_BLUE};
  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      color: ${props.$view === "dark" ? ColorCode.WHITE : ColorCode.BLACK};
    `}
`;

interface ProgramLengthProps extends ThemeProps {
  $view: ViewType;
}

export const StyledProgramLength = styled.div<ProgramLengthProps>`
  flex: 1;
  display: flex;
  justify-content: center;
  ${semibold()};
  color: ${(props) =>
    props.$view === "dark" ? ColorCode.WHITE : ColorCode.BLACK};
  font-size: 1.25em;
`;

export const StyledMonthNumber = styled.span<ProgramLengthProps>`
  color: ${(props) =>
    props.$view === "dark" ? ColorCode.WHITE : ColorCode.BLUE};
`;

export const StyledChartContainer = styled.div`
  display: flex;
  justify-content: center;

  @media ${devices.tablet} {
    margin-bottom: 2rem;
    display: none;
  }
`;

export const StyledChartContainerTablet = styled.div`
  display: none;
  justify-content: center;

  @media ${devices.tablet} {
    margin-bottom: 2rem;
    display: flex;
  }
`;
