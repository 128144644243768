import dayjs from "dayjs";
import { DATE_FORMAT_RFC } from "lib/date";
import { cleanJSON } from "lib/json";
import { calculateUserLevel, getStudySchedule } from "lib/question";
import { isSupported } from "lib/questionnaire";
import { request } from "lib/request";
import { buildState } from "lib/state";
import { ConfigModel } from "models/config";
import {
  ControlQuestionnaireVersion,
  QuestionnaireModel,
  QuestionnaireVersion,
} from "models/questionnaire";
import { StateModel } from "models/state";
import { sendGetQuestionnaireEvent } from "./event";

interface QuestionnaireRequestWrapper {
  questionnaire: QuestionnaireModel;
  state?: StateModel;
}

// returns both questionnaire and state (which is optional)
export const loadSavedQuestionnaire = async (
  config: ConfigModel,
  target?: QuestionnaireVersion
) => {
  const { uuid } = config;

  // make request to load the saved questionnaire state
  const response = await request<QuestionnaireRequestWrapper>({
    method: "GET",
    url: "/v1/questionnaire",
    params: { uuid },

    // authenticated, won't work otherwise
    authenticated: true,
  });

  // get the version stored, note the order: first override target, then state, then config and last the control
  const questionnaireVersion =
    target ||
    response.data.state?.id ||
    config.questionnaireVersion ||
    ControlQuestionnaireVersion;
  const supported = isSupported(questionnaireVersion);

  // always replace questionnaire model with clean one
  const toLoad = supported ? questionnaireVersion : ControlQuestionnaireVersion;
  const questionnaire = await loadCleanQuestionnaire(uuid, toLoad, false);

  // if the original questionnaire is not supported anymore
  let state = response.data.state;
  if (!supported) {
    state = buildState(config);
  }

  // send event to the backend (fire and forget)
  sendGetQuestionnaireEvent({
    uuid: config.uuid,
    questionnaireVersion,
  });

  // return cleaned questionnaire and state
  return {
    questionnaire: cleanJSON<QuestionnaireModel>(questionnaire),
    state: cleanJSON<StateModel>(state),
  };
};

// fetch a clean version of questionnaire from CDN
export const loadCleanQuestionnaire = async (
  uuid: string,
  version: QuestionnaireVersion,
  sendEvent: boolean
) => {
  // this makes TS files dynamic bundles
  const { default: json } = await import(`../questionnaires/v${version}.ts`);

  // sanity check
  if (isSupported(version) === false) {
    version = ControlQuestionnaireVersion;
  }

  if (sendEvent) {
    // send event to the backend (fire and forget)
    sendGetQuestionnaireEvent({ uuid, questionnaireVersion: version });
  }

  if (!json) {
    throw new Error("Failed to load clean questionnaire!");
  }

  return json as QuestionnaireModel;
};

// this one only saves "state" object
export const saveQuestionnaireState = async (
  questionnaire: QuestionnaireModel,
  state: StateModel,
  finished: boolean
) => {
  // create a clone, so we don't modify the original state
  const newState: StateModel = JSON.parse(JSON.stringify(state));

  // remove password so we don't send it to the server
  delete newState.user?.password;

  // add finished flag
  newState.finished = finished;
  newState.finishedTimestamp =
    newState.finishedTimestamp || dayjs().toISOString();

  // add studySchedule and programEndDate
  const studySchedule = getStudySchedule(questionnaire, state);
  if (studySchedule) {
    newState.studySchedule = studySchedule;
    newState.programEndDate = dayjs()
      .add(studySchedule.months, "month")
      .subtract(1, "day")
      .format(DATE_FORMAT_RFC);
  }

  // send to server
  const response = await request<QuestionnaireModel>({
    method: "POST",
    url: "/v1/questionnaire",
    data: {
      state: newState,
      userLevel: calculateUserLevel(questionnaire, state),
    },
  });
  return response;
};
