import { StartHereImageContentType } from "models/content/start-here-image";
import { FunctionComponent } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import StartHere from "components/questionnaire/start-here";

interface StartHereImageContentProps extends ContentProps {
  content: StartHereImageContentType;
}

const StartHereImageContent: FunctionComponent<
  StartHereImageContentProps
> = ({}) => {
  return <StartHere />;
};

export default StartHereImageContent;

export const startHereImageContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: StartHereImageContent,
  validator: startHereImageContentValidator,
};
