import List, { ListItemProps } from "components/list";
import { useQuestionnaireState } from "hooks/state";
import { getDayName } from "lib/date";
import { capitalize } from "lib/string";
import { DaySelectionConfirmContentType } from "models/content/day-selection-confirm";
import { FunctionComponent } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";

interface DaySelectionConfirmContentProps extends ContentProps {
  content: DaySelectionConfirmContentType;
}

const DaySelectionConfirmContent: FunctionComponent<
  DaySelectionConfirmContentProps
> = () => {
  const state = useQuestionnaireState();
  const { days = [] } = state;
  const parseListItems = () => {
    const items: ListItemProps[] = days.map((day) => {
      return {
        title: capitalize(getDayName(day)),
      };
    });
    return items;
  };

  return <List type="normal" items={parseListItems()} />;
};

export default DaySelectionConfirmContent;

export const daySelectionConfirmContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: DaySelectionConfirmContent,
  validator: daySelectionConfirmContentValidator,
};
