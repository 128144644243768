import Image from "components/image";
import { isLocal } from "lib/env";
import { getMediaUrl, MediaUrls } from "lib/media";
import { toHTML } from "lib/string";
import {
  SlideshowContentType,
  SlideShowItemModel,
} from "models/content/slideshow";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import {
  ImageCSS,
  StyledImageWrapper,
  StyledProgressContainer,
  StyledProgressOuter,
  StyledSlide,
  StyledText,
  StyledTextWrapper,
  StylesProgressInner,
} from "./slideshow.styles";
import { useTheme } from "hooks/theme";

const SKIP_ANIMATIONS = import.meta.env.VITE_SKIP_ANIMATIONS === "true";

interface SlideshowContentProps extends ContentProps {
  content: SlideshowContentType;
}

export const parseSlideshowUrls = (item: SlideShowItemModel): MediaUrls => {
  const { image } = item;
  const filename = `slideshow/${image}`;
  const mobile = getMediaUrl(filename, {
    extension: "jpg",
    size: "2x",
  });
  const mobileWebp = getMediaUrl(filename, {
    extension: "webp",
    size: "2x",
  });
  const tablet = getMediaUrl(filename, {
    extension: "jpg",
    size: "3x",
  });
  const tabletWebp = getMediaUrl(filename, {
    extension: "webp",
    size: "3x",
  });
  const desktop = getMediaUrl(filename, {
    extension: "jpg",
    size: "3x",
  });
  const desktopWebp = getMediaUrl(filename, {
    extension: "webp",
    size: "3x",
  });
  return { mobile, tablet, desktop, mobileWebp, tabletWebp, desktopWebp };
};

const SlideshowContent: FunctionComponent<SlideshowContentProps> = ({
  content,
  onSubmit,
}) => {
  const theme = useTheme();
  const [index, toggleIndex] = useState(0);
  const [active, toggleActive] = useState(false);
  const { slideshowOptions = {} } = content;
  const {
    size = "normal",
    progress = false,
    items = [],
    delay = isLocal() ? 2500 : 4500,
  } = slideshowOptions;

  useEffect(() => {
    let localIndex = 0;
    const interval = setInterval(() => {
      // inrease active
      localIndex += 1;

      if (localIndex === items.length) {
        // if reached end
        clearInterval(interval);

        // make a redirect
        onSubmit();

        // exit to prevent further animation
        return;
      }
      toggleIndex(localIndex);
    }, delay);

    // start progressbar
    toggleActive(true);

    // clear interval on unmount
    return () => clearInterval(interval);
  }, []);

  const skip = () => {
    onSubmit();
  };

  return (
    <Fragment>
      <StyledImageWrapper $size={size}>
        {items.map((item, i) => {
          const urls = parseSlideshowUrls(item);
          return (
            <StyledSlide key={item.image} opacity={index === i ? 1 : 0}>
              <Image urls={urls} alt="" css={ImageCSS} />
            </StyledSlide>
          );
        })}
        {progress && (
          <StyledProgressContainer $theme={theme}>
            <StyledProgressOuter $theme={theme}>
              <StylesProgressInner
                $theme={theme}
                $state={active}
                $duration={items.length * delay}
              />
            </StyledProgressOuter>
          </StyledProgressContainer>
        )}
      </StyledImageWrapper>
      <StyledTextWrapper $size={size}>
        {items.map((item, i) => {
          return (
            <StyledText
              key={item.text}
              $size={size}
              $opacity={index === i ? 1 : 0}
            >
              {toHTML(item.text)}
            </StyledText>
          );
        })}
      </StyledTextWrapper>
      {SKIP_ANIMATIONS && <div onClick={skip}>SKIP SLIDESHOW</div>}
    </Fragment>
  );
};

export default SlideshowContent;

export const slideshowContentValidator: ValidatorMethod = () => {
  return "block";
};

export const schema: ContentSchema = {
  Component: SlideshowContent,
  validator: slideshowContentValidator,
};
