import { getStoreUrl } from "lib/store";
import { useEffect } from "react";

const AppDashboardView = () => {
  useEffect(() => {
    const url = getStoreUrl();
    window.location.assign(url);
  }, []);

  return null;
};

export default AppDashboardView;
