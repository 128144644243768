import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import { alpha, ColorCode } from "theme/colors";
import devices from "theme/devices";

interface RadioProps extends ThemeProps {
  $active: boolean;
}

export const StyledRadio = styled.div<RadioProps>`
  margin-bottom: 6px;
  display: flex;
  min-height: 90px;
  position: relative;

  border: 1px solid ${alpha(ColorCode.BLACK, 0.03)};
  border-radius: 4px;
  background-color: ${ColorCode.WHITE};
  padding: 1rem;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);

  ${(props) =>
    props.$active &&
    css`
      background-color: ${ColorCode.GREEN};
      box-shadow: none;
    `}

  @media ${devices.tablet} {
    padding: 1.75rem;
    border-radius: 6px;
    min-height: 168px;
  }

  ${(props) => css`
    ${props.$theme === "MasterEnglish" &&
    css`
      padding: 1.5rem 1rem;
    `}

    ${props.$theme === "HablaIngles" &&
    css`
      margin-bottom: 10px;
      border-radius: 16px;
      box-shadow: none;

      ${props.$active &&
      css`
        background-color: ${ColorCode.WHITE};
      `}
    `}
  `}
`;

export const StyledSelector = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;

  @media ${devices.tablet} {
    padding-right: 1.75rem;
  }
`;

export const StyledContent = styled.div<ThemeProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      line-height: 1.25;
    `}
`;

export const StyledDiscountTag = styled.span`
  position: absolute;
  right: 1rem;
  top: 1rem;
  background-color: ${ColorCode.BLUE};
  color: ${ColorCode.WHITE};
  font-size: 0.625em;
  padding: 0.15rem 0.5rem;
  border-radius: 4px;
  text-align: center;
`;

interface Props extends ThemeProps {
  $active: boolean;
}

export const StyledBlue = styled.span<Props>`
  color: ${ColorCode.BLUE};

  ${(props) =>
    props.$active &&
    css`
      color: ${ColorCode.GREEN_DARK};
    `}

  ${(props) =>
    props.$theme === "HablaIngles" &&
    props.$active &&
    css`
      color: ${ColorCode.BLUE};
    `}
`;
