import { RouteObject } from "react-router-dom";
import QuestionnaireResetViewWrapper from "./reset/wrapper";
import QuestionnaireGenerateViewWrapper from "./generate/wrapper";
import QuestionnaireRaffleViewWrapper from "./raffle/wrapper";
import QuestionnaireVersionViewWrapper from "./version/wrapper";
import QuestionnaireQuestionViewWrapper from "./question/wrapper";
import QuestionnaireUpdateEmailViewWrapper from "./update-email/wrapper";
import QuestionnaireEmailExistsViewWrapper from "./email-exists/wrapper";
import QuestionnaireStartLevelTestViewWrapper from "./start-level-test/wrapper";
import QuestionnaireCongratulationsViewWrapper from "./congratulations/wrapper";
import QuestionnaireThankYouViewWrapper from "./thank-you/wrapper";
import QuestionnaireNewConfirmViewWrapper from "./new-confirm/wrapper";
import QuestionnairePostPurchaseViewWrapper from "./post-purchase/wrapper";
import QuestionnaireReactivateOrNewViewWrapper from "./reactivate-or-new/wrapper";
import QuestionnaireReactivateViewWrapper from "./reactivate/wrapper";
import QuestionnaireNextStepViewWrapper from "./next-step/wrapper";
import QuestionnaireStartingLevelTestViewWrapper from "./starting-level-test/wrapper";
import QuestionnaireLoginWithPasswordViewWrapper from "./login-with-password/wrapper";
import QuestionnaireVerifyEmailViewWrapper from "./verify-email/wrapper";

const ES_ROOT = "habla-ingles";

const VE = ":version";
const VE_N_QU = `${VE}/:question`;

export const questionnaireRoutes: RouteObject[] = [
  { path: `${ES_ROOT}`, element: <QuestionnaireRaffleViewWrapper /> },
  {
    path: `${ES_ROOT}/generate`,
    element: <QuestionnaireGenerateViewWrapper />,
  },
  { path: `${ES_ROOT}/reset`, element: <QuestionnaireResetViewWrapper /> },
  {
    path: `${ES_ROOT}/${VE}`,
    element: <QuestionnaireVersionViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/actualizar-email`,
    element: <QuestionnaireUpdateEmailViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/email-ya-existe`,
    element: <QuestionnaireEmailExistsViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/empezar-test-de-nivel`,
    element: <QuestionnaireStartLevelTestViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/enhorabuena`,
    element: <QuestionnaireCongratulationsViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/gracias`,
    element: <QuestionnaireThankYouViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/nueva-confirmacion`,
    element: <QuestionnaireNewConfirmViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/post-purchase`,
    element: <QuestionnairePostPurchaseViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/reactivar-o-nueva`,
    element: <QuestionnaireReactivateOrNewViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/reactivar`,
    element: <QuestionnaireReactivateViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/siguiente-paso`,
    element: <QuestionnaireNextStepViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/test-de-nivel-inicial`,
    element: <QuestionnaireStartingLevelTestViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/usuario`,
    element: <QuestionnaireLoginWithPasswordViewWrapper />,
  },
  {
    path: `${ES_ROOT}/${VE}/verificar-email`,
    element: <QuestionnaireVerifyEmailViewWrapper/>
  },
  {
    path: `${ES_ROOT}/${VE_N_QU}`,
    element: <QuestionnaireQuestionViewWrapper />,
  },
];
