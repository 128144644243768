import { useTheme } from "hooks/theme";
import { useView } from "hooks/view";
import { runValidations, ValidatorFn } from "lib/validators";
import { ChangeEvent, FunctionComponent, KeyboardEvent, useState } from "react";
import {
  StyledWrapper,
  StyledError,
  StyledHint,
  StyledInput,
  StyledLabel,
  StyledUniqueError,
  StyledTextarea,
} from "./input.styles";

export type InputType = "text" | "email" | "password" | "textarea";
export type InputModeType =
  | "none"
  | "text"
  | "tel"
  | "url"
  | "email"
  | "numeric"
  | "decimal"
  | "search";
export type InputEnterKeyHintType =
  | "enter"
  | "done"
  | "go"
  | "next"
  | "previous"
  | "search"
  | "send";
export type InputAutoCapitalizeType =
  | "none"
  | "sentences"
  | "words"
  | "characters";
export type InputAutoCompleteType =
  | "given-name"
  | "username"
  | "new-password"
  | "current-password";

export interface InputProps {
  name?: string;
  label?: string;
  placeholder?: string;

  hidden?: boolean;
  unique?: boolean;

  type: InputType;
  hint?: string | any; // can be element as well

  value: string;
  maxLength?: number;

  inputMode?: InputModeType;
  enterKeyHint?: InputEnterKeyHintType;
  autoCapitalize?: InputAutoCapitalizeType;
  autoComplete?: InputAutoCompleteType;
  autoFocus?: boolean;

  // when value changes
  onChange: (value: string) => void;

  // delegate enter
  onSubmit?: () => void;

  // returns undefined if "ok", returns string if not valid (string is the error message)
  validators?: ValidatorFn[];

  // for hooking up from parent level for example
  extRef?: any;
}

const Input: FunctionComponent<InputProps> = ({
  label,
  name,
  hidden = false,
  unique = false,
  placeholder = "",
  hint,
  type,
  value,
  maxLength = 255,
  autoFocus = false,
  onChange,
  onSubmit,
  validators = [],
  inputMode = "text",
  enterKeyHint = "enter",
  autoCapitalize = "none",
  autoComplete = "off",
  extRef,
}) => {
  const theme = useTheme();
  const [error, setError] = useState<string | undefined>(undefined);
  const view = useView();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    onChange(value);
  };

  const handleKeyPress = () => {
    // nada
  };

  const handleKeyUp = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { key } = e;
    if (onSubmit && key === "Enter") {
      // run validatations without setting the error state
      const result = runValidations(value, validators);
      if (result === undefined) {
        onSubmit();
      }
    } else {
      // key up always ends up in validation
      internallyValidate();
    }
  };

  const internallyValidate = () => {
    const validationResult = runValidations(value, validators);
    setError(validationResult);
    return validationResult;
  };

  const handleBlur = () => {
    internallyValidate();
  };

  const renderField = () => {
    if (type === "textarea") {
      return (
        <StyledTextarea
          ref={extRef}
          aria-placeholder={placeholder || ""}
          placeholder={placeholder || ""}
          $error={error !== undefined}
          maxLength={maxLength}
          value={value}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          $theme={theme}
        />
      );
    }

    return (
      <StyledInput
        ref={extRef}
        aria-placeholder={placeholder || ""}
        placeholder={placeholder || ""}
        $error={error !== undefined}
        type={type}
        maxLength={maxLength}
        autoFocus={autoFocus}
        value={value}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        inputMode={inputMode}
        enterKeyHint={enterKeyHint}
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        $theme={theme}
      />
    );
  };

  if (unique) {
    return (
      <StyledWrapper $hidden={hidden}>
        <StyledError $valid={error === undefined}>
          {error || "&nbsp;"}
        </StyledError>
        {renderField()}
        {hint && <StyledHint>{hint}</StyledHint>}
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper $hidden={hidden}>
      {label && (
        <StyledLabel $view={view} htmlFor={name}>
          {label}
        </StyledLabel>
      )}
      {hint && <StyledHint>{hint}</StyledHint>}
      {renderField()}
      {error && <StyledUniqueError>{error}</StyledUniqueError>}
    </StyledWrapper>
  );
};

export default Input;
