import ThemeProvider from "providers/theme";
import HomeView from "./view";

const HomeViewWrapper = () => {
  return (
    <ThemeProvider>
      <HomeView />
    </ThemeProvider>
  );
};

export default HomeViewWrapper;
